export const codeGroupActionButton = (group, clickHandler) => {
  const lifecycleStatus = group?.lifecycleStatus?.toLowerCase();
  const actionButtons = [
    {
      icon: "visibility",
      onClick: () => clickHandler("view", group),
    },
    {
      icon: "content_copy",
      onClick: () => clickHandler("copy", group),
    },
  ];
  const editableStatuses = ["draft", "active"];
  const deletableStatuses = ["draft"];
  const exportableStatuses = ["draft", "active"];

  editableStatuses.includes(lifecycleStatus) &&
    actionButtons.push({
      icon: "edit",
      onClick: () => clickHandler("edit", group),
    });
  deletableStatuses.includes(lifecycleStatus) &&
    actionButtons.push({
      icon: "delete",
      onClick: () => clickHandler("delete", group),
    });
  exportableStatuses.includes(lifecycleStatus) &&
    actionButtons.unshift({
      icon: "download",
      onClick: () => clickHandler("export", group),
    });
  return actionButtons;
};
