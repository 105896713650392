import { useMutation } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { CircularProgress } from "@mui/material";
import { Button } from "pepsico-ds";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import createAsset from "../../../graphql/mutations/createAsset";
import { FeatureToggle, isFeatureEnabled } from "../../../utils/featureToggle";
import FileUploadField from "./FileUploadField";
import "./imageUploader.scss";

export const SingleImageUploadComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    schema,
    uischema,
    required,
    visible,
    enabled = true,
  } = props;

  const [file, setFile] = useState({ base64: "", name: data, thumb: data });
  const [loader, setLoader] = useState(false);
  const [createAssetMutation] = useMutation(createAsset);
  const [error, setError] = useState({ msg: "", tryAgainButton: false });

  const acceptedExtensions =
    uischema.accept?.toString()?.replace(/image\//g, ".") || ".jpeg,.jpg,.png";
  const maxWidth = Number(schema?.maxWidth ?? uischema?.maxWidth ?? 2000);
  const maxHeight = Number(schema?.maxHeight ?? uischema?.maxHeight ?? 2000);
  const maxSize = Number(schema?.maxSize ?? uischema?.maxSize ?? 1000);
  const requiredResolution = uischema?.requiredResolution ?? false;

  const handleFileUpload = async (newFile, newFileName) => {
    if (typeof newFile === "string") {
      setFile({ thumb: newFile, name: newFileName, base64: undefined });
      return;
    }

    //covert the newFile into base64
    const reader = new FileReader();
    const fileExtension = newFile.name.split(".").pop();
    const hasIncludeExt = acceptedExtensions.includes(fileExtension);
    if (!hasIncludeExt) {
      setError({
        msg: `Invalid file type! Allowed files types are ${acceptedExtensions.toString()}`,
        tryAgainButton: false,
      });
      return;
    } else if (newFile.size && Math.ceil(newFile.size / 1000) > maxSize) {
      const isMb = maxSize >= 1000;
      const size = isMb ? maxSize / 1000 : maxSize;
      const sizeUnit = isMb ? "MB" : "KB";
      setError({
        msg: `Image is too large! Maximum size allowed is ${size}${sizeUnit}.`,
        tryAgainButton: false,
      });
      return;
    } else {
      setError({ msg: "", tryAgainButton: false });
    }
    reader.readAsDataURL(newFile);
    reader.onload = () => {
      setFile({ base64: reader.result, name: newFile.name });
    };
  };

  const callCreateAsset = async () => {
    if (!file?.base64) {
      return;
    }

    try {
      const params = {
        variables: {
          Asset: { data: file?.base64?.split(",")[1] },
        },
      };
      setLoader(true);
      const imageUpload = await createAssetMutation(params);
      setLoader(false);
      if (error?.msg?.length === 0 && imageUpload?.data?.createAsset?.url) {
        handleChange(path, imageUpload?.data?.createAsset?.url);
      } else {
        setError({ msg: "Something went wrong", tryAgainButton: true });
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      setError({ msg: "Something went wrong", tryAgainButton: true });
    }
  };

  const cleanImage = () => {
    setError({ msg: "", tryAgainButton: false });
    setFile({ base64: "", name: "" });
    handleChange(path, "");
  };

  const imgLoadHandler = (evt) => {
    setError({ msg: "", tryAgainButton: false });

    const naturalWidth = evt.target?.naturalWidth;
    const naturalHeight = evt.target?.naturalHeight;

    // Validate exact dimensions
    if (
      requiredResolution &&
      (naturalWidth !== maxWidth || naturalHeight !== maxHeight)
    ) {
      setError({
        msg:
          "Image resolution with " +
          maxWidth +
          "px width and " +
          maxHeight +
          "px height is required!",
        tryAgainButton: false,
      });
      return;
    }

    // Validate max allowed width
    if (maxWidth && naturalWidth > maxWidth) {
      setError({
        msg: "Maximum " + maxWidth + "px image width allowed!",
        tryAgainButton: false,
      });
      return;
    }

    // Validate max allowed height
    if (maxHeight && naturalHeight > maxHeight) {
      setError({
        msg: "Maximum " + maxHeight + "px image height allowed!",
        tryAgainButton: false,
      });
      return;
    }

    // Image is valid (size and dimensions)
    if (isFeatureEnabled(FeatureToggle.newFileUploader)) {
      handleChange(path, file.thumb);
    } else {
      callCreateAsset();
    }
  };

  useEffect(() => {
    if (data && data?.length) {
      setFile({ thumb: data, name: data, base64: "" });
    } else {
      setFile({ thumb: "", name: "", base64: "" });
    }
  }, [data]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <div className="fileUploadHeader" data-testid="single-image-upload-control">
        <span className="label-text $font-xsmall-regular">
          <label>{label}</label>
        </span>
        {required && (
          <span
            className="required-text $font-xxsmall-bold"
            data-testid="is-field-required"
          >
            *Required
          </span>
        )}
      </div>
      {file.name && (
        <div className="image-container">
          <img
            data-testid="image-thumbnail"
            src={file?.base64 ? file.base64 || data : file?.thumb}
            alt={file?.name}
            onLoad={imgLoadHandler}
            style={{
              width: "100%",
              height: "200px",
              cursor: "pointer",
              objectFit: "contain",
            }}
          />
          <div className={loader ? "imageOverlay loader" : "imageOverlay"}>
            {loader ? (
              <CircularProgress />
            ) : (
              <Button
                onClick={cleanImage}
                style={{ backgroundColor: "#117D9F" }}
                size="medium"
                iconTrailing="autorenew"
                disabled={!enabled || uischema.readonly}
              >
                Replace Image
              </Button>
            )}
          </div>
        </div>
      )}
      <>
        {error.msg && (
          <div className="display-flex gap-2 align-items-center mt-2">
            <span style={{ color: "red", fontSize: "14px" }}>{error.msg}</span>
            {error.tryAgainButton && (
              <Button variant="primaryInverse" onClick={callCreateAsset}>
                try again
              </Button>
            )}
          </div>
        )}
      </>
      {!file.name && (
        <FileUploadField
          className="fileUploader"
          value={data || [file.name]}
          updateValue={(newFile, newFileName) =>
            handleFileUpload(newFile, newFileName)
          }
          uiSchema={uischema}
          maxSize={maxSize}
          acceptedExtensions={acceptedExtensions}
          disabled={!enabled || uischema.readonly}
        />
      )}
    </>
  );
};

SingleImageUploadComponent.propTypes = {
  schema: PropTypes.object.isRequired,
  uischema: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  data: PropTypes.string,
  path: PropTypes.string.isRequired,
  required: PropTypes.bool,
  visible: PropTypes.bool,
  enabled: PropTypes.bool,
};

export const SingleImageUploadControl = withJsonFormsControlProps(
  SingleImageUploadComponent
);
