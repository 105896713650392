import "./user-limit-settings.scss";

const keyToTitleMapper = {
  maxRegisteredDevices: "Maximum amount of devices registration",
  maxRegisteredDevicesTimeWindow: "Number of days",
  maxLoginsPerDevice: "Maximum logins allowed per device",
  maxLoginsPerDeviceTimeWindow: "Number of days",
  maxScanCount: "Total Scan Count",
  maxScanCountTimeWindow: "Minutes",
};

export default function UserLimitCardList(props) {
  const { userSettings } = props;

  return (
    <div>
      <div className="user-limit-cards-container">
        <div className="user-limit-card">
          <h4>User Login Limit Screen Settings</h4>
          <div className="values-container">
            <h5>
              {keyToTitleMapper["maxRegisteredDevices"]}:
              <span>{userSettings["maxRegisteredDevices"]}</span>
            </h5>
            <h5>
              {keyToTitleMapper["maxRegisteredDevicesTimeWindow"]}:
              <span>{userSettings["maxRegisteredDevicesTimeWindow"]}</span>
            </h5>
          </div>
        </div>
        <div className="user-limit-card">
          <h4>User Limit Screen Settings</h4>
          <div className="values-container">
            <h5>
              {keyToTitleMapper["maxLoginsPerDevice"]}:
              <span>{userSettings["maxLoginsPerDevice"]}</span>
            </h5>
            <h5>
              {keyToTitleMapper["maxLoginsPerDeviceTimeWindow"]}:
              <span>{userSettings["maxLoginsPerDeviceTimeWindow"]}</span>
            </h5>
          </div>
        </div>
        <div className="user-limit-card">
          <h4>User Limit Successful Code Scan Settings</h4>
          <div className="values-container">
            <h5>
              {keyToTitleMapper["maxScanCount"]}:
              <span>{userSettings["maxScanCount"]}</span>
            </h5>
            <h5>
              {keyToTitleMapper["maxScanCountTimeWindow"]}:
              <span>{userSettings["maxScanCountTimeWindow"]}</span>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
