import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, LoadingSpinner, ToastContainer, Toggle } from "pepsico-ds";
import { useEffect, useState } from "react";
import {
  CODE_GROUP_ADMINISTRATION_STATUS,
  CODE_GROUP_LIFE_CYCLE_STATUS,
} from "../../utils/constants";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { jsonSchema, restrictedUISchema, uiSchema } from "./codeGroupUtils";
import "./createCodeGroupModal.scss";
import { useCodeGroupModal } from "./useCodeGroupModal";

/**
 * CodeGroupSetupModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Flag indicating whether the modal should be shown.
 * @param {function} props.setShowModal - Function to set the showModal state.
 * @params {function} props.refetchListing - Function to refetch the listing.
 * @params {boolean} props.loadCodeGroupLoader - Flag indicating whether the code group data is loading.
 * @params {Object} props.codeGroupData - The code group data.
 * @params {function} props.setCodeGroupData - Function to set the code group data
 * @returns {JSX.Element} The CodeGroupSetupModal component.
 */
export default function CodeGroupSetupModal({
  showModal,
  setShowModal,
  refetchListing,
  loadCodeGroupLoader,
  codeGroupData,
  setParentToastData,
  setCodeGroupData,
}) {
  const [alreadyCreatedData, setAlreadyCreatedData] = useState({});
  const { performDraftCodeGroup, performPublishCodeGroup, validateCodeGroup } =
    useCodeGroupModal(codeGroupData, alreadyCreatedData);

  const [modalToast, setModalToast] = useState([]);

  useEffect(() => {
    if (codeGroupData.codeGroupId) {
      setAlreadyCreatedData(codeGroupData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadCodeGroupLoader]);

  const onCloseModal = () => {
    if (codeGroupData?.codeGroupId) {
      setShowModal((prev) => ({ ...prev, setupModal: false }));
      setCodeGroupData({});
    } else
      setShowModal({ ...showModal, setupModal: false, closeConfirmModal: true });
  };
  const handleActionButtons = async (lifecycleStatus) => {
    if (lifecycleStatus === CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT) {
      const isCodeGroupValid = validateCodeGroup(CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT);
      if (!isCodeGroupValid?.isValid) {
        setModalToast([
          {
            text: isCodeGroupValid.message,
            type: "error",
            id: 3,
          },
        ]);
        return;
      }
      const response = await performDraftCodeGroup();
      if (response.isSuccess) {
        setModalToast([
          {
            text: "Code Group saved as draft",
            type: "success",
            id: 1,
          },
        ]);
        setCodeGroupData((prev) => ({
          ...prev,
          codeGroupId: codeGroupData.codeGroupId || response.codeGroupId,
          lifecycleStatus: CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT,
        }));
        return;
      }
      setModalToast([
        {
          text: response.message,
          type: "error",
          id: 3,
        },
      ]);
    } else {
      const isCodeGroupValid = validateCodeGroup(lifecycleStatus);
      if (!isCodeGroupValid.isValid) {
        setModalToast([
          {
            text: isCodeGroupValid.message,
            type: "error",
            id: 3,
          },
        ]);
        return;
      }
      const response = await performPublishCodeGroup();
      if (response.isSuccess) {
        setShowModal({ showModal, setupModal: false });
        setParentToastData([
          {
            text: response.message,
            type: "success",
            id: 2,
          },
        ]);
        setCodeGroupData({});
        refetchListing();
        return;
      }
      setModalToast([
        {
          text: response.message,
          type: "error",
          id: 2,
        },
      ]);
    }
  };
  return (
    <Dialog
      className="createProduct-modal"
      open={showModal.setupModal}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={10}>
            <h2
              data-testid="create-code-group-modal"
              style={{ marginLeft: "18%", textAlign: "center" }}
            >
              {codeGroupData?.codeGroupId ? "Update" : "Create"} Code Group
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={onCloseModal}
              className="cursor-pointer"
              data-testid="closeIcon"
            />
          </Grid>
        </Grid>
        <ToastContainer
          data={modalToast}
          removeToast={() => setModalToast([])}
          showActionIcon
        />
      </DialogTitle>
      {!loadCodeGroupLoader ? (
        <DialogContent
          sx={{
            overflowX: "hidden",
          }}
        >
          <div className="my-5 create-modal-wrapper">
            <JsonFormsWrapper
              jsonschema={jsonSchema}
              readonly={
                codeGroupData.codeGroupId &&
                codeGroupData?.administrationStatus ===
                  CODE_GROUP_ADMINISTRATION_STATUS.ENABLED &&
                codeGroupData.lifecycleStatus !== CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT
              }
              uischema={
                codeGroupData.lifecycleStatus ===
                  CODE_GROUP_LIFE_CYCLE_STATUS.ACTIVE &&
                codeGroupData.administrationStatus ===
                  CODE_GROUP_ADMINISTRATION_STATUS.DISABLED
                  ? restrictedUISchema
                  : uiSchema
              }
              data={codeGroupData}
              onChange={(val) => setCodeGroupData(val)}
            />
          </div>
        </DialogContent>
      ) : (
        <DialogContent className="display-flex align-items-center justify-content-center">
          <LoadingSpinner
            data-testid="loading-spinner"
            size={{ height: "50px", width: "50px" }}
          />
        </DialogContent>
      )}
      <DialogActions className="mt-5 display-flex justify-content-end align-items-center">
        {[
          CODE_GROUP_LIFE_CYCLE_STATUS.ACTIVE,
          CODE_GROUP_LIFE_CYCLE_STATUS.QUEUED,
        ].includes(codeGroupData?.lifecycleStatus) && (
          <Toggle
            className="toggleStyles mr-3"
            label={
              codeGroupData?.administrationStatus ===
              CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
                ? "Active"
                : "In Active"
            }
            onUpdate={(val) =>
              setCodeGroupData({
                ...codeGroupData,
                administrationStatus: val
                  ? CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
                  : CODE_GROUP_ADMINISTRATION_STATUS.DISABLED,
              })
            }
            size="medium"
            checked={
              codeGroupData?.administrationStatus ===
              CODE_GROUP_ADMINISTRATION_STATUS.ENABLED
            }
          />
        )}
        {(!codeGroupData.codeGroupId ||
          (codeGroupData.codeGroupId &&
            codeGroupData.lifecycleStatus ===
              CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT)) && (
          <Button
            variant="secondary"
            className="justify-content-space-around"
            onClick={() => handleActionButtons(CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT)}
          >
            Save as Draft
          </Button>
        )}
        <Button
          variant="primary"
          className="jufstify-content-space-around"
          onClick={() => handleActionButtons(codeGroupData.lifecycleStatus)}
        >
          {codeGroupData?.codeGroupId ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
