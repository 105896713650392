import { Font, Modal } from "pepsico-ds";
import "./ViewDetails.scss";

function ViewDetailsModal({ isOpen, closeModal, name, description, type }) {
  return (
    <Modal
      showModal={isOpen}
      onCloseModal={closeModal}
      title="Partner Details"
      showSecondaryButton={false}
      showTertiaryButton={false}
      showLink={false}
      className="custom-modal"
    >
      <section className="main-container">
        <section className="title-description-container">
          <span>
            <Font size="body" style={{ fontWeight: "bold", marginRight: "1rem" }}>
              Name
            </Font>
            <Font size="body">{name}</Font>
          </span>
          <span>
            <Font size="body" style={{ fontWeight: "bold", marginRight: "1rem" }}>
              Subtypes
            </Font>
            <Font size="body">{type}</Font>
          </span>
        </section>
        <section style={{ marginTop: "2rem" }}>
          <Font size="body" style={{ fontWeight: "bold", marginRight: "1rem" }}>
            Description:
          </Font>
          <Font size="body">{description}</Font>
        </section>
      </section>
    </Modal>
  );
}

export default ViewDetailsModal;
