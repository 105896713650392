import { Card, Modal } from "pepsico-ds";

function TermAndConditionModal({
  termAndConditionModalOpen,
  toggleTCModal,
  tcData,
}) {
  return (
    <Modal
      showModal={termAndConditionModalOpen}
      onCloseModal={toggleTCModal}
      title={"Term and Condition"}
      primaryButtonProps={{
        size: "medium",
        text: "back",
        variant: "primary",
        onClick: () => {
          toggleTCModal();
        },
      }}
      showSecondaryButton={false}
      showTertiaryButton={false}
      showLink={false}
    >
      <Card>
        <div dangerouslySetInnerHTML={{ __html: tcData }} />
      </Card>
    </Modal>
  );
}

export default TermAndConditionModal;
