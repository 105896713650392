import { Radio } from "@mui/material";
import { LoadingSpinner } from "pepsico-ds";
import { getDateWithFormat } from "../../../utils/commons";

const SurveyAndQuizTable = ({
  surveyAndQuizData,
  selectedContent,
  loading,
  onSelectContent,
  programTimezone,
}) => {
  return (
    <div className="codegroups-table p-0">
      {loading ? (
        <LoadingSpinner
          size={{
            height: "30px",
            width: "30px",
          }}
          style={{ margin: "auto" }}
        />
      ) : (
        <table>
          <thead>
            <tr>
              <th className=""></th>
              <th className="">SURVEY/QUIZ</th>
              <th className="">TYPE</th>
              <th className="">START DATE</th>
              <th className="">END DATE</th>
              <th className="">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {surveyAndQuizData?.length ? (
              surveyAndQuizData?.map((content) => (
                <tr key={content.id}>
                  <td className="">
                    <Radio
                      size="small"
                      checked={selectedContent?.id === content.id}
                      onChange={() => onSelectContent(content)}
                      value={content.id}
                      name={`${content.title}-radio`}
                      inputProps={{ "aria-label": content.title }}
                    />
                  </td>
                  <td className="">{content.title}</td>
                  <td className="">{content.contentData?.type || "-"}</td>
                  <td className="">
                    {getDateWithFormat(content.activeStartDate, programTimezone)}
                  </td>
                  <td className="">
                    {getDateWithFormat(content.activeEndDate, programTimezone)}
                  </td>
                  <td className="">
                    {" "}
                    <div className="common-status-wrapper">
                      <span
                        className={`common-status ${content.status?.toLowerCase()}`}
                      >
                        {content.status ? content.status.toLowerCase() : ""}
                      </span>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="no-data">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SurveyAndQuizTable;
