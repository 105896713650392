import { Card, Tag, Tooltip } from "pepsico-ds";
import useModalState from "../../../hooks/useModalState";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import PartnerCardModal from "../Modals/PartnerCardModal/PartnerCardModal";
import { partnersActionButtons } from "../partnersActionButton";
import "./PartnerCard.scss";

const PartnerCard = ({ name, active, description, type, refetch, id }) => {
  const [viewIsOpen, viewOnOpen, viewOnClose] = useModalState();
  const [copyIsOpen, copyOnOpen, copyOnClose] = useModalState();
  const [editIsOpen, editOnOpen, editOnClose] = useModalState();
  const [deleteIsOpen, deleteOnOpen, deleteOnClose] = useModalState();

  const activeStatus = active ? "active" : "inactive";
  return (
    <>
      <Card
        className="partner-card-container"
        icon=""
        ellipsis=""
        style={{
          padding: "0px",
          borderRadius: "10px",
          boxShadow: "0px 0px 17px 0px #005CBC14",
          backgroundColor: "#fff",
        }}
      >
        <div className="partner-card-container" data-testid="partner-grid-card">
          <div className="partner-details">
            <div className="partner-status-type">
              <span className={`status ${active ? "active" : "inactive"}`}>
                {active ? "Active" : "Inactive"}
              </span>
              {type === "INTEGRATED" && (
                <Tag
                  text="Third Party"
                  color="Cyan-500"
                  type="filled"
                  isCopyable={false}
                />
              )}
            </div>
            {name.length > 60 ? (
              <Tooltip direction="right" text={name}>
                <h3 className="partner-name">{name.substring(0, 60)}...</h3>
              </Tooltip>
            ) : (
              <h3 className="partner-name">{name}</h3>
            )}
            <div className="partner-meta">
              {description && (
                <div className="meta-item">
                  <span className="meta-label">Description</span>
                  <span>{description}</span>
                </div>
              )}
            </div>

            <div className={`button-group-container ${!active ? "inactive" : ""}`}>
              <ButtonGroupDynamic
                items={partnersActionButtons({
                  status: type === "INTEGRATED" ? "third-party" : activeStatus,
                  viewCallback: viewOnOpen,
                  copyCallback: copyOnOpen,
                  editCallback: editOnOpen,
                  deleteCallback: deleteOnOpen,
                })}
                spacing="1px"
                size="small"
                variant="control"
              />
            </div>
          </div>
        </div>
      </Card>
      <PartnerCardModal
        viewIsOpen={viewIsOpen}
        viewOnClose={viewOnClose}
        copyIsOpen={copyIsOpen}
        copyOnClose={copyOnClose}
        editIsOpen={editIsOpen}
        editOnClose={editOnClose}
        deleteIsOpen={deleteIsOpen}
        deleteOnClose={deleteOnClose}
        partner={{
          name,
          active,
          description,
          type,
          id,
        }}
        refetch={refetch}
        key={`partner-card-modal-grid-${id}`}
      />
    </>
  );
};

export default PartnerCard;
