import { gql } from "@apollo/client";

export const getCampaignListing = gql`
  query ListCampaigns($filter: CampaignFilter) {
    listCampaigns(filter: $filter) {
      id
      title
      titleShort
      category
      type
      activeStartDate
      activeEndDate
      segments
      campaignStatus
      displayPosition
      imageUrl
      hasSegments
      activityTypes
      endless
      activities {
        id
        name
        description
        activityData
      }
      rewardGroups {
        id
        type
        amount
      }
    }
  }
`;
