import { createContext, useEffect, useState } from "react";
// Create the CampaignSetupContext
const CampaignSetupContext = createContext({
  campaignState: {},
  toastData: [],
  stepper: 0,
  setToastData: () => null,
  stepsForCampaignSetup: [],
  currentCampaignTypeConfig: {},
  setCampaignState: () => null,
  setCurrentCampaignTypeConfig: () => null,
  setStepsForCampaignSetup: () => null,
  resetCurrentCampaignTypeConfig: () => null,
  resetCampaignState: () => null,
});

// Create the CampaignSetupProvider component
const CampaignSetupProvider = ({ children }) => {
  // Define the initial state
  const initialState = {
    type: "",
    id: "",
    activities: [],
    outcomes: [],
    campaignRules: [],
    segments: [],
    campaignContent: null,
    relatedContents: [],
  };

  const [campaignState, setCampaignState] = useState(initialState);
  const [currentCampaignTypeConfig, setCurrentCampaignTypeConfig] = useState({});
  const [toastData, setToastData] = useState([]);
  const [stepsForCampaignSetup, setStepsForCampaignSetup] = useState([]);
  const [stepper, setStepper] = useState(0);

  useEffect(() => {
    if (
      currentCampaignTypeConfig &&
      currentCampaignTypeConfig.steps &&
      currentCampaignTypeConfig.steps.length
    ) {
      setStepsForCampaignSetup(
        currentCampaignTypeConfig.steps.map((step) => ({
          ...step,
          label: step.label,
          url: step.slug,
          disableNext: false /* TODO: update it to true. once valdations are done */,
        }))
      );
    }
  }, [currentCampaignTypeConfig?.key]);
  const resetCampaignState = () => {
    setCampaignState(initialState);
  };

  const resetCurrentCampaignTypeConfig = () => {
    setCurrentCampaignTypeConfig({});
  };

  // Create the context value
  const contextValue = {
    toastData,
    setToastData,
    campaignState,
    stepper,
    setStepper,
    stepsForCampaignSetup,
    currentCampaignTypeConfig,
    setCurrentCampaignTypeConfig,
    setStepsForCampaignSetup,
    resetCurrentCampaignTypeConfig,
    resetCampaignState,
    setCampaignState,
    // Add any functions or methods to update the state here
  };

  // Render the provider with the context value and children
  return (
    <CampaignSetupContext.Provider value={contextValue}>
      {children}
    </CampaignSetupContext.Provider>
  );
};

export { CampaignSetupContext, CampaignSetupProvider };
