export const rewardBasicSetupJsonSchema = {
  type: "object",
  properties: {
    partnerId: {
      type: "string",
      title: "Select Partner",
      minLength: 1,
    },
    type: {
      type: "string",
      title: "Reward Type",
      minLength: 1,
    },
    rewardTypeId: {
      type: "string",
      title: "Reward Type",
      minLength: 1,
    },
    partnerCodeType: {
      type: "boolean",
      title: "Partner Code Type",
    },
    rewardCategoryIds: {
      type: "array",
      title: "Reward Category",
      items: {
        type: "string",
        minLength: 1,
      },
      minItems: 1,
    },
    category: {
      type: "string",
      title: "Reward Category",
      minLength: 1,
    },
    imageUrl: {
      type: "string",
      title: "Reward Image",
      format: "uri",
    },
    wheelImageUrl: {
      type: "string",
      title: "Wheel Image",
      format: "uri",
    },
    quantity: {
      type: "integer",
      title: "Reward Quantity",
    },
    rewardUnitId: {
      type: "string",
      title: "Unit",
      minLength: 1,
    },
    rewardItemGroupId: {
      type: "string",
      title: "Partner Code Group",
    },
    name: {
      type: "string",
      title: "Reward Name",
      minLength: 1,
      maxLength: 40,
    },
    description: {
      type: ["string", "null"],
      title: "Reward Description",
      maxLength: 250,
    },
    linkType: {
      title: "Select a Link Type",
      enum: ["SMS", "DEEP_LINK", "WEB_URL", "NOTHING"],
    },
  },
  required: [
    "partnerId",
    "type",
    "rewardTypeId",
    "partnerCodeType",
    "rewardCategoryIds",
    "category",
    "imageUrl",
    "wheelImageUrl",
    "quantity",
    "rewardUnitId",
    "rewardItemGroupId",
    "name",
  ],
  allOf: [
    {
      if: {
        properties: {
          partnerCodeType: { const: true },
        },
        required: ["partnerCodeType"],
      },
      then: {
        properties: {
          rewardItemGroupId: {
            type: "string",
            title: "Partner Code Group",
            minLength: 1,
          },
        },
        required: ["rewardItemGroupId"],
      },
    },
    {
      if: {
        properties: {
          partnerType: { const: "PARTNER_CODE_GROUP" },
        },
        required: ["partnerType"],
      },
      then: {
        properties: {
          linkType: {
            title: "Select a Link Type",
            enum: ["SMS", "DEEP_LINK", "WEB_URL", "NOTHING"],
          },
        },
        required: ["linkType"],
        allOf: [
          {
            if: {
              properties: {
                linkType: { const: "SMS" },
              },
            },
            then: {
              properties: {
                buttonText: {
                  type: "string",
                  title: "Button Text",
                  minLength: 1,
                },
                sms: {
                  type: "string",
                  title: "SMS Text Message",
                  minLength: 1,
                },
                smsNumber: {
                  type: "string",
                  title: "SMS Number",
                  minLength: 1,
                },
              },
              required: ["buttonText", "sms", "smsNumber"],
            },
          },
          {
            if: {
              properties: {
                linkType: { const: "DEEP_LINK" },
              },
            },
            then: {
              properties: {
                buttonText: {
                  type: "string",
                  title: "Button Text",
                  minLength: 1,
                },
                appUrl: {
                  type: "string",
                  title: "iOS Deep Link",
                  format: "uri",
                },
                androidAppId: {
                  type: "string",
                  title: "Android App ID",
                  minLength: 1,
                },
                iosAppId: {
                  type: "string",
                  title: "iOS App ID",
                  minLength: 1,
                },
              },
              required: ["buttonText", "appUrl", "androidAppId", "iosAppId"],
            },
          },
          {
            if: {
              properties: {
                linkType: { const: "WEB_URL" },
              },
            },
            then: {
              properties: {
                buttonText: {
                  type: "string",
                  title: "Button Text",
                  minLength: 1,
                },
                webUrl: {
                  type: "string",
                  title: "Web URL",
                  format: "uri",
                },
              },
              required: ["buttonText", "webUrl"],
            },
          },
        ],
      },
    },
  ],
};

export const rewardBasicSetupUiSchema = {
  type: "VerticalLayout",
  className: "reward-creation-form",
  elements: [
    {
      type: "SimpleGroup",
      label: "Basic Setup",
      elements: [
        {
          type: "HorizontalLayout",
          className: "basic-setup-columns",
          elements: [
            {
              type: "VerticalLayout",
              className: "reward-image-fields",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/imageUrl",
                  component: "singleImageUpload",
                  label: "Reward Image",
                  maxWidth: "360",
                  maxHeight: "357",
                  requiredResolution: true,
                  accept: ["image/png", "image/jpg", "image/jpeg"],
                },
                {
                  type: "Control",
                  scope: "#/properties/wheelImageUrl",
                  component: "singleImageUpload",
                  label: "Wheel Image",
                  maxWidth: "153",
                  maxHeight: "315",
                  requiredResolution: true,
                  accept: ["image/png", "image/jpg", "image/jpeg"],
                },
              ],
            },
            {
              type: "VerticalLayout",
              className: "reward-fields",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "HorizontalLayout",
                      className: "quantity-row",
                      gap: "simple",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/quantity",
                          label: "Reward Quantity",
                          placeholder: "Insert Number",
                        },
                        {
                          type: "Control",
                          scope: "#/properties/rewardUnitId",
                          label: "Unit",
                          component: "rewardUnitSelect",
                          placeholder: "Select",
                        },
                        {
                          type: "Control",
                          scope: "#/properties/rewardItemGroupId",
                          label: "Partner Code Group",
                          component: "partnerCodeGroupSelect",
                          placeholder: "Select",
                          rule: {
                            effect: "ENABLE",
                            condition: {
                              scope: "#/properties/partnerCodeType",
                              schema: {
                                const: true,
                              },
                            },
                          },
                        },
                      ],
                    },
                    {
                      type: "Control",
                      scope: "#/properties/name",
                      label: "Reward Name",
                      placeholder: "Type here",
                      maxLength: 40,
                    },
                    {
                      type: "Control",
                      scope: "#/properties/description",
                      label: "Reward Description",
                      component: "textarea",
                      placeholder: "Type here",
                      maxLength: 250,
                    },
                  ],
                },
                {
                  type: "ConditionalSectionLayout",
                  headerControl: "#/allOf/1/then/properties/linkType",
                  noFieldsValues: ["NOTHING"],
                  noSelectionLabel: "Select link type first",
                  noFieldsLabel: "Nothing to fill in",
                  className: "reward-specific-fields",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/allOf/1/then/properties/linkType",
                      label: "Select a Link Type",
                      component: "radioInput",
                      options: {
                        labelMapping: {
                          SMS: "SMS",
                          DEEP_LINK: "Deep Link",
                          WEB_URL: "Web URL",
                          NOTHING: "Yönlendirme Yapma",
                        },
                      },
                    },
                    {
                      type: "VerticalLayout",
                      className: "link-type-sections",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          className: "link-type-fields deep-link-fields",
                          elements: [
                            {
                              type: "VerticalLayout",
                              elements: [
                                {
                                  type: "Control",
                                  scope:
                                    "#/allOf/1/then/allOf/1/then/properties/buttonText",
                                  label: "Button Text",
                                },
                                {
                                  type: "Control",
                                  scope:
                                    "#/allOf/1/then/allOf/1/then/properties/iosAppId",
                                  label: "iOS App ID",
                                },
                              ],
                            },
                            {
                              type: "VerticalLayout",
                              elements: [
                                {
                                  type: "Control",
                                  scope:
                                    "#/allOf/1/then/allOf/1/then/properties/androidAppId",
                                  label: "Android App ID",
                                },
                                {
                                  type: "Control",
                                  scope:
                                    "#/allOf/1/then/allOf/1/then/properties/appUrl",
                                  label: "iOS Deep Link",
                                },
                              ],
                            },
                          ],
                          rule: {
                            effect: "SHOW",
                            condition: {
                              scope: "#/allOf/1/then/properties/linkType",
                              schema: {
                                const: "DEEP_LINK",
                              },
                            },
                          },
                        },
                        {
                          type: "HorizontalLayout",
                          className: "link-type-fields web-url-fields",
                          elements: [
                            {
                              type: "Control",
                              scope:
                                "#/allOf/1/then/allOf/2/then/properties/buttonText",
                              label: "Button Text",
                            },
                            {
                              type: "Control",
                              scope: "#/allOf/1/then/allOf/2/then/properties/webUrl",
                              label: "Web URL",
                            },
                          ],
                          rule: {
                            effect: "SHOW",
                            condition: {
                              scope: "#/allOf/1/then/properties/linkType",
                              schema: {
                                const: "WEB_URL",
                              },
                            },
                          },
                        },
                        {
                          type: "VerticalLayout",
                          className: "link-type-fields",
                          elements: [
                            {
                              type: "HorizontalLayout",
                              elements: [
                                {
                                  type: "Control",
                                  scope:
                                    "#/allOf/1/then/allOf/0/then/properties/buttonText",
                                  label: "Button Text",
                                },
                                {
                                  type: "Control",
                                  scope:
                                    "#/allOf/1/then/allOf/0/then/properties/smsNumber",
                                  label: "SMS Number",
                                },
                              ],
                            },
                            {
                              type: "Control",
                              scope: "#/allOf/1/then/allOf/0/then/properties/sms",
                              label: "SMS Text Message",
                              component: "textarea",
                            },
                          ],
                          rule: {
                            effect: "SHOW",
                            condition: {
                              scope: "#/allOf/1/then/properties/linkType",
                              schema: {
                                const: "SMS",
                              },
                            },
                          },
                        },
                      ],
                    },
                  ],
                  rule: {
                    effect: "SHOW",
                    condition: {
                      scope: "#/properties/partnerType",
                      schema: {
                        const: "PARTNER_CODE_GROUP",
                      },
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const partnerCodeGroupSpecificationsJsonSchema = {
  type: "object",
  properties: {
    image_on_great_choice_screen: {
      type: "string",
      title: "Image on Great Choice Screen",
      format: "uri",
    },
    great_choice_screen_description: {
      type: "string",
      title: "Great Choice Screen Description",
      minLength: 1,
    },
  },
  required: ["image_on_great_choice_screen", "great_choice_screen_description"],
};

export const partnerCodeGroupSpecificationsUiSchema = {
  type: "SimpleGroup",
  label: "Specifications",
  className: "basic-setup-columns",
  elements: [
    {
      type: "HorizontalLayout",
      className: "partner-reward-specifications",
      elements: [
        {
          type: "Control",
          scope: "#/properties/image_on_great_choice_screen",
          label: "Image on Great Choice Screen",
          component: "singleImageUpload",
        },
        {
          type: "Control",
          scope: "#/properties/great_choice_screen_description",
          label: "Great Choice Screen Description",
          component: "textarea",
          placeholder: "Type here",
        },
      ],
    },
  ],
};

export const hopiSpecificationsJsonSchema = {
  type: "object",
  properties: {
    hopi_campaign_code: {
      type: "string",
      title: "Hopi Campaign Code",
      minLength: 1,
    },
  },
  required: ["hopi_campaign_code"],
};

export const hopiSpecificationsUiSchema = {
  type: "SimpleGroup",
  label: "Specifications",
  className: "reward-specific-fields",
  elements: [
    {
      type: "Control",
      scope: "#/properties/hopi_campaign_code",
      label: "Hopi Campaign Code",
    },
  ],
};

export const zyngaSpecificationsJsonSchema = {
  type: "object",
  properties: {
    digital_benefit_name: {
      type: "string",
      title: "Digital Reward Name",
      minLength: 1,
    },
    schema: {
      type: "string",
      title: "Schema",
      minLength: 1,
    },
    host: {
      type: "string",
      title: "Host",
      minLength: 1,
    },
    reward_parameters: {
      type: "array",
      minItems: 1,
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
            title: "Key",
            minLength: 1,
          },
          value: {
            type: "string",
            title: "Value",
            minLength: 1,
          },
          operating_system: {
            type: "string",
            title: "Operating System",
            enum: ["ios", "android"],
          },
        },
        required: ["key", "value", "operating_system"],
      },
    },
  },
  required: ["digital_benefit_name", "schema", "host", "reward_parameters"],
};

export const zyngaSpecificationsUiSchema = {
  type: "VerticalLayout",
  className: "reward-specific-fields",
  elements: [
    {
      type: "SimpleGroup",
      label: "Specifications - Digital Reward",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/digital_benefit_name",
              label: "Digital Reward Name",
            },
            {
              type: "Control",
              scope: "#/properties/schema",
              label: "Schema",
            },
            {
              type: "Control",
              scope: "#/properties/host",
              label: "Host",
            },
          ],
        },
      ],
    },
    {
      type: "SimpleGroup",
      label: "Specifications - Reward Parameters",
      elements: [
        {
          type: "Control",
          scope: "#/properties/reward_parameters",
          label: "Add Parameter",
          component: "arrayOfTexts",
          options: {
            detail: {
              type: "HorizontalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/key",
                  label: "Key",
                },
                {
                  type: "Control",
                  scope: "#/properties/value",
                  label: "Value",
                },
                {
                  type: "Control",
                  scope: "#/properties/operating_system",
                  label: "Operating System",
                  component: "stringSelect",
                  options: {
                    labelMapping: {
                      ios: "iOS",
                      android: "Android",
                    },
                  },
                },
              ],
            },
          },
        },
      ],
    },
  ],
};

export const sodexoSpecificationsJsonSchema = {
  type: "object",
  properties: {
    image_on_great_choice_screen: {
      type: "string",
      title: "Image on Great Choice Screen",
      format: "uri",
    },
    great_choice_screen_description: {
      type: "string",
      title: "Great Choice Screen Description",
      minLength: 1,
    },
  },
  required: ["image_on_great_choice_screen", "great_choice_screen_description"],
};

export const sodexoSpecificationsUiSchema = {
  type: "SimpleGroup",
  label: "Specifications",
  className: "basic-setup-columns",
  elements: [
    {
      type: "HorizontalLayout",
      className: "partner-reward-specifications",
      elements: [
        {
          type: "Control",
          scope: "#/properties/image_on_great_choice_screen",
          label: "Image on Great Choice Screen",
          component: "singleImageUpload",
        },
        {
          type: "Control",
          scope: "#/properties/great_choice_screen_description",
          label: "Great Choice Screen Description",
          component: "textarea",
          placeholder: "Type here",
        },
      ],
    },
  ],
};

export const internetPackageSpecificationsJsonSchema = {
  type: "object",
  properties: {
    external_id: {
      type: "string",
      title: "External ID",
      minLength: 1,
    },
  },
  required: ["external_id"],
};

export const internetPackageSpecificationsUiSchema = {
  type: "SimpleGroup",
  label: "Specifications",
  className: "reward-specific-fields",
  elements: [
    {
      type: "Control",
      scope: "#/properties/external_id",
      label: "External ID",
    },
  ],
};
