import { Breadcrumb } from "pepsico-ds";
import AppConfigurationsForm from "../../components/management/AppConfigurations/AppConfigurationsForm";
import { paths } from "../../routes/paths";

export default function AppConfigurationsPage() {
  return (
    <div className="container">
      <div className="wrap">
        <Breadcrumb
          breadcrumbs={[
            {
              label: "System Setting",
              href: `../../${paths.system_setting}`,
            },
            {
              label: "View User Limit Settings",
              href: `../../${paths.system_setting}/${paths.user_limit_settings_edit}`,
            },
          ]}
          activeIndex={1}
        />
        <div className="heading-wrap">
          <h2>User Limit Settings</h2>
        </div>
        <div className="app-configurations-form">
          <AppConfigurationsForm isEditPage={false} />
        </div>
      </div>
    </div>
  );
}
