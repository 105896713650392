import { Breadcrumb, Button } from "pepsico-ds";
// ../components/common/CodeGroupGridCar
import { useQuery } from "@apollo/client";
import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "../../routes/paths";
import loadContent from "../banners/graphQl/queries/loadContent";
import "./../../styles/common.scss";
import "./ManagementPage.scss";

export default function TermAndConditionViewPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [termAndConditionData, setTermAndConditionData] = useState("");

  const dataReceived = location.state;
  const initialFilter = {
    id: dataReceived?.id,
  };
  const { data, loading, error } = useQuery(loadContent, {
    variables: initialFilter,
  });

  useEffect(() => {
    if (!dataReceived) {
      navigate(`/${paths.system_setting}/${paths.term_and_condition}`);
    }
    if (data && !loading && !error) {
      setTermAndConditionData(data?.loadContent);
    }
  }, [data, loading, error, dataReceived]);

  const onCLickClose = () => {
    navigate(`/${paths.system_setting}/${paths.term_and_condition}`);
  };
  return (
    <div className="container">
      <div className="wrap">
        <Breadcrumb
          breadcrumbs={[
            {
              label: "System Setting",
              href: `../../${paths.system_setting}`,
            },
            {
              label: "Terms and Conditions",
              href: `../../${paths.system_setting}/${paths.term_and_condition}`,
            },
            { label: `View ${termAndConditionData?.title}` },
          ]}
          activeIndex={2}
        />

        {termAndConditionData?.contentData?.is_required && (
          <Chip
            sx={{ backgroundColor: "#AAD2E2", width: "150px" }}
            size="small"
            label="Required to accept"
          />
        )}

        <h2>{termAndConditionData?.title}</h2>
        <h2>{termAndConditionData?.contentData?.title}</h2>
        <p>{termAndConditionData?.contentData?.text}</p>
        {termAndConditionData?.contentData?.permissions &&
          termAndConditionData?.contentData?.permissions.length > 0 && (
            <div style={{ maxHeight: "350px", overflow: "scroll" }}>
              <div className="permissions-container">
                {termAndConditionData?.contentData?.permissions?.map(
                  (permission) => (
                    <div key={permission.id} className="permission-item">
                      <p>
                        <strong>ID</strong>: {permission?.id}
                      </p>
                      <p>
                        <strong>Name</strong>: {permission?.name}
                      </p>
                      <p>
                        <strong>Text</strong>: {permission?.text}
                      </p>
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        <hr className="custom-hr" />
        <div className="button-container">
          <Button
            size="medium"
            text="Close"
            variant="primary"
            onClick={onCLickClose}
          />
        </div>
      </div>
    </div>
  );
}
