import CheckIcon from "@mui/icons-material/Check";
import PropTypes from "prop-types";
import { getDateWithFormat } from "../../../utils/commons";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { partnersCodeGroupActionButtons } from "../partnersCodeGroupActionButton";
import "./PartnerCodeGroupTable.scss";

const PartnerCodeGroupTable = ({
  partnerCodeGroupData,
  handleActionButtons,
  currentProgramTimezone,
}) => {
  /* We need this code for future change so commented */
  /* const getStatus = (status, startDate, endDate) => {
    if (
      new Date(moment.tz(startDate, currentProgramTimezone)) >
      new Date(moment.tz(currentProgramTimezone))
    ) {
      return "imported";
    }
    if (
      new Date(moment.tz(endDate, currentProgramTimezone)) <
      new Date(moment.tz(currentProgramTimezone))
    ) {
      return "expired";
    }
    return status ? "active" : "anactive";
  }; */

  return (
    <div className="pcg-list-container">
      <table className="pcg-list-table">
        <thead>
          <tr>
            <th className="column-status">Status</th>
            <th className="column-code-status">Code Imported</th>
            <th className="column-title">Code Group Name</th>
            <th className="column-description">Description</th>
            <th className="column-active-date">Active Date</th>
            <th className="column-inactive-date">Import Date</th>
            <th className="column-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {partnerCodeGroupData.map((item, index) => (
            <tr key={index}>
              <td className="pcg-status-type">
                <span className={`status ${item?.isActive ? "active" : "inactive"}`}>
                  {item?.isActive ? "active" : "inactive"}
                </span>
              </td>
              <td className="pcg-status-type code-status">
                {item?.isImported ? (
                  <span
                    className={
                      item?.isImported ? "status active" : "status inactive"
                    }
                  >
                    Code Imported <CheckIcon fontSize="small" />
                  </span>
                ) : (
                  "Not Imported"
                )}
              </td>
              <td className={`${/\s/.test(item.name) ? "" : "no-space-title"}`}>
                {item.name || "-"}
              </td>
              <td>{item?.description || "-"}</td>
              <td>
                {getDateWithFormat(item?.startDate, currentProgramTimezone) || "-"} -{" "}
                {getDateWithFormat(item?.endDate, currentProgramTimezone) || "-"}
              </td>
              <td>
                {item?.importedDate
                  ? new Date(item?.importedDate).toLocaleDateString()
                  : "N/A"}
              </td>
              <td>
                <div className="button-group-wrapper">
                  <ButtonGroupDynamic
                    items={partnersCodeGroupActionButtons(
                      item?.isActive ? "active" : "inactive",
                      handleActionButtons,
                      item
                    )}
                    spacing="0px"
                    size="medium"
                    variant="control"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PartnerCodeGroupTable.propTypes = {
  partnerCodeGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      partnerCodeGroupStatus: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      totalCodes: PropTypes.number.isRequired,
      exhaustedCodes: PropTypes.number.isRequired,
      activeDate: PropTypes.string.isRequired,
      inactiveDate: PropTypes.string.isRequired,
      codeUploadStatus: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PartnerCodeGroupTable;
