import { gql } from "@apollo/client";

export const CreateCodeGroupMutation = gql`
  mutation ($codeGroup: CodeGroupInput!) {
    createCodeGroup(codeGroup: $codeGroup) {
      codeGroupId
    }
  }
`;

export const UpdateCodeGroupMutation = gql`
  mutation ($codeGroup: CodeGroupUpdate!, $id: String!) {
    updateCodeGroup(codeGroupUpdate: $codeGroup, id: $id) {
      code
      text
    }
  }
`;

export const createDraftCodeGroupMutation = gql`
  mutation CreateDraftCodeGroup($codeGroup: CodeGroupDraftInput!) {
    createDraftCodeGroup(codeGroup: $codeGroup) {
      codeGroupId
    }
  }
`;
export const updateDraftCodeGroupMutation = gql`
  mutation UpdateDraftCodeGroup($id: String!, $codeGroup: CodeGroupDraftInput!) {
    updateDraftCodeGroup(id: $id, codeGroup: $codeGroup) {
      code
      text
    }
  }
`;
