export const programConfigDuplicateJson = {
  loadProgram: {
    id: "018a2bd5-fdc2-77ce-9367-7d700186d5ae",
    name: "Pepsico Tasty Rewards",
    description: "Program configuration for PTR",
    slug: "ptr",
    logo: "/logo.png",
    defaultValues: {},
    tags: {
      campaign: [
        "ProfileTag1",
        "Test tag 1",
        "test_new_tag",
        "testCreation",
        "Label",
        "new_tag_test2",
        "New T",
        "TAG1",
        "test 4",
        "test 3",
        "taggggggs",
        "Test Take a survey",
        "xyz",
        "andreza",
        "LinkTag1",
        " test 67  test 67   ",
        "testLoadingTag2",
        "AutoTag1",
        "ReferFriendTag1",
        "aa",
        "selectOnCreation1",
        "STAG",
        "test",
        "MY_AWESOME_TAG_2000",
        "Test123",
        "campaign_test",
        "WILL_THIS_WORK",
        "test5",
        "example_tag",
        "test2",
        "r",
        "testLoadingTag",
        "Bug Retest",
        "Teste Andreza",
        "hello",
        "test_campaign_tag",
        "SUMMER 2024",
      ],
      content: ["test"],
    },
    localization: {
      programTimezone: "America/Chicago",
      countries: [
        {
          key: "US",
          value: {
            languages: ["en-us", "es-us"],
            currencies: ["USD"],
            addressSchema: {},
          },
        },
      ],
    },
    campaigns: [
      {
        key: "upload_receipt",
        value: {
          enabled: true,
          label: "Upload a Receipt",
          image:
            "https://pepsicotest.getbynder.com/m/6183cec2bcf98239/original/content_86cd7a15-1212-464b-854f-573ecd02d314.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["upload-receipt"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "545988e0-932b-4420-a0d1-868e4743793e",
                name: "Upload Receipt",
                description:
                  "Awards entries after a user upload a receipt containing the selected keywords",
                inputSchema: {
                  type: "object",
                  properties: {
                    receipt_data: {
                      type: "object",
                    },
                  },
                  required: ["receipt_data"],
                },
                requiredRules: [
                  "match-brands-keywords",
                  "user-daily-limit",
                  "match-outlets-keywords",
                ],
                requiredOutcomes: ["award-points-with-streak"],
                slug: "upload-receipt",
                maxAllowed: 1,
                rules: [
                  {
                    id: "018fa743-e42f-75ca-884b-67d512454a6f",
                    name: "Brands",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of brand ids selected by the admin",
                          filter_by: "BRAND",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "match-brands-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "019073a7-39ff-7e45-b60d-c41c929f5809",
                    name: "Minimum Purchase Value",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        min_purchase_val: {
                          default: 1,
                          title: "Min Purchase Value",
                          type: "number",
                          minimum: 1,
                        },
                      },
                      required: ["min_purchase_val"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "min-purchase-val",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/min_purchase_val",
                          prefexLabel: "Per",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907e31-8c2a-7388-b934-2ef174dc93d2",
                    name: "Retailers",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description: "Array of outlet ids selected by the admin",
                          filter_by: "OUTLET",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "match-outlets-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "01907ec4-1a19-76ac-8d44-4b3dc2c6e8ec",
                    name: "Receipt recency",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        end_timestamp: {
                          title: "End Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                        start_timestamp: {
                          title: "Start Date/Time",
                          type: "number",
                          date_format: "unix",
                        },
                      },
                      required: ["start_timestamp"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "receipt-in-date-range",
                    uiSchema: {
                      label: "Receipt date",
                      type: "HorizontalLayout",
                      label_classname: "receipt-recency-label",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          component: "dateTimePicker",
                          scope: "#/properties/start_timestamp",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                gt: "start_timestamp",
                              },
                            },
                            effect: "VALIDATE",
                          },
                          component: "dateTimePicker",
                          type: "Control",
                          scope: "#/properties/end_timestamp",
                        },
                      ],
                    },
                  },
                  {
                    id: "0190dcb4-54da-76bc-88a4-dd9f143ced9e",
                    name: "SKU/Products",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        ids: {
                          description:
                            "Array of SKU/product ids selected by the admin",
                          filter_by: "PRODUCT",
                          type: "array",
                          items: {
                            format: "uuid",
                            type: "string",
                          },
                        },
                      },
                      required: ["ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: null,
                    slug: "match-products-keywords",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "keywordController",
                          scope: "#/properties/ids",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "019091a6-0304-7b31-9e77-48d9dbd585cf",
                    name: "Award Entries",
                    slug: "award-points-with-streak",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                      {
                        id: "01906983-1d36-7531-a5d2-24e60747b252",
                        name: "Validate Configurable Streak",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            performance_count: {
                              title: "Performance Count",
                              type: "integer",
                              minimum: 1,
                            },
                            interval: {
                              title: "Interval",
                              type: "string",
                              oneOf: [
                                {
                                  const: "this_hour",
                                  title: "In an hour",
                                },
                                {
                                  const: "today",
                                  title: "In a day",
                                },
                                {
                                  const: "this_week",
                                  title: "In a week",
                                },
                                {
                                  const: "this_month",
                                  title: "In a month",
                                },
                                {
                                  const: "this_year",
                                  title: "In a year",
                                },
                                {
                                  const: "all_time",
                                  title: "All times",
                                },
                              ],
                            },
                          },
                        },
                        ruleFunction: "validate",
                        maxAllowed: null,
                        slug: "validate-streak-configurable",
                        uiSchema: {
                          type: "HorizontalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/performance_count",
                            },
                            {
                              type: "Control",
                              component: "muiSelect",
                              scope: "#/properties/interval",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "01907379-b59a-788e-9b59-4fdb9ce7b5d6",
              name: "Upload receipt campaign content definition",
              slug: "upload-receipt-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Upload Receipt Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      redirect_link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Link to redirect",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/redirect_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "upload-receipt-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "log_in",
        value: {
          enabled: true,
          label: "Log In",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["login"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "018fa743-e430-77a3-ba86-f2a7b87afe08",
                name: "Login",
                description: "Awards entries when a user succesfully logs in.",
                inputSchema: {},
                requiredRules: [],
                requiredOutcomes: ["award-points-with-streak"],
                slug: "login",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "019091a6-0304-7b31-9e77-48d9dbd585cf",
                    name: "Award Entries",
                    slug: "award-points-with-streak",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                      {
                        id: "01906983-1d36-7531-a5d2-24e60747b252",
                        name: "Validate Configurable Streak",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            performance_count: {
                              title: "Performance Count",
                              type: "integer",
                              minimum: 1,
                            },
                            interval: {
                              title: "Interval",
                              type: "string",
                              oneOf: [
                                {
                                  const: "this_hour",
                                  title: "In an hour",
                                },
                                {
                                  const: "today",
                                  title: "In a day",
                                },
                                {
                                  const: "this_week",
                                  title: "In a week",
                                },
                                {
                                  const: "this_month",
                                  title: "In a month",
                                },
                                {
                                  const: "this_year",
                                  title: "In a year",
                                },
                                {
                                  const: "all_time",
                                  title: "All times",
                                },
                              ],
                            },
                          },
                        },
                        ruleFunction: "validate",
                        maxAllowed: null,
                        slug: "validate-streak-configurable",
                        uiSchema: {
                          type: "HorizontalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/performance_count",
                            },
                            {
                              type: "Control",
                              component: "muiSelect",
                              scope: "#/properties/interval",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "9339b9fb-3ad6-4baa-b6d8-9bab4aa750c9",
              name: "Log in campaign content definition",
              slug: "log-in-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Log in Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "log-in-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "sweepstakes",
        value: {
          enabled: true,
          label: "Sweepstakes",
          image:
            "https://pepsicotest.getbynder.com/m/56244251ed4e41d6/original/content_aa534b6a-837c-4efa-bc0c-59eee1cb4d2c.png",
          categories: [
            "Bundle",
            "Gift Cards",
            "High Value",
            "Low Value",
            "Medium Value",
            "Trips/Experiences",
          ],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["sweepstakes"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "0190c75b-43db-7d07-9cda-b03a823eb2e7",
                name: "Enter Sweepstakes",
                description: "Buy sweepstakes entries",
                inputSchema: {},
                requiredRules: ["require-phone", "exclude-email"],
                requiredOutcomes: ["purchase-entry"],
                slug: "sweepstakes",
                maxAllowed: 1,
                rules: [
                  {
                    id: "0190c76a-8bf4-7775-bca7-93d959bcc1b4",
                    name: "Require user phone number",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        require_phone: {
                          description: "Require user profile to have phone-number",
                          default: true,
                          title: "Phone Number Required",
                          type: "boolean",
                        },
                      },
                      required: ["require_phone"],
                    },
                    ruleFunction: "validation",
                    maxAllowed: 1,
                    slug: "require-phone",
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/require_phone",
                        },
                      ],
                    },
                  },
                  {
                    id: "0190c89b-22d5-71b8-b100-572dca435352",
                    name: "Exclude emails",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      $schema: "http://json-schema.org/draft-07/schema",
                      type: "object",
                      properties: {
                        domains: {
                          title: "Domains to exclude",
                          type: "array",
                          items: {
                            type: "string",
                          },
                          description:
                            "Comma separated values of domains that will be denied",
                        },
                        exclude: {
                          title: "Exclude certain domains from winners",
                          type: "boolean",
                          default: false,
                        },
                      },
                      required: ["exclude"],
                    },
                    ruleFunction: "validation",
                    maxAllowed: null,
                    slug: "exclude-email",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "toggle",
                          scope: "#/properties/exclude",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/exclude",
                            },
                            effect: "ENABLE",
                          },
                          placeholder: "@pepsico.com,@domain.com",
                          type: "Control",
                          component: "emailDomainsComponent",
                          scope: "#/properties/domains",
                          info: "Everyone can join sweeps, but the domains will be excluded only from winner selection process",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "019094af-b1d5-7a71-b239-d31a48270994",
                    name: "Sweepstakes Purchase",
                    slug: "purchase-entry",
                    description: "Awards Sweepstakes entries to a user.",
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        winners_required: {
                          description:
                            "Maximum number of winners that the sweepstakes can have",
                          default: 1,
                          title: "Maximum number of winners",
                          type: "integer",
                          minimum: 1,
                        },
                        target_id: {
                          hidden: true,
                          type: "string",
                          description: "Created sweepstakes id",
                        },
                        source: {
                          const: "points",
                          hidden: true,
                          type: "string",
                        },
                        target: {
                          const: "sweepstakes",
                          hidden: true,
                          type: "string",
                        },
                      },
                      required: ["winners_required", "target", "source"],
                    },
                    uiSchema: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/winners_required",
                          label: "Maximum number of winners",
                        },
                      ],
                    },
                    requiredRules: ["calculate-cost"],
                    rules: [
                      {
                        id: "0190c8aa-d861-77f9-a3f6-b4cd64e77fd9",
                        name: "Sweepstakes Entry Cost",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            cost: {
                              default: 1,
                              title: "Entry's price",
                              type: "number",
                              minimum: 1,
                            },
                          },
                          required: ["cost"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-cost",
                        uiSchema: {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/properties/cost",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "categorySelect",
                              label: "Category",
                              type: "Control",
                              scope: "#/properties/category",
                            },
                            {
                              component: "brandSelect",
                              label: "Brand",
                              type: "Control",
                              scope: "#/properties/brands",
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "category",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                category: {
                  title: "Category",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "category"],
            },
          },
          contentSteps: [
            {
              id: "52dee9da-24ba-4d23-877f-dde00733f9b8",
              name: "Sweepstakes Campaign Content Definition",
              slug: "sweepstakes-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Sweepstakes Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                      terms_and_conditions: {
                        format: "uri",
                        description:
                          "URL to consumer terms and conditions to participate in the campaign",
                        title: "Terms and Conditions URL",
                        type: "string",
                      },
                    },
                    required: [
                      "title",
                      "description",
                      "campaign_image_url",
                      "terms_and_conditions",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                l10n: {
                  className: "content-language-wrapper",
                  title: "Content*",
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "SimpleGroup",
                      className: "content-language-wrapper__content",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              options: {
                                format: "data-url",
                              },
                              component: "singleImageUpload",
                              type: "Control",
                              scope:
                                "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                            },
                            {
                              type: "VerticalLayout",
                              elements: [
                                {
                                  type: "VerticalLayout",
                                  elements: [
                                    {
                                      type: "Control",
                                      maxLength: 150,
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                    },
                                    {
                                      component: "textarea",
                                      type: "Control",
                                      maxLength: 500,
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                    },
                                    {
                                      type: "Control",
                                      scope:
                                        "#/properties/l10n/properties/{l10n_placeholder}/properties/terms_and_conditions",
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "sweepstakes-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup-new",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "take_a_survey",
        value: {
          enabled: true,
          label: "Take a Survey",
          image:
            "https://pepsicotest.getbynder.com/m/171682b1a76ade55/original/content_2daf6d01-bf02-48f1-ab38-e33c0fda4dbb.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["take-a-survey"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "018fa743-e430-77f7-929e-838549402121",
                name: "Take a Survey",
                description: "Awards entries after a user takes a survey.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "array",
                    },
                  },
                  required: ["identifier"],
                },
                requiredRules: ["match-survey-ids"],
                requiredOutcomes: ["award-points"],
                slug: "take-a-survey",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "eb5cc6c8-7352-48b9-9e2d-6cf040f501e7",
                    name: "Match Survey Ids",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        survey_ids: {
                          title: "Survey IDs",
                          type: "array",
                        },
                      },
                      required: ["survey_ids"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 0,
                    slug: "match-survey-ids",
                    uiSchema: {
                      type: "VerticalLayout",
                      elements: [
                        {
                          type: "Control",
                          component: "singleInputArray",
                          scope: "#/properties/survey_ids",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0190695c-6fcb-7ce9-a416-e3bb91eb6513",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "01907eff-9ec7-7e0f-8a19-9381c16673c5",
              name: "Take a Survey campaign content definition",
              slug: "take-a-survey-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Take a Survey Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      survey_link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Survey Link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: [
                      "title",
                      "description",
                      "campaign_image_url",
                      "survey_link",
                    ],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/survey_link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "take-a-survey-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "code_redemption",
        value: {
          enabled: true,
          label: "Code Redemption",
          image:
            "https://pepsicotest.getbynder.com/m/53af30774360d493/original/content_e49d8374-3009-4794-afa8-8203199dc5db.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["unlimited-code"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "018fa743-e430-7b37-abd2-178994ba1c05",
                name: "Unlimited Code",
                description: "Awards entries after a user enters an unlimited code.",
                inputSchema: {
                  type: "object",
                  properties: {
                    identifier: {
                      type: "string",
                    },
                  },
                  required: ["identifier"],
                },
                requiredRules: ["match-unlimited-code"],
                requiredOutcomes: ["award-points"],
                slug: "unlimited-code",
                maxAllowed: 1,
                rules: [
                  {
                    id: "018fa743-e430-7b8f-9093-1e7f197e6401",
                    name: "Match Unlimited Code",
                    hidden: false,
                    inputSchema: {
                      type: "object",
                      properties: {
                        code: {
                          pattern: "^.*[a-zA-Z0-9]+.*$",
                          not: {
                            type: "null",
                          },
                          title: "Unlimited Code",
                          type: ["string"],
                          minLength: 1,
                        },
                      },
                      required: ["code"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "match-unlimited-code",
                    uiSchema: {
                      type: "VerticalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/code",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0190695c-6fcb-7ce9-a416-e3bb91eb6513",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "93f18de8-77d7-4484-bf3c-c649abc3f41b",
              name: "Code Redemption campaign content definition",
              slug: "code-redemption-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Code Redemption (unlimited) Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    maxLength: 150,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    component: "textarea",
                                    type: "Control",
                                    maxLength: 500,
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "code-redemption-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
      {
        key: "complete_your_profile",
        value: {
          enabled: true,
          label: "Complete your Profile",
          image:
            "https://pepsicotest.getbynder.com/m/19a559cf87f4f42f/original/content_4586b0d3-c81f-4568-a6f1-a7cc8d9a1c9a.png",
          categories: [],
          requirement: {
            requiredOutcomes: [],
            requiredActivities: ["profile-completion"],
            requiredRules: [],
            possibleOutcomes: [],
            possibleActivities: [
              {
                id: "018fa743-e430-7b83-b4eb-947f2445682e",
                name: "Profile Completion",
                description: "Awards entries when a user completes their profile.",
                inputSchema: {},
                requiredRules: [],
                requiredOutcomes: ["award-points"],
                slug: "profile-completion",
                maxAllowed: null,
                rules: [
                  {
                    id: "01906967-dbc7-7020-9c30-6085bd2a72a4",
                    name: "User Daily limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Single User Daily limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-daily-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-0922-7ff5-80ce-5fa7fc176ea4",
                    name: "User Hourly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Hourly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-hourly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906969-a455-72fd-b4c3-25662ffdf13e",
                    name: "User Campaign limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Campaign limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-campaign-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906977-54e9-7794-a27d-012f762d86f8",
                    name: "User Monthly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Monthly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-monthly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "01906978-8fcf-7e03-aba1-e922c539975a",
                    name: "Total Participation Limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "Total Participation Limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "total-participation-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                  {
                    id: "76953929-2ac5-4f02-a937-3dbaf71b07ea",
                    name: "User Weekly limit",
                    hidden: false,
                    inputSchema: {
                      additionalProperties: false,
                      type: "object",
                      properties: {
                        limit: {
                          title: "User Weekly limit",
                          type: "integer",
                          minimum: 1,
                        },
                      },
                      required: ["limit"],
                    },
                    ruleFunction: "validate",
                    maxAllowed: 1,
                    slug: "user-weekly-limit",
                    uiSchema: {
                      type: "HorizontalLayout",
                      class_name: "custom-width",
                      elements: [
                        {
                          type: "Control",
                          maxLength: 10,
                          scope: "#/properties/limit",
                        },
                      ],
                    },
                  },
                ],
                outcomes: [
                  {
                    id: "0190695c-6fcb-7ce9-a416-e3bb91eb6513",
                    name: "Award Entries",
                    slug: "award-points",
                    description: "Awards entries to a user.",
                    inputSchema: {},
                    uiSchema: {},
                    requiredRules: ["calculate-points"],
                    rules: [
                      {
                        id: "0190695e-6e1f-73b2-bab3-d4448161bae3",
                        name: "Entries",
                        hidden: false,
                        inputSchema: {
                          type: "object",
                          properties: {
                            points: {
                              title: "Number of Entries",
                              type: "number",
                            },
                          },
                          required: ["points"],
                        },
                        ruleFunction: "calculate",
                        maxAllowed: 1,
                        slug: "calculate-points",
                        uiSchema: {
                          type: "VerticalLayout",
                          class_name: "custom-width",
                          elements: [
                            {
                              type: "Control",
                              maxLength: 10,
                              scope: "#/properties/points",
                            },
                          ],
                        },
                      },
                    ],
                  },
                ],
              },
            ],
            possibleRules: [],
          },
          campaignSetup: {
            uiSchema: {
              type: "SimpleGroup",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      maxLength: 50,
                      scope: "#/properties/title",
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Always active",
                              type: "Control",
                              scope: "#/properties/endless",
                            },
                          ],
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [],
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Active Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/activeStartDate",
                            },
                            {
                              type: "HorizontalLayout",
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/endless",
                                },
                                effect: "DISABLE",
                              },
                              elements: [
                                {
                                  rule: {
                                    condition: {
                                      schema: {
                                        gt: "activeStartDate",
                                      },
                                    },
                                    effect: "VALIDATE",
                                  },
                                  component: "dateTimePicker",
                                  label: "Active End Date/Time",
                                  type: "Control",
                                  minimum: "currentDateTime",
                                  scope: "#/allOf/0/then/properties/activeEndDate",
                                },
                              ],
                            },
                          ],
                        },
                        {
                          type: "Control",
                          component: "brandSelect",
                          scope: "#/properties/brands",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "dateTimePicker",
                              label: "Display Start Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayStartDate",
                            },
                            {
                              component: "dateTimePicker",
                              label: "Display End Date/Time",
                              type: "Control",
                              minimum: "currentDateTime",
                              scope: "#/properties/displayEndDate",
                            },
                          ],
                        },
                        {
                          component: "campaignTagSelect",
                          type: "Control",
                          maxLength: 20,
                          scope: "#/properties/tags",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              DISABLED: "Disabled",
                              HIDDEN: "Hidden",
                            },
                          },
                          component: "radioInput",
                          label: "When activity is completed, show campaign card as",
                          type: "Control",
                          scope: "#/properties/displayAfterEndDate",
                        },
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              component: "toggle",
                              label: "Show number of entries in campaign card",
                              type: "Control",
                              scope: "#/properties/hasShowEntriesCount",
                            },
                            {
                              component: "toggle",
                              label: "Show in Member Campaign History",
                              type: "Control",
                              scope: "#/properties/hasShowInHistory",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Basic Setup",
            },
            inputSchema: {
              allOf: [
                {
                  if: {
                    properties: {
                      endless: {
                        const: false,
                      },
                    },
                  },
                  then: {
                    required: [
                      "activeEndDate",
                      "title",
                      "activeStartDate",
                      "displayAfterEndDate",
                    ],
                  },
                },
              ],
              title: "Basic Setup",
              type: "object",
              properties: {
                hasShowEntriesCount: {
                  title: "Show number of entries in campaign card",
                  type: "boolean",
                  default: true,
                },
                activeStartDate: {
                  title: "Active Start Date/Time",
                  type: "string",
                },
                brands: {
                  type: "array",
                },
                displayStartDate: {
                  title: "Display Start Date/Time",
                  type: ["string", "null"],
                },
                displayAfterEndDate: {
                  title: "When activity is completed, show campaign card as",
                  type: "string",
                  enum: ["HIDDEN", "DISABLED"],
                },
                endless: {
                  title: "Always active",
                  type: "boolean",
                },
                activeEndDate: {
                  title: "Active End Date/Time",
                  type: "string",
                },
                title: {
                  title: "Campaign Name",
                  type: "string",
                  minLength: 1,
                },
                displayEndDate: {
                  title: "Display End Date/Time",
                  type: ["string", "null"],
                },
                hasShowInHistory: {
                  title: "Show in Member Campaign History",
                  type: "boolean",
                  default: true,
                },
                tags: {
                  title: "Tags",
                  type: "array",
                },
              },
              required: ["title", "activeStartDate", "displayAfterEndDate"],
            },
          },
          contentSteps: [
            {
              id: "d64f04e4-708f-41be-a865-3bc2c32ed281",
              name: "Complete your Profile campaign content definition",
              slug: "complete-your-profile-campaign-content",
              version: 1,
              description: "Definition of campaign content object",
              active: true,
              inputSchema: {
                $schema: "http://json-schema.org/draft-07/schema#",
                additionalProperties: false,
                title: "Complete your Profile Campaign Content Definition",
                type: "object",
                definitions: {
                  localized_content: {
                    additionalProperties: false,
                    type: "object",
                    properties: {
                      link: {
                        pattern:
                          "(https://www.|http://www.|https://|http://)?[a-zA-Z0-9]{2,}(.[a-zA-Z0-9]{2,})(.[a-zA-Z0-9]{2,})",
                        message: 'must match pattern "uri"',
                        title: "Content link",
                        type: "string",
                      },
                      campaign_image_url: {
                        format: "uri",
                        title: "Content image url",
                        type: "string",
                      },
                      description: {
                        title: "Content description",
                        type: "string",
                        minLength: 1,
                      },
                      image_description: {
                        title: "Image description",
                        type: "string",
                      },
                      title: {
                        title: "Content title",
                        type: "string",
                        minLength: 1,
                      },
                    },
                    required: ["title", "description", "campaign_image_url"],
                  },
                },
                properties: {
                  l10n: {
                    anyOf: [
                      {
                        required: ["en-us"],
                      },
                      {
                        required: ["fr-ca"],
                      },
                      {
                        required: ["es-mx"],
                      },
                    ],
                    additionalProperties: false,
                    type: "object",
                    propertyNames: {
                      enum: ["en-us", "es-mx", "fr-ca"],
                    },
                    properties: {
                      "es-mx": {
                        $ref: "#/definitions/localized_content",
                      },
                      "fr-ca": {
                        $ref: "#/definitions/localized_content",
                      },
                      "en-us": {
                        $ref: "#/definitions/localized_content",
                      },
                    },
                  },
                },
                required: ["l10n"],
              },
              uiSchema: {
                className: "content-language-wrapper",
                title: "Content*",
                type: "VerticalLayout",
                elements: [
                  {
                    type: "SimpleGroup",
                    className: "content-language-wrapper__content",
                    elements: [
                      {
                        type: "HorizontalLayout",
                        elements: [
                          {
                            options: {
                              format: "data-url",
                            },
                            component: "singleImageUpload",
                            type: "Control",
                            scope:
                              "#/properties/l10n/properties/{l10n_placeholder}/properties/campaign_image_url",
                          },
                          {
                            type: "VerticalLayout",
                            elements: [
                              {
                                type: "VerticalLayout",
                                elements: [
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/title",
                                  },
                                  {
                                    type: "Control",
                                    component: "textarea",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/description",
                                  },
                                  {
                                    type: "Control",
                                    scope:
                                      "#/properties/l10n/properties/{l10n_placeholder}/properties/link",
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
          steps: [
            {
              label: "Basics",
              description: "",
              shortTile: null,
              slug: "generic-setup",
              values: [
                {
                  type: "campaign-setup",
                  definition: "campaign-setup-content",
                },
                {
                  type: "content",
                  definition: "complete-your-profile-campaign-content",
                },
                {
                  type: "segment",
                },
              ],
            },
            {
              label: " Activities",
              description: "",
              shortTile: null,
              slug: "activity-level-setup",
              values: [
                {
                  multiple: false,
                  type: "activities",
                  duplicate: true,
                },
              ],
            },
            {
              label: "Summary",
              description: "",
              shortTile: null,
              slug: "summary",
              values: [],
            },
          ],
        },
      },
    ],
    widgets: [
      {
        id: "20eb798c-2869-49ed-a11c-c67923564982",
        embedCode:
          '<pep-loyalty widget-id="20eb798c-2869-49ed-a11c-c67923564982" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Widget Member History 2",
        isActive: false,
        widgetData: {
          historyType: "SWEEPSTAKES_HISTORY",
          customWidget: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-06-26T12:35:01.054415534Z",
        updatedAt: null,
      },
      {
        id: "da2588af-26b6-4f82-9934-8522dc6ac7cc",
        embedCode:
          '<pep-loyalty widget-id="da2588af-26b6-4f82-9934-8522dc6ac7cc" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Widget Member History 3",
        isActive: false,
        widgetData: {
          historyType: "ACTIVITY_HISTORY",
          customWidget: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-06-26T12:35:01.054415534Z",
        updatedAt: null,
      },
      {
        id: "e7590de2-1f1e-428f-a121-afd459cb9ea1",
        embedCode:
          '<pep-loyalty widget-id="e7590de2-1f1e-428f-a121-afd459cb9ea1" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Widget Member History 5",
        isActive: false,
        widgetData: {
          historyType: "SWEEPSTAKES_HISTORY",
          customWidget: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-06-26T12:35:01.054415534Z",
        updatedAt: null,
      },
      {
        id: "fc14594e-3ed3-4cb7-a5d4-c757db99e86a",
        embedCode:
          '<pep-loyalty widget-id="fc14594e-3ed3-4cb7-a5d4-c757db99e86a" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Sweepstakes History DEMO",
        isActive: true,
        widgetData: {
          headerTextColor: "#f2f6f8",
          pointsTextColor: "#4c7b27",
          historyType: "SWEEPSTAKES_HISTORY",
          maxHeight: 400,
          rowTextColor: "#333",
          customWidget: true,
          headerRowColor: "#497f3a",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-06-26T12:35:01.054415534Z",
        updatedAt: null,
      },
      {
        id: "0f44f158-a84c-4fa2-896b-90a6a53b80b8",
        embedCode:
          '<pep-loyalty widget-id="0f44f158-a84c-4fa2-896b-90a6a53b80b8" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test Schema Campaign",
        isActive: true,
        widgetData: {
          selectedCampaigns: [],
          buttonColor: "#DD2634",
          format: "GRID",
          descriptionColor: "#616161",
          visibleNonMembers: true,
          buttonTextHoverColor: "#DD2634",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#008553",
          itemsPerRow: 4,
          titleColor: "#005CBC",
          unauthenticated: {
            cta: {
              en: {
                url: "Test 1",
                text: "test Text",
              },
              es: {
                url: "Test 2",
                text: "Test 2",
              },
            },
          },
          maxHeight: 256,
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#005CBC",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-06-27T12:03:42.869166461Z",
        updatedAt: "2024-06-27T12:03:42.869166461Z",
      },
      {
        id: "25eb798c-2869-49ed-a11c-c67923564982",
        embedCode:
          '<pep-loyalty widget-id="25eb798c-2869-49ed-a11c-c67923564982" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Widget Member Information",
        isActive: true,
        widgetData: {
          textMainColor: "#2F60A0",
          iconMainColor: "#2F60A0",
          showMemberSignupDate: false,
          textHighlightColor: "#ff9304",
          showDateBalanceUpdated: false,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#DD2634",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-06-26T12:35:01.054415534Z",
        updatedAt: "2024-06-28T13:40:02.036979840Z",
      },
      {
        id: "6c2573e9-a74c-4db0-b52a-24a6d1ed2aec",
        embedCode:
          '<pep-loyalty widget-id="6c2573e9-a74c-4db0-b52a-24a6d1ed2aec" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Information 3 ",
        isActive: true,
        widgetData: {
          showIcons: true,
          textMainColor: "#2F60A0",
          showWelcomeMessage: true,
          showMemberBalance: false,
          iconMainColor: "#2F60A0",
          iconHighlightColor: "#2cdd26",
          customWidget: true,
          showNumberCompletedActivities: false,
          showMemberSignupDate: false,
          textHighlightColor: "#DD2634",
          showNumberActivitiesToComplete: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-06-28T13:42:17.217282373Z",
        updatedAt: "2024-07-01T17:04:03.151990340Z",
      },
      {
        id: "5ac04be9-6cec-425b-b7a7-57e79dccdf2a",
        embedCode:
          '<pep-loyalty widget-id="5ac04be9-6cec-425b-b7a7-57e79dccdf2a" type="sweepstakes"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "sweepstakes",
        title: "Daniel Test Sweeps",
        isActive: true,
        widgetData: {
          warningColor: "#FF891C",
          selectedCampaigns: ["0394f0b5-2ead-4805-9494-c7482bca8fb9"],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          visibleNonMembers: false,
          buttonTextHoverColor: "#000000",
          textColor: "#616161",
          buttonHoverColor: "#11b123",
          itemsPerRow: 4,
          titleColor: "#0057A2",
          maxHeight: 256,
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#005CBC",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-07-01T19:11:25.376300608Z",
        updatedAt: "2024-07-04T18:54:27.508352214Z",
      },
      {
        id: "e923796b-974c-4b64-82d6-9365aa5f63ea",
        embedCode:
          '<pep-loyalty widget-id="e923796b-974c-4b64-82d6-9365aa5f63ea" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test Color History Andreza 2",
        isActive: false,
        widgetData: {
          headerTextColor: "#00fe9d",
          pointsTextColor: "#b8622f",
          historyType: "ACTIVITY_HISTORY",
          maxHeight: 600,
          rowTextColor: "#4c9d6b",
          customWidget: true,
          headerRowColor: "#787fac",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-06-27T19:43:17.617244137Z",
        updatedAt: "2024-07-08T12:26:48.955736034Z",
      },
      {
        id: "0015b347-7f1d-4023-90df-bacfab8d2306",
        embedCode:
          '<pep-loyalty widget-id="0015b347-7f1d-4023-90df-bacfab8d2306" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,ad",
        isActive: false,
        widgetData: {
          historyType: "SWEEPSTAKES_HISTORY",
          customWidget: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-08T17:55:38.596919531Z",
        updatedAt: "2024-07-08T17:55:38.596919531Z",
      },
      {
        id: "4bffd406-e553-4f6a-bfe9-3aafc1fc6366",
        embedCode:
          '<pep-loyalty widget-id="4bffd406-e553-4f6a-bfe9-3aafc1fc6366" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Widget 43657",
        isActive: false,
        widgetData: {
          format: "GRID",
          selectedCampaigns: ["c7e3166c-07e7-4418-9170-d8568e1f453c"],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-07-10T19:49:47.705594716Z",
        updatedAt: "2024-07-10T19:49:47.705594716Z",
      },
      {
        id: "76803d40-c849-4506-b76a-ad5adda234ed",
        embedCode:
          '<pep-loyalty widget-id="76803d40-c849-4506-b76a-ad5adda234ed" type="campaign"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "campaigns",
        title: "test",
        isActive: true,
        widgetData: {
          value: "test",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-16T18:58:04.674853363Z",
        updatedAt: "2024-07-16T19:01:31.727346211Z",
      },
      {
        id: "e6a86dfa-8a3f-4073-9074-bc62125f874f",
        embedCode:
          '<pep-loyalty widget-id="e6a86dfa-8a3f-4073-9074-bc62125f874f" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Teste",
        isActive: false,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "770c9cc2-0720-4929-8042-4e350340e9aa",
            "28a478c2-e11e-4886-a619-89875eea062a",
            "8566fbe9-8234-4d24-81db-f10d9cc2c475",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-16T22:42:54.589639375Z",
        updatedAt: "2024-07-16T22:42:54.589639375Z",
      },
      {
        id: "97122d33-9552-45b8-bad3-57af919a7530",
        embedCode:
          '<pep-loyalty widget-id="97122d33-9552-45b8-bad3-57af919a7530" type="sweepstakes"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "sweepstakes",
        title: "Widget Demo  Sweeps",
        isActive: false,
        widgetData: {
          warningColor: "#db1d2c",
          selectedCampaigns: [
            "fc62799b-3759-4cc1-ae93-122526a33a94",
            "fe9c85a9-7d7c-4284-ae03-6af47ade3490",
            "c7e3166c-07e7-4418-9170-d8568e1f453c",
          ],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.signup.com",
                text: "Registra-se",
              },
              "en-us": {
                url: "www.signup.com",
                text: "Sign up",
              },
            },
          },
          maxHeight: 256,
          cardBgColor: "#aa8f8f",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#36874b",
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-17T14:55:46.707897399Z",
        updatedAt: "2024-07-17T14:55:46.707897399Z",
      },
      {
        id: "15ef12ff-2972-4425-bb02-1902b1fdb7ae",
        embedCode:
          '<pep-loyalty widget-id="15ef12ff-2972-4425-bb02-1902b1fdb7ae" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Demo Widget Campaigns",
        isActive: false,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "e5749539-7c4c-4347-9779-0b083c4d2b90",
            "20ec4d36-0ceb-4471-b536-ba7d93a53b53",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-07-17T14:57:20.329376463Z",
        updatedAt: "2024-07-19T20:03:54.281303016Z",
      },
      {
        id: "63945349-364e-4176-a79e-19cf2f5a4067",
        embedCode:
          '<pep-loyalty widget-id="63945349-364e-4176-a79e-19cf2f5a4067" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Lu Member information",
        isActive: true,
        widgetData: {
          showWelcomeMessage: true,
          showMemberBalance: true,
          customWidget: false,
          showNumberCompletedActivities: true,
          showMemberSignupDate: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showDateBalanceUpdated: true,
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-19T20:15:07.430229780Z",
        updatedAt: "2024-07-19T20:15:07.430229780Z",
      },
      {
        id: "e9672559-9c30-4292-a9df-e5a2a41e0f7e",
        embedCode:
          '<pep-loyalty widget-id="e9672559-9c30-4292-a9df-e5a2a41e0f7e" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test Lu member history - Sweepstake history",
        isActive: false,
        widgetData: {
          historyType: "SWEEPSTAKES_HISTORY",
          customWidget: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-19T20:15:41.308095024Z",
        updatedAt: "2024-07-19T20:15:41.308095024Z",
      },
      {
        id: "faa33e89-1f38-46b7-9d92-e002e140fe90",
        embedCode:
          '<pep-loyalty widget-id="faa33e89-1f38-46b7-9d92-e002e140fe90" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test Lu list of campaigns",
        isActive: false,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "e5749539-7c4c-4347-9779-0b083c4d2b90",
            "20ec4d36-0ceb-4471-b536-ba7d93a53b53",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-07-19T20:16:04.294495580Z",
        updatedAt: "2024-07-19T20:16:04.294495580Z",
      },
      {
        id: "f26d4f81-fd87-4f2c-8b06-22b4988c3ba5",
        embedCode:
          '<pep-loyalty widget-id="f26d4f81-fd87-4f2c-8b06-22b4988c3ba5" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title:
          "Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniamad",
        isActive: false,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "946f23b7-6054-4a49-8f3b-98682be9fb2f",
            "1238cfdb-02c3-422f-9df1-66924b24456a",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-07-19T20:20:51.758779854Z",
        updatedAt: "2024-07-19T20:20:51.758779854Z",
      },
      {
        id: "71d66ae8-7eef-4f33-90fb-a71ffd76e733",
        embedCode:
          '<pep-loyalty widget-id="71d66ae8-7eef-4f33-90fb-a71ffd76e733" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test ID Daniel",
        isActive: false,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "1238cfdb-02c3-422f-9df1-66924b24456a",
            "b628c642-4ff7-4c64-bd5d-7b8fd8ad25f8",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-07-22T12:55:00.729302135Z",
        updatedAt: "2024-07-24T17:07:25.541384139Z",
      },
      {
        id: "9d7ff57f-fffc-45a0-81fc-0d2c25acc2c7",
        embedCode:
          '<pep-loyalty widget-id="9d7ff57f-fffc-45a0-81fc-0d2c25acc2c7" type="sweepstakes"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "sweepstakes",
        title: "Demo321",
        isActive: false,
        widgetData: {
          warningColor: "#db1d2c",
          selectedCampaigns: [
            "946f23b7-6054-4a49-8f3b-98682be9fb2f",
            "fc62799b-3759-4cc1-ae93-122526a33a94",
            "1238cfdb-02c3-422f-9df1-66924b24456a",
            "fe9c85a9-7d7c-4284-ae03-6af47ade3490",
            "c7e3166c-07e7-4418-9170-d8568e1f453c",
          ],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          maxHeight: 256,
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 5,
        createdAt: "2024-07-25T07:17:10.660237977Z",
        updatedAt: "2024-07-25T07:36:42.620669263Z",
      },
      {
        id: "64edfb22-1c9c-4450-9403-c9872b5a995c",
        embedCode:
          '<pep-loyalty widget-id="64edfb22-1c9c-4450-9403-c9872b5a995c" type="campaign"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "Demo32111",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "c76f1a2f-f889-42d5-950e-dec9d94288b6",
            "2c18d034-c3a7-4275-b48e-de5d6d667845",
            "fccab21a-df97-4688-8b2b-bf15a0c28db0",
          ],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 4,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.google.com",
                text: "Spanish",
              },
              "en-us": {
                url: "www.google.com",
                text: "English",
              },
            },
          },
          maxHeight: 256,
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-25T07:35:38.429424895Z",
        updatedAt: "2024-07-25T07:36:50.940255315Z",
      },
      {
        id: "2653d981-ff78-4fff-8b86-c3acb8e9d53f",
        embedCode:
          '<pep-loyalty widget-id="2653d981-ff78-4fff-8b86-c3acb8e9d53f" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "test 1",
        isActive: true,
        widgetData: {
          periodStartDate: "2024-07-02",
          showWelcomeMessage: false,
          showMemberBalance: false,
          customWidget: false,
          showNumberCompletedActivities: false,
          showMemberSignupDate: true,
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-19T21:30:49.137858125Z",
        updatedAt: "2024-07-25T11:10:08.636799490Z",
      },
      {
        id: "de9f01af-df44-493d-872f-b4ea5e7e6f97",
        embedCode:
          '<pep-loyalty widget-id="de9f01af-df44-493d-872f-b4ea5e7e6f97" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Information DatePicker 2",
        isActive: true,
        widgetData: {
          periodStartDate: "2024-07-04",
          showIcons: true,
          textMainColor: "#2F60A0",
          showWelcomeMessage: false,
          showMemberBalance: false,
          iconMainColor: "#2F60A0",
          iconHighlightColor: "#26dd63",
          customWidget: true,
          showNumberCompletedActivities: false,
          showMemberSignupDate: true,
          textHighlightColor: "#DD2634",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-01T15:34:54.527922990Z",
        updatedAt: "2024-07-25T17:10:17.769705739Z",
      },
      {
        id: "08aad342-96c1-4d0e-b252-2f52f91d98ae",
        embedCode:
          '<pep-loyalty widget-id="08aad342-96c1-4d0e-b252-2f52f91d98ae" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test Brands",
        isActive: false,
        widgetData: {
          format: "GRID",
          selectedCampaigns: ["e779c49a-ced0-423c-a3ce-45a2382f3236"],
          customWidget: false,
          visibleNonMembers: false,
          brandId: "",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-07-26T17:35:32.952969340Z",
        updatedAt: "2024-07-26T19:29:51.869876821Z",
      },
      {
        id: "3300c807-676a-4a11-a430-ec7e29d6d46c",
        embedCode:
          '<pep-loyalty widget-id="3300c807-676a-4a11-a430-ec7e29d6d46c" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "PRE-PROD Rewards List",
        isActive: true,
        widgetData: {
          warningColor: "#db1d2c",
          selectedCampaigns: [
            "713a7a7d-b6ad-4f30-a8ed-195f123cc3e9",
            "efa5eb0e-0560-48f8-87e0-ed7e9b1cc6b1",
            "fc62799b-3759-4cc1-ae93-122526a33a94",
          ],
          buttonColor: "#31d1b2",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#707f0c",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0b7125",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.tastyrewards.com/signup",
                text: "Inscribirse",
              },
              "en-us": {
                url: "www.tastyrewards.com/signup",
                text: "Sign Up",
              },
            },
          },
          maxHeight: 256,
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#d60d6c",
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-29T18:44:15.544856197Z",
        updatedAt: "2024-07-29T18:45:32.210313064Z",
      },
      {
        id: "f4271fe7-5eb8-415c-9c88-d031fb13cbc9",
        embedCode:
          '<pep-loyalty widget-id="f4271fe7-5eb8-415c-9c88-d031fb13cbc9" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test cdp",
        isActive: true,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "bb157777-02b9-49a8-818d-886f6248a67a",
            "454c5242-abc5-4fb6-971d-11fd5a752b7e",
            "e9c49af1-f93e-423f-a278-a63d246da487",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-29T18:48:35.885169294Z",
        updatedAt: "2024-07-29T18:48:45.808966214Z",
      },
      {
        id: "21456621-acb3-4bd4-9346-43e95f093b50",
        embedCode:
          '<pep-loyalty widget-id="21456621-acb3-4bd4-9346-43e95f093b50" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test cdp 2 active",
        isActive: true,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "454c5242-abc5-4fb6-971d-11fd5a752b7e",
            "e9c49af1-f93e-423f-a278-a63d246da487",
            "efa5eb0e-0560-48f8-87e0-ed7e9b1cc6b1",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-29T18:50:04.959941254Z",
        updatedAt: "2024-07-29T18:50:04.959941254Z",
      },
      {
        id: "86aa2f1e-6ca7-4406-8715-a3e84beab30b",
        embedCode:
          '<pep-loyalty widget-id="86aa2f1e-6ca7-4406-8715-a3e84beab30b" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "PRE-PROD Activity List - Recipe",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "44436438-46d8-4bc2-85c8-9d6e1e4d7973",
            "c28c4503-2ea7-4df5-b59b-8f35d0e67e59",
            "2a9f422b-9e87-4454-9037-7a8ffd701fe8",
            "43658b6f-51ca-4edb-94e1-dbecba010ddd",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 4,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.tastyrewards.com/signup",
                text: "Inscribirse",
              },
              "en-us": {
                url: "www.signup.com",
                text: "Sign up",
              },
            },
          },
          maxHeight: 256,
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-07-29T18:51:25.874256528Z",
        updatedAt: "2024-07-29T18:51:25.874256528Z",
      },
      {
        id: "cfd9b0ff-1da3-4ebf-baf2-1110a2075f65",
        embedCode:
          '<pep-loyalty widget-id="cfd9b0ff-1da3-4ebf-baf2-1110a2075f65" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "PRE-PROD All Activity List",
        isActive: true,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "ebd991f6-67b0-45d4-b144-f111ffbb453c",
            "0ea4b453-b8c6-4843-b96d-4f479120d34f",
            "2dbe09f0-fd97-4618-a92b-3d6785a03f8a",
            "0e44ede5-463b-483d-8d92-cdcbf5f185fe",
            "8fa248af-bc9a-4f92-8500-339194f4aa6d",
            "bb157777-02b9-49a8-818d-886f6248a67a",
            "e779c49a-ced0-423c-a3ce-45a2382f3236",
          ],
          customWidget: false,
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.tastyrewards.com/signup",
                text: "Inscribirse",
              },
              "en-us": {
                url: "www.tastyrewards.com/signup",
                text: "Sign up",
              },
            },
          },
          visibleNonMembers: true,
        },
        selectedCampaignsSize: 7,
        createdAt: "2024-07-29T18:55:14.971999519Z",
        updatedAt: "2024-07-29T18:55:14.971999519Z",
      },
      {
        id: "1f26fc9f-1fba-4593-9cbf-f48a70fa3e00",
        embedCode:
          '<pep-loyalty widget-id="1f26fc9f-1fba-4593-9cbf-f48a70fa3e00" type="campaign"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "PRE-PROD Featured Activity",
        isActive: true,
        widgetData: {
          format: "CAROUSEL",
          selectedCampaigns: [
            "efa5eb0e-0560-48f8-87e0-ed7e9b1cc6b1",
            "713a7a7d-b6ad-4f30-a8ed-195f123cc3e9",
            "ebd991f6-67b0-45d4-b144-f111ffbb453c",
            "4b527064-48b1-4751-ac6b-62066dd02257",
          ],
          customWidget: false,
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.tastyrewards.com/signup",
                text: "Inscribirse",
              },
              "en-us": {
                url: "www.signup.com",
                text: "Sign up",
              },
            },
          },
          visibleNonMembers: true,
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-07-29T19:00:23.871055753Z",
        updatedAt: "2024-07-29T19:00:42.730940849Z",
      },
      {
        id: "da21513f-8e43-4e51-b3c5-c82b45b48de5",
        embedCode:
          '<pep-loyalty widget-id="da21513f-8e43-4e51-b3c5-c82b45b48de5" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "PROD Activity History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          pointsTextColor: "#0057A2",
          historyType: "ACTIVITY_HISTORY",
          maxHeight: 400,
          rowTextColor: "#000000",
          customWidget: true,
          headerRowColor: "#F8F8F8",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-29T19:03:26.788528778Z",
        updatedAt: "2024-07-29T19:03:26.788528778Z",
      },
      {
        id: "ec9cafb2-0304-423e-9abb-884f0ff5f77d",
        embedCode:
          '<pep-loyalty widget-id="ec9cafb2-0304-423e-9abb-884f0ff5f77d" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "PROD Member Information",
        isActive: true,
        widgetData: {
          textMainColor: "#0e4caa",
          iconMainColor: "#0e4caa",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-29T19:07:50.316077127Z",
        updatedAt: "2024-07-29T19:07:50.316077127Z",
      },
      {
        id: "9fe4ff40-8cc3-43e9-920b-2c750e04ab09",
        embedCode:
          '<pep-loyalty widget-id="9fe4ff40-8cc3-43e9-920b-2c750e04ab09" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "PROD Member Information - Copy",
        isActive: true,
        widgetData: {
          textMainColor: "#0e4caa",
          iconMainColor: "#0e4caa",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-30T13:57:04.474384733Z",
        updatedAt: "2024-07-30T13:57:04.474384733Z",
      },
      {
        id: "a8e5eb1f-561d-47a2-b391-fffafa687e1f",
        embedCode:
          '<pep-loyalty widget-id="a8e5eb1f-561d-47a2-b391-fffafa687e1f" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "PROD Member Information - Copy - Copy",
        isActive: true,
        widgetData: {
          periodStartDate: "2024-07-25",
          textMainColor: "#0e4caa",
          iconMainColor: "#0e4caa",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-30T17:48:04.122264664Z",
        updatedAt: "2024-07-30T17:48:04.122264664Z",
      },
      {
        id: "aa5ffd62-378e-421b-b31e-c733a47effd4",
        embedCode:
          '<pep-loyalty widget-id="aa5ffd62-378e-421b-b31e-c733a47effd4" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test CDP Member Information - Copy",
        isActive: true,
        widgetData: {
          periodStartDate: "2024-07-25",
          textMainColor: "#0e4caa",
          iconMainColor: "#0e4caa",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-30T18:11:51.408422609Z",
        updatedAt: "2024-07-30T18:11:51.408422609Z",
      },
      {
        id: "40abcfe5-7b4d-4be2-b894-596a52b37648",
        embedCode:
          '<pep-loyalty widget-id="40abcfe5-7b4d-4be2-b894-596a52b37648" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test New Brand Field Daniel",
        isActive: false,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "bb157777-02b9-49a8-818d-886f6248a67a",
            "3a0d3836-7347-42b2-9084-5e78dc021827",
            "2dadacd4-9812-44d9-89e0-bd176d06656d",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-29T19:26:38.494921280Z",
        updatedAt: "2024-07-30T19:35:13.735596556Z",
      },
      {
        id: "6f77b124-a181-43b4-b1e1-2017ce93ddea",
        embedCode:
          '<pep-loyalty widget-id="6f77b124-a181-43b4-b1e1-2017ce93ddea" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test cdp - Copy",
        isActive: true,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "bb157777-02b9-49a8-818d-886f6248a67a",
            "e9c49af1-f93e-423f-a278-a63d246da487",
            "3a0d3836-7347-42b2-9084-5e78dc021827",
            "934034af-a319-481a-84d0-a5a7d69d7aed",
            "2dadacd4-9812-44d9-89e0-bd176d06656d",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 5,
        createdAt: "2024-07-30T19:40:40.039414961Z",
        updatedAt: "2024-07-30T19:40:40.039414961Z",
      },
      {
        id: "17ed92d6-a0eb-4486-b027-7f410462e170",
        embedCode:
          '<pep-loyalty widget-id="17ed92d6-a0eb-4486-b027-7f410462e170" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "List of sweepstakes cdp test",
        isActive: true,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "b9628803-feaf-4539-ae39-07cc44066e3c",
            "2dadacd4-9812-44d9-89e0-bd176d06656d",
            "9f110503-db6d-4c47-a5fd-8d35be9815e9",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-30T19:43:07.921436860Z",
        updatedAt: "2024-07-30T19:43:07.921436860Z",
      },
      {
        id: "8d16d358-39be-4032-ba0b-b6daa2851fd7",
        embedCode:
          '<pep-loyalty widget-id="8d16d358-39be-4032-ba0b-b6daa2851fd7" type="campaign"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "List of sweepstakes cdp test no Copy",
        isActive: true,
        widgetData: {
          format: "GRID",
          selectedCampaigns: [
            "b9628803-feaf-4539-ae39-07cc44066e3c",
            "2dadacd4-9812-44d9-89e0-bd176d06656d",
            "9f110503-db6d-4c47-a5fd-8d35be9815e9",
          ],
          customWidget: false,
          visibleNonMembers: false,
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-07-30T19:54:49.814796004Z",
        updatedAt: "2024-07-30T19:54:49.814796004Z",
      },
      {
        id: "f7f06280-5183-4dcb-9ed8-72d91a9b827e",
        embedCode:
          '<pep-loyalty widget-id="f7f06280-5183-4dcb-9ed8-72d91a9b827e" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test Sweeps Brand",
        isActive: true,
        widgetData: {
          format: "GRID",
          selectedCampaigns: ["3a0d3836-7347-42b2-9084-5e78dc021827"],
          customWidget: false,
          visibleNonMembers: false,
          brandId: "1cb552dc-1d5c-4eb6-8bea-0583d44e1356",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-07-30T18:30:30.486208214Z",
        updatedAt: "2024-07-31T10:46:13.173633244Z",
      },
      {
        id: "9d788566-0dc8-4a24-848f-842b77c49cf8",
        embedCode:
          '<pep-loyalty widget-id="9d788566-0dc8-4a24-848f-842b77c49cf8" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "PROD Sweepstakes History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-29T19:03:59.744178351Z",
        updatedAt: "2024-07-31T12:21:11.344952450Z",
      },
      {
        id: "a5831ab6-f827-40d2-a1de-07f50647bf07",
        embedCode:
          '<pep-loyalty widget-id="a5831ab6-f827-40d2-a1de-07f50647bf07" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "PROD Member Information - Copy",
        isActive: true,
        widgetData: {
          periodStartDate: "2024-07-25",
          textMainColor: "#0e4caa",
          iconMainColor: "#0e4caa",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-30T14:00:43.436747979Z",
        updatedAt: "2024-07-31T12:30:50.869011329Z",
      },
      {
        id: "5e32448d-6f09-4754-845c-21f8418a2140",
        embedCode:
          '<pep-loyalty widget-id="5e32448d-6f09-4754-845c-21f8418a2140" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Joe - Member Info - 1",
        isActive: true,
        widgetData: {
          showWelcomeMessage: true,
          showMemberBalance: true,
          customWidget: false,
          showNumberCompletedActivities: true,
          showMemberSignupDate: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showDateBalanceUpdated: true,
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-07-31T20:44:21.903700427Z",
        updatedAt: "2024-07-31T20:44:21.903700427Z",
      },
      {
        id: "79a76ca6-24c5-4ea2-b075-261cc456f0ae",
        embedCode:
          '<pep-loyalty widget-id="79a76ca6-24c5-4ea2-b075-261cc456f0ae" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Joe Test History 8-6 1",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-06T22:30:51.700247758Z",
        updatedAt: "2024-08-06T22:30:51.700247758Z",
      },
      {
        id: "30d7ec0a-fc90-42eb-93d5-04b820c6f322",
        embedCode:
          '<pep-loyalty widget-id="30d7ec0a-fc90-42eb-93d5-04b820c6f322" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Joe Test History 8-6 1",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-06T22:30:53.362680854Z",
        updatedAt: "2024-08-06T22:30:53.362680854Z",
      },
      {
        id: "42400126-7ed9-419b-acaa-8baa000e0bb8",
        embedCode:
          '<pep-loyalty widget-id="42400126-7ed9-419b-acaa-8baa000e0bb8" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Joe Test 8-6 _ 1",
        isActive: true,
        widgetData: {
          showIcons: false,
          textMainColor: "#0E4CAA",
          showWelcomeMessage: true,
          showMemberBalance: true,
          customWidget: false,
          showNumberCompletedActivities: true,
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          activitiesPeriod: "ALL_ACTIVITIES",
          showDateBalanceUpdated: true,
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-06T23:55:17.401055610Z",
        updatedAt: "2024-08-06T23:55:17.401055610Z",
      },
      {
        id: "96b761d2-fbb9-4215-bd2f-471811ee6fba",
        embedCode:
          '<pep-loyalty widget-id="96b761d2-fbb9-4215-bd2f-471811ee6fba" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test to Joe",
        isActive: false,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-07T11:06:36.821144675Z",
        updatedAt: "2024-08-08T11:23:18.975926774Z",
      },
      {
        id: "fa3d1647-9b39-4161-84f0-74665ead052e",
        embedCode:
          '<pep-loyalty widget-id="fa3d1647-9b39-4161-84f0-74665ead052e" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test Sweeps for Joe",
        isActive: false,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["21cd949d-416a-4d89-a522-2e20d120277c"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: [
            {
              displayText: "",
              id: "",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-08T11:23:36.220187693Z",
        updatedAt: "2024-08-08T11:23:36.220187693Z",
      },
      {
        id: "994ffdf8-c445-4895-bd1e-1d8fced7b046",
        embedCode:
          '<pep-loyalty widget-id="994ffdf8-c445-4895-bd1e-1d8fced7b046" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Demo123",
        isActive: true,
        widgetData: {
          warningColor: "#db1d2c",
          selectedCampaigns: [
            "fc62799b-3759-4cc1-ae93-122526a33a94",
            "fe9c85a9-7d7c-4284-ae03-6af47ade3490",
            "b628c642-4ff7-4c64-bd5d-7b8fd8ad25f8",
            "946f23b7-6054-4a49-8f3b-98682be9fb2f",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 4,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.google.com",
                text: "Spanish",
              },
              "en-us": {
                url: "www.google.com",
                text: "English",
              },
            },
          },
          maxHeight: 256,
          brandId: [
            {
              displayText: "",
              id: "",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: true,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-07-25T07:14:37.186439954Z",
        updatedAt: "2024-08-08T15:22:37.498509589Z",
      },
      {
        id: "9f2f6049-4b06-4948-a186-dc2024c51fa1",
        embedCode:
          '<pep-loyalty widget-id="9f2f6049-4b06-4948-a186-dc2024c51fa1" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Joe Test 8-8 _ 1",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-08T18:31:18.164676328Z",
        updatedAt: "2024-08-08T18:31:23.043753102Z",
      },
      {
        id: "a5710f07-5803-45c2-bb92-cf9106d94fa7",
        embedCode:
          '<pep-loyalty widget-id="a5710f07-5803-45c2-bb92-cf9106d94fa7" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Joe Test 8-8 _ 1",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-08T18:39:34.655105095Z",
        updatedAt: "2024-08-08T18:39:34.655105095Z",
      },
      {
        id: "7d129c4c-e5e2-4f5b-8cad-fb46969adced",
        embedCode:
          '<pep-loyalty widget-id="7d129c4c-e5e2-4f5b-8cad-fb46969adced" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Member Information - Copy",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T05:26:53.870589820Z",
        updatedAt: "2024-08-09T05:26:53.870589820Z",
      },
      {
        id: "bb95f4e0-14c9-4c28-a14c-68fa49f4ac36",
        embedCode:
          '<pep-loyalty widget-id="bb95f4e0-14c9-4c28-a14c-68fa49f4ac36" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Member Information - Copy - Copy",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T05:27:15.144463797Z",
        updatedAt: "2024-08-09T05:27:15.144463797Z",
      },
      {
        id: "33eec57a-989c-464a-9474-349969c32202",
        embedCode:
          '<pep-loyalty widget-id="33eec57a-989c-464a-9474-349969c32202" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Member Information - Copy",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T05:28:16.767942716Z",
        updatedAt: "2024-08-09T05:28:16.767942716Z",
      },
      {
        id: "858da924-0aa6-4249-a318-c067b1965ccd",
        embedCode:
          '<pep-loyalty widget-id="858da924-0aa6-4249-a318-c067b1965ccd" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Demo 111",
        isActive: true,
        widgetData: {
          textMainColor: "#aaa50e",
          iconMainColor: "#1f1f20",
          showMemberSignupDate: true,
          textHighlightColor: "#7d7eb5",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#120104",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T05:45:26.540971436Z",
        updatedAt: "2024-08-09T05:45:26.540971436Z",
      },
      {
        id: "67da2a45-0e6e-4344-8cef-da7b7698adf7",
        embedCode:
          '<pep-loyalty widget-id="67da2a45-0e6e-4344-8cef-da7b7698adf7" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Demo 111 - Copy",
        isActive: true,
        widgetData: {
          showIcons: true,
          textMainColor: "#aaa50e",
          showWelcomeMessage: true,
          showMemberBalance: false,
          iconMainColor: "#1f1f20",
          iconHighlightColor: "#120104",
          customWidget: true,
          showNumberCompletedActivities: true,
          showMemberSignupDate: true,
          textHighlightColor: "#7d7eb5",
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T05:47:23.300606114Z",
        updatedAt: "2024-08-09T05:47:23.300606114Z",
      },
      {
        id: "0b757eae-b08c-45ac-a41e-6ce189e8842d",
        embedCode:
          '<pep-loyalty widget-id="0b757eae-b08c-45ac-a41e-6ce189e8842d" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Demo 111 - Copy - Copy",
        isActive: false,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: false,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: true,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T05:55:08.343004613Z",
        updatedAt: "2024-08-09T05:55:16.578392151Z",
      },
      {
        id: "e1544dc4-4e4c-4edb-8365-2213bd6980d4",
        embedCode:
          '<pep-loyalty widget-id="e1544dc4-4e4c-4edb-8365-2213bd6980d4" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test111",
        isActive: true,
        widgetData: {
          headerTextColor: "#e5e7ea",
          pointsTextColor: "#e59f12",
          historyType: "SWEEPSTAKES_HISTORY",
          maxHeight: 400,
          rowTextColor: "#031270",
          customWidget: true,
          headerRowColor: "#e32222",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T05:59:59.405441877Z",
        updatedAt: "2024-08-09T05:59:59.405441877Z",
      },
      {
        id: "e0ae4c5f-3464-4e8f-86ae-e85824247c2b",
        embedCode:
          '<pep-loyalty widget-id="e0ae4c5f-3464-4e8f-86ae-e85824247c2b" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test111",
        isActive: true,
        widgetData: {
          headerTextColor: "#e5e7ea",
          pointsTextColor: "#e59f12",
          historyType: "SWEEPSTAKES_HISTORY",
          maxHeight: 400,
          rowTextColor: "#031270",
          customWidget: true,
          headerRowColor: "#e32222",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T05:59:59.687885302Z",
        updatedAt: "2024-08-09T05:59:59.687885302Z",
      },
      {
        id: "0501a97f-dea8-4607-8356-0f1ee67a0f4e",
        embedCode:
          '<pep-loyalty widget-id="0501a97f-dea8-4607-8356-0f1ee67a0f4e" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Demo 111",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T06:05:37.137753965Z",
        updatedAt: "2024-08-09T06:05:37.137753965Z",
      },
      {
        id: "a34237c1-9fd9-437d-a507-fdc50186c6f8",
        embedCode:
          '<pep-loyalty widget-id="a34237c1-9fd9-437d-a507-fdc50186c6f8" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test Demo 111",
        isActive: true,
        widgetData: {
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "ALL_ACTIVITIES",
          showNumberActivitiesToComplete: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T06:06:00.924971889Z",
        updatedAt: "2024-08-09T06:06:00.924971889Z",
      },
      {
        id: "ffe8476e-dfde-4ce4-b973-4626242129bd",
        embedCode:
          '<pep-loyalty widget-id="ffe8476e-dfde-4ce4-b973-4626242129bd" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test Activity History",
        isActive: true,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "ACTIVITY_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T06:49:06.665861689Z",
        updatedAt: "2024-08-09T06:49:06.665861689Z",
      },
      {
        id: "35a33f9b-dd4a-4b08-a0f5-8c99e8fb79af",
        embedCode:
          '<pep-loyalty widget-id="35a33f9b-dd4a-4b08-a0f5-8c99e8fb79af" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test222",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "114bb7be-7a21-4f86-b858-19cd1127aed3",
            "385cabd9-6223-467f-8484-b50f5721f169",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "83230e69-3f6c-4910-9e7a-1f6d8ded61bb",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-08-09T07:01:34.024871630Z",
        updatedAt: "2024-08-09T07:01:34.024871630Z",
      },
      {
        id: "04cdc9e3-ae14-4dfb-a90d-eecdaf8d9682",
        embedCode:
          '<pep-loyalty widget-id="04cdc9e3-ae14-4dfb-a90d-eecdaf8d9682" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Checking Edit Func",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["3da24c0e-d0fe-48c7-a626-aa84c0f82a18"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "cbad3356-78c1-4450-80b8-d113a74c08dc",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T07:27:01.160947806Z",
        updatedAt: "2024-08-09T07:27:01.160947806Z",
      },
      {
        id: "0c88f793-34a1-47c1-8a36-857cd73e9cf7",
        embedCode:
          '<pep-loyalty widget-id="0c88f793-34a1-47c1-8a36-857cd73e9cf7" type="sweepstakes"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test9876",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["73ca125d-12ca-4798-8dca-a4c89c007c98"],
          buttonColor: "#DB1D2C",
          format: "CAROUSEL",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.google.com",
                text: "Spanish",
              },
              "en-us": {
                url: "www.google.com",
                text: "English",
              },
            },
          },
          brandId: "22232dfa-b32a-4124-a273-0b8d0445225d",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T07:32:59.815780908Z",
        updatedAt: "2024-08-09T07:32:59.815780908Z",
      },
      {
        id: "d9b096ce-f08e-4c85-9e77-4ba88a0cf282",
        embedCode:
          '<pep-loyalty widget-id="d9b096ce-f08e-4c85-9e77-4ba88a0cf282" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test9876 - Copy",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "73ca125d-12ca-4798-8dca-a4c89c007c98",
            "0257ce0f-2b67-412f-abbb-7e8d7f391c17",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.google.com",
                text: "Spanish",
              },
              "en-us": {
                url: "www.google.com",
                text: "English",
              },
            },
          },
          brandId: [
            {
              displayText: "Cap'n crunch",
              id: "cbad3356-78c1-4450-80b8-d113a74c08dc",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 2,
        createdAt: "2024-08-09T07:35:17.460235541Z",
        updatedAt: "2024-08-09T07:36:04.336980917Z",
      },
      {
        id: "9b136fb8-3592-476c-b868-2f9262868369",
        embedCode:
          '<pep-loyalty widget-id="9b136fb8-3592-476c-b868-2f9262868369" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test9876 - Copy - Copy",
        isActive: false,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["3da24c0e-d0fe-48c7-a626-aa84c0f82a18"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.google.com",
                text: "Spanish",
              },
              "en-us": {
                url: "www.google.com",
                text: "English",
              },
            },
          },
          brandId: [
            {
              displayText: "",
              id: "",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T07:44:32.730401183Z",
        updatedAt: "2024-08-09T07:44:32.730401183Z",
      },
      {
        id: "e126fd7b-dc62-439c-8a10-22ce6cf6c16b",
        embedCode:
          '<pep-loyalty widget-id="e126fd7b-dc62-439c-8a10-22ce6cf6c16b" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "TestWDSInactive",
        isActive: false,
        widgetData: {
          selectedCampaigns: ["42f56ac3-bb11-4579-a560-b8d2e2e813e1"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "83230e69-3f6c-4910-9e7a-1f6d8ded61bb",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T12:57:15.464004591Z",
        updatedAt: "2024-08-09T12:57:15.464004591Z",
      },
      {
        id: "40ecdf4e-d7d4-4f2e-9e27-501a24be7b1c",
        embedCode:
          '<pep-loyalty widget-id="40ecdf4e-d7d4-4f2e-9e27-501a24be7b1c" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "TestWDSEdit",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["95377e51-b8b5-4a62-9213-6e3d603cade2"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: [
            {
              displayText: "Cheetos",
              id: "1cb552dc-1d5c-4eb6-8bea-0583d44e1356",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T12:56:41.989984406Z",
        updatedAt: "2024-08-09T13:07:58.985651245Z",
      },
      {
        id: "deb2c312-e67c-4e10-96d3-33d0d07d009b",
        embedCode:
          '<pep-loyalty widget-id="deb2c312-e67c-4e10-96d3-33d0d07d009b" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "TestWDSCopy",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["95377e51-b8b5-4a62-9213-6e3d603cade2"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: [
            {
              displayText: "Cheetos",
              id: "1cb552dc-1d5c-4eb6-8bea-0583d44e1356",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T13:08:22.614108213Z",
        updatedAt: "2024-08-09T13:08:22.614108213Z",
      },
      {
        id: "dc41c9ae-eded-4585-837c-ede432a063e1",
        embedCode:
          '<pep-loyalty widget-id="dc41c9ae-eded-4585-837c-ede432a063e1" type="member-history"></pep-loyalty>',
        displayInfo: "ACTIVITY_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test Daniel",
        isActive: false,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "ACTIVITY_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T14:38:39.574326500Z",
        updatedAt: "2024-08-09T14:38:39.574326500Z",
      },
      {
        id: "291e1ee6-facc-4275-9210-ae4127440654",
        embedCode:
          '<pep-loyalty widget-id="291e1ee6-facc-4275-9210-ae4127440654" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "MN Test Widget 001",
        isActive: false,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["f846ebe2-2cfc-4100-a9b8-ed4832a229b1"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "javascript:alert('test');",
                text: "ES",
              },
              "en-us": {
                url: "javascript:alert('test');",
                text: "ENG",
              },
            },
          },
          brandId: [
            {
              displayText: "Aquafina",
              id: "20977a11-fab6-4ec9-b85d-ca4a568c6137",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T16:24:06.835990891Z",
        updatedAt: "2024-08-09T16:45:25.680979032Z",
      },
      {
        id: "bba31c8d-e7bc-4fd9-83d4-a5366e96f110",
        embedCode:
          '<pep-loyalty widget-id="bba31c8d-e7bc-4fd9-83d4-a5366e96f110" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test date",
        isActive: false,
        widgetData: {
          periodStartDate: "2024-08-08",
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showIcons: true,
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T17:28:40.716548940Z",
        updatedAt: "2024-08-09T17:28:40.716548940Z",
      },
      {
        id: "ddcf2fee-1178-4acc-8f76-d7dc6dc42ebb",
        embedCode:
          '<pep-loyalty widget-id="ddcf2fee-1178-4acc-8f76-d7dc6dc42ebb" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Test date",
        isActive: false,
        widgetData: {
          showIcons: true,
          textMainColor: "#0E4CAA",
          showWelcomeMessage: true,
          showMemberBalance: true,
          iconMainColor: "#0E4CAA",
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: false,
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showDateBalanceUpdated: true,
          showNumberActivitiesToComplete: true,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-09T17:31:17.049465259Z",
        updatedAt: "2024-08-09T17:36:36.581543879Z",
      },
      {
        id: "999bc312-e434-427b-842f-888ce8cff5a0",
        embedCode:
          '<pep-loyalty widget-id="999bc312-e434-427b-842f-888ce8cff5a0" type="member-history"></pep-loyalty>',
        displayInfo: "SWEEPSTAKES_HISTORY",
        widgetDefinitionSlug: "member-history",
        title: "Test 9876",
        isActive: false,
        widgetData: {
          headerTextColor: "#0057A2",
          rowTextColor: "#000000",
          customWidget: false,
          headerRowColor: "#F8F8F8",
          pointsTextColor: "#0057A2",
          historyType: "SWEEPSTAKES_HISTORY",
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-12T07:16:41.988308673Z",
        updatedAt: "2024-08-12T07:16:52.770467849Z",
      },
      {
        id: "0e8b9b51-e093-4671-a076-68c11009ccd0",
        embedCode:
          '<pep-loyalty widget-id="0e8b9b51-e093-4671-a076-68c11009ccd0" type="member-information"></pep-loyalty>',
        displayInfo: null,
        widgetDefinitionSlug: "member-information",
        title: "Testt",
        isActive: true,
        widgetData: {
          periodStartDate: "2024-08-31",
          textMainColor: "#0E4CAA",
          iconMainColor: "#0E4CAA",
          showMemberSignupDate: true,
          textHighlightColor: "#D50A30",
          showIcons: true,
          showWelcomeMessage: false,
          showMemberBalance: false,
          iconHighlightColor: "#D50A30",
          customWidget: false,
          showNumberCompletedActivities: true,
          activitiesPeriod: "FROM_SPECIFIC_DATE",
          showNumberActivitiesToComplete: false,
        },
        selectedCampaignsSize: null,
        createdAt: "2024-08-12T07:23:25.348070345Z",
        updatedAt: "2024-08-12T07:32:43.430215531Z",
      },
      {
        id: "c41483b0-4c12-40e1-afe3-8e25dd7d1ba2",
        embedCode:
          '<pep-loyalty widget-id="c41483b0-4c12-40e1-afe3-8e25dd7d1ba2" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Test",
        isActive: false,
        widgetData: {
          selectedCampaigns: [
            "1ba6c8eb-f7aa-4e5b-8558-75b5a037e698",
            "f3596da6-2f57-4195-b615-c2c8cb5352c2",
            "f56600cc-8dc1-4803-b35f-bd610e0468d6",
            "89ae4bb1-5ee6-4045-9c71-c8997146a06c",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "2e699b76-92f2-4d31-905d-3ef446791dfe",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-08-12T07:45:38.975437387Z",
        updatedAt: "2024-08-12T07:45:38.975437387Z",
      },
      {
        id: "b2fd7e2d-495d-4bda-9f1c-4a68480795d8",
        embedCode:
          '<pep-loyalty widget-id="b2fd7e2d-495d-4bda-9f1c-4a68480795d8" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "title",
        isActive: true,
        widgetData: {
          custom_widget: true,
          card_color: "#FFFFFF",
          selectedCampaigns: ["1", "2"],
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-12T17:49:38.403555137Z",
        updatedAt: "2024-08-12T17:49:38.403555137Z",
      },
      {
        id: "40e14254-4b04-4057-a17e-9b8e2335d1fd",
        embedCode:
          '<pep-loyalty widget-id="40e14254-4b04-4057-a17e-9b8e2335d1fd" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "Joe Test 8-9 _ 1",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "95377e51-b8b5-4a62-9213-6e3d603cade2",
            "42f56ac3-bb11-4579-a560-b8d2e2e813e1",
            "114bb7be-7a21-4f86-b858-19cd1127aed3",
            "1ba6c8eb-f7aa-4e5b-8558-75b5a037e698",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.google.com",
                text: "Spanish CTA",
              },
              "en-us": {
                url: "www.google.com",
                text: "English CTA",
              },
            },
          },
          brandId: [
            {
              displayText: "Cheetos",
              id: "1cb552dc-1d5c-4eb6-8bea-0583d44e1356",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-08-09T16:37:19.687212760Z",
        updatedAt: "2024-08-12T18:12:43.722959247Z",
      },
      {
        id: "f9cac96f-9bb3-4711-86ac-b8df383bc01e",
        embedCode:
          '<pep-loyalty widget-id="f9cac96f-9bb3-4711-86ac-b8df383bc01e" type="campaigns"></pep-loyalty>',
        displayInfo: "CAROUSEL",
        widgetDefinitionSlug: "campaigns",
        title: "title",
        isActive: true,
        widgetData: {
          custom_widget: true,
          card_color: "#FFFFFF",
          selectedCampaigns: ["1", "2"],
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-12T19:54:31.505140889Z",
        updatedAt: "2024-08-12T19:54:31.505140889Z",
      },
      {
        id: "cf54f289-4270-460e-92ff-4fbc8f5b79ef",
        embedCode:
          '<pep-loyalty widget-id="cf54f289-4270-460e-92ff-4fbc8f5b79ef" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "title",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["cf421e82-53ce-40f7-9e83-9a643b1c3ddb"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||alert('hello')",
                text: "Spanish",
              },
              "en-us": {
                url: "method||alert('hello')",
                text: "English",
              },
            },
          },
          brandId: [
            {
              displayText: "",
              id: "",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-12T19:09:17.433873604Z",
        updatedAt: "2024-08-13T18:01:33.537518665Z",
      },
      {
        id: "3d9854e4-a373-4d2a-9c3a-ba12e1d8ef36",
        embedCode:
          '<pep-loyalty widget-id="3d9854e4-a373-4d2a-9c3a-ba12e1d8ef36" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "MN Test 002",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["dc141ebb-92af-46b6-af62-57acd564c0a3"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "method||alert('Open Modal')",
                text: "ES",
              },
              "en-us": {
                url: "method||alert('Open Modal')",
                text: "ENG",
              },
            },
          },
          brandId: [
            {
              displayText: "",
              id: "",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-09T16:46:37.557071541Z",
        updatedAt: "2024-08-13T18:36:00.545420015Z",
      },
      {
        id: "4108c008-5b0e-4cfe-9dbf-e7c67fb1e365",
        embedCode:
          '<pep-loyalty widget-id="4108c008-5b0e-4cfe-9dbf-e7c67fb1e365" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Joe Test 8-9 _ 1",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "f846ebe2-2cfc-4100-a9b8-ed4832a229b1",
            "89ae4bb1-5ee6-4045-9c71-c8997146a06c",
            "f1476fcf-e2bd-49d8-b138-b9174c6e2a6d",
            "73ca125d-12ca-4798-8dca-a4c89c007c98",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: true,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          unauthenticated: {
            cta: {
              "es-mx": {
                url: "www.google.com",
                text: "CTA Spanish",
              },
              "en-us": {
                url: "www.google.com",
                text: "CTA English",
              },
            },
          },
          brandId: [
            {
              displayText: "",
              id: "",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-08-09T16:12:47.297282335Z",
        updatedAt: "2024-08-13T19:31:48.965265945Z",
      },
      {
        id: "4b534bda-bf62-4252-b713-c18a42e2f48f",
        embedCode:
          '<pep-loyalty widget-id="4b534bda-bf62-4252-b713-c18a42e2f48f" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "Test Demo123",
        isActive: true,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: [
            "dc86c2b5-c74b-48a9-bbd2-dd6a3381c806",
            "2ad376a9-eaa3-4e1d-a6be-066430c23968",
            "bbab86cb-ead2-48e3-a95c-391ba5aa75b6",
            "7b277151-21f1-4c02-b512-0a0387639a89",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "20977a11-fab6-4ec9-b85d-ca4a568c6137",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 4,
        createdAt: "2024-08-16T11:20:49.654232505Z",
        updatedAt: "2024-08-16T11:20:49.654232505Z",
      },
      {
        id: "a7f39185-44cb-41ea-ba0b-c8c25ca3ddd7",
        embedCode:
          '<pep-loyalty widget-id="a7f39185-44cb-41ea-ba0b-c8c25ca3ddd7" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "title - Copy",
        isActive: true,
        widgetData: {
          selectedCampaigns: ["177cae62-5795-4c2c-9fbd-2f9b9a6df087"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "20977a11-fab6-4ec9-b85d-ca4a568c6137",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-16T18:32:10.756698552Z",
        updatedAt: "2024-08-16T18:32:10.756698552Z",
      },
      {
        id: "dda6ce82-911b-45b7-81d1-e14f9a53ffbb",
        embedCode:
          '<pep-loyalty widget-id="dda6ce82-911b-45b7-81d1-e14f9a53ffbb" type="sweepstakes"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "sweepstakes",
        title: "TestWDS",
        isActive: false,
        widgetData: {
          warningColor: "#DB1D2C",
          selectedCampaigns: ["c28189d0-798b-40e6-9993-b1e267a91c81"],
          buttonColor: "#DB1D2C",
          format: "GRID",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#B8B8B8",
          buttonHoverColor: "#DB1D2C",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: [
            {
              displayText: "Doritos",
              id: "17776ecf-62ea-41d4-9228-c8bb9809e231",
            },
          ],
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0E4CAA",
        },
        selectedCampaignsSize: 1,
        createdAt: "2024-08-15T11:23:19.112481583Z",
        updatedAt: "2024-08-19T06:10:08.502130260Z",
      },
      {
        id: "ca6e3497-0807-4add-9b20-c8ec281aebcc",
        embedCode:
          '<pep-loyalty widget-id="ca6e3497-0807-4add-9b20-c8ec281aebcc" type="campaigns"></pep-loyalty>',
        displayInfo: "GRID",
        widgetDefinitionSlug: "campaigns",
        title: "title - Copy - Copy",
        isActive: true,
        widgetData: {
          selectedCampaigns: [
            "76e27266-713d-449e-9bc9-4306278018d8",
            "a072cfd4-f08a-4786-a6f6-c4f8f901c027",
            "48aeb564-3abb-4c6d-a145-4622c578887c",
          ],
          buttonColor: "#DB1D2C",
          format: "GRID",
          descriptionColor: "#4C4C4C",
          visibleNonMembers: false,
          buttonTextHoverColor: "#E1E1E1",
          textColor: "#616161",
          buttonHoverColor: "#DD2634",
          completedColor: "#00944a",
          itemsPerRow: 3,
          titleColor: "#0057A2",
          brandId: "7a5c4aca-c879-4456-9f6b-4fd6b453d330",
          cardBgColor: "#FFFFFF",
          buttonTextColor: "#FFFFFF",
          customWidget: false,
          comingSoonColor: "#0e4caa",
        },
        selectedCampaignsSize: 3,
        createdAt: "2024-08-16T19:19:32.974797854Z",
        updatedAt: "2024-08-22T19:15:26.249624927Z",
      },
    ],
    widgetDefinitions: [
      {
        id: "2d5c26fc-76cd-416d-ad83-82f21a6207d8",
        title: "List of campaigns",
        version: 1,
        slug: "campaigns",
        inputSchema: {
          title: "List of Campaigns",
          type: "object",
          properties: {
            selectedCampaigns: {
              type: "array",
            },
            buttonColor: {
              type: "string",
            },
            format: {
              enum: ["GRID", "CAROUSEL"],
            },
            descriptionColor: {
              type: "string",
            },
            visibleNonMembers: {
              type: "boolean",
            },
            buttonTextHoverColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
            buttonHoverColor: {
              type: "string",
            },
            completedColor: {
              type: "string",
            },
            itemsPerRow: {
              type: "number",
              enum: [3, 4],
            },
            titleColor: {
              type: "string",
            },
            unauthenticated: {
              type: "object",
              properties: {
                cta: {
                  type: "object",
                  properties: {
                    "es-mx": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL Spanish",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text Spanish",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                    "en-us": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL English",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text English",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                  },
                },
              },
            },
            maxHeight: {
              type: "number",
            },
            cardBgColor: {
              type: "string",
            },
            buttonTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            comingSoonColor: {
              type: "string",
            },
          },
          dependencies: {
            customWidget: {
              oneOf: [
                {
                  properties: {
                    customWidget: {
                      const: true,
                    },
                  },
                  required: ["maxHeight"],
                },
                {
                  properties: {
                    customWidget: {
                      const: false,
                    },
                  },
                },
              ],
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              tooltipDirection: "right",
              tooltipText:
                "Inform the button CTA and redirect link for non-authenticated experience",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      component: "toggle",
                      label: "Widget is visible by non members",
                      type: "Control",
                      scope: "#/properties/visibleNonMembers",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text English*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL English*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text Spanish*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL Spanish*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
              ],
              className: "setup-non-members-group",
              rules: [
                {
                  targets: [
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                  ],
                  condition: {
                    expectedValue: true,
                    scope: "#/properties/visibleNonMembers",
                  },
                  effect: "MAKE_REQUIRED",
                },
              ],
              label: "Button Setup for Non Members",
              tooltipIcon: "info",
              type: "SimpleGroup",
            },
            {
              className: "custom-preview-group",
              page: "Campaign",
              type: "simpleGroupPreviewCards",
              elements: [
                {
                  className: "horizontal-layout-3",
                  label: "Format",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          CAROUSEL: "Show in horizontal carousel",
                          GRID: "Show in a grid pattern",
                        },
                      },
                      component: "radioInput",
                      type: "Control",
                      scope: "#/properties/format",
                    },
                  ],
                },
                {
                  className: "horizontal-layout-4",
                  label: "Widget Card Styling",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Widget",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
                {
                  rule: {
                    condition: {
                      schema: {
                        const: false,
                      },
                      scope: "#/properties/customWidget",
                    },
                    effect: "HIDE",
                  },
                  className: "group-bg-gray",
                  label: "",
                  type: "SimpleGroup",
                  elements: [
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-5",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              3: "3 cards per row",
                              4: "4 cards per row",
                            },
                          },
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "radioInput",
                          type: "Control",
                          scope: "#/properties/itemsPerRow",
                        },
                        {
                          tooltipDirection: "right",
                          component: "numberInput",
                          tooltipText:
                            "Set a maximum height for this element to prevent it from growing beyond that limit.",
                          scope: "#/properties/maxHeight",
                          rule: {
                            condition: {
                              conditions: [
                                {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                {
                                  schema: {
                                    const: "GRID",
                                  },
                                  scope: "#/properties/format",
                                },
                              ],
                              type: "AND",
                            },
                            effect: "ENABLE",
                          },
                          label: "Max height",
                          placeholder: "Insert value in pixels",
                          tooltipIcon: "info",
                          type: "Control",
                          maxLength: 4,
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Card",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Card Background",
                          type: "Control",
                          scope: "#/properties/cardBgColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Campaign Title",
                          type: "Control",
                          scope: "#/properties/titleColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Coming Soon",
                          type: "Control",
                          scope: "#/properties/comingSoonColor",
                        },
                      ],
                    },
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-hexacolor",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Secondary Texts",
                          type: "Control",
                          scope: "#/properties/textColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Campaign Description",
                          type: "Control",
                          scope: "#/properties/descriptionColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Completed",
                          type: "Control",
                          scope: "#/properties/completedColor",
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Button",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Background",
                          type: "Control",
                          scope: "#/properties/buttonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Text",
                          type: "Control",
                          scope: "#/properties/buttonTextColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Hover",
                          type: "Control",
                          scope: "#/properties/buttonHoverColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Text Hover",
                          type: "Control",
                          scope: "#/properties/buttonTextHoverColor",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: "5ef663bf-f0d6-44f1-8a37-007fff84478f",
        title: "List of member information",
        version: 1,
        slug: "member-information",
        inputSchema: {
          title: "Member Information",
          type: "object",
          properties: {
            periodStartDate: {
              format: "date",
              title: "Period start date",
              type: "string",
            },
            textMainColor: {
              title: "Text main color",
              type: "string",
            },
            iconMainColor: {
              title: "Icon main color",
              type: "string",
            },
            showMemberSignupDate: {
              title: "Show member signup date",
              type: "boolean",
            },
            textHighlightColor: {
              title: "Text highlight color",
              type: "string",
            },
            showDateBalanceUpdated: {
              title: "Show date balance updated",
              type: "boolean",
            },
            showIcons: {
              title: "Show icons",
              type: "boolean",
            },
            showWelcomeMessage: {
              title: "Show welcome message",
              type: "boolean",
            },
            showMemberBalance: {
              title: "Show member balance",
              type: "boolean",
            },
            iconHighlightColor: {
              title: "Icon highlight color",
              type: "string",
            },
            customWidget: {
              title: "Custom widget",
              type: "boolean",
            },
            showNumberCompletedActivities: {
              title: "Show number of completed activities",
              type: "boolean",
            },
            activitiesPeriod: {
              title: "Activities period",
              type: "string",
              enum: ["ALL_ACTIVITIES", "FROM_SPECIFIC_DATE"],
            },
            showNumberActivitiesToComplete: {
              title: "Show number of activities to complete",
              type: "boolean",
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              className: "specifications-group",
              label: "Widget Component Specifications",
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showWelcomeMessage",
                    },
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showNumberCompletedActivities",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showWelcomeMessage",
                        },
                        effect: "DISABLE",
                      },
                      component: "checkbox",
                      label: "Show member signup date",
                      type: "Control",
                      scope: "#/properties/showMemberSignupDate",
                    },
                    {
                      options: {
                        labelMapping: {
                          ALL_ACTIVITIES: "All activities",
                          FROM_SPECIFIC_DATE: "From a specific date",
                        },
                      },
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showNumberCompletedActivities",
                        },
                        effect: "DISABLE",
                      },
                      component: "radioInput",
                      label: false,
                      type: "Control",
                      scope: "#/properties/activitiesPeriod",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: "FROM_SPECIFIC_DATE",
                          },
                          scope: "#/properties/activitiesPeriod",
                        },
                        effect: "SHOW",
                      },
                      component: "datePickerMaterialUI",
                      label: false,
                      type: "Control",
                      scope: "#/properties/periodStartDate",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-3",
                  elements: [
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showMemberBalance",
                    },
                    {
                      type: "Control",
                      component: "toggle",
                      scope: "#/properties/showNumberActivitiesToComplete",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-4",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/showMemberBalance",
                        },
                        effect: "DISABLE",
                      },
                      component: "checkbox",
                      label: "Show date when balance was last updated",
                      type: "Control",
                      scope: "#/properties/showDateBalanceUpdated",
                    },
                  ],
                },
              ],
            },
            {
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-5",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Styling",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "toggle",
                      type: "Control",
                      scope: "#/properties/showIcons",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "HIDE",
                      },
                      label: "Icons",
                      type: "SimpleGroup",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          className: "horizontal-layout-hexacolor",
                          elements: [
                            {
                              rule: {
                                condition: {
                                  conditions: [
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/customWidget",
                                    },
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/showIcons",
                                    },
                                  ],
                                  type: "AND",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Image*",
                              type: "Control",
                              scope: "#/properties/iconMainColor",
                            },
                            {
                              rule: {
                                condition: {
                                  conditions: [
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/customWidget",
                                    },
                                    {
                                      schema: {
                                        const: true,
                                      },
                                      scope: "#/properties/showIcons",
                                    },
                                  ],
                                  type: "AND",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Circle*",
                              type: "Control",
                              scope: "#/properties/iconHighlightColor",
                            },
                          ],
                        },
                      ],
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "HIDE",
                      },
                      label: "Bar Items",
                      type: "SimpleGroup",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          className: "horizontal-layout-hexacolor",
                          elements: [
                            {
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Fixed content*",
                              type: "Control",
                              scope: "#/properties/textMainColor",
                            },
                            {
                              rule: {
                                condition: {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                effect: "ENABLE",
                              },
                              component: "textInputHexaColor",
                              label: "Dynamic content*",
                              type: "Control",
                              scope: "#/properties/textHighlightColor",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
              label: "Widget Styling",
            },
          ],
        },
      },
      {
        id: "cdfe074f-377b-45d9-bb73-ab185dac206e",
        title: "List of member history",
        version: 1,
        slug: "member-history",
        inputSchema: {
          title: "Member History",
          type: "object",
          properties: {
            headerTextColor: {
              type: "string",
            },
            pointsTextColor: {
              type: "string",
            },
            historyType: {
              type: "string",
              enum: ["SWEEPSTAKES_HISTORY", "ACTIVITY_HISTORY"],
            },
            maxHeight: {
              type: "number",
            },
            rowTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            headerRowColor: {
              type: "string",
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              className: "specifications-group",
              label: "History Type",
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          ACTIVITY_HISTORY: "Activity history",
                          SWEEPSTAKES_HISTORY: "Sweepstake history",
                        },
                      },
                      component: "radioInput",
                      label: false,
                      type: "Control",
                      scope: "#/properties/historyType",
                    },
                  ],
                },
              ],
            },
            {
              type: "SimpleGroup",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-custom-widget",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Styling",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
              ],
              label: "Widget Styling",
            },
            {
              type: "SimpleGroup",
              rule: {
                condition: {
                  schema: {
                    const: false,
                  },
                  scope: "#/properties/customWidget",
                },
                effect: "HIDE",
              },
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Header row*",
                      type: "Control",
                      defaultValue: "#000000",
                      scope: "#/properties/headerRowColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Header text*",
                      type: "Control",
                      scope: "#/properties/headerTextColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Row text*",
                      type: "Control",
                      scope: "#/properties/rowTextColor",
                    },
                    {
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      component: "textInputHexaColor",
                      label: "Entries text*",
                      type: "Control",
                      scope: "#/properties/pointsTextColor",
                    },
                    {
                      tooltipDirection: "left",
                      component: "numberInput",
                      tooltipText:
                        "Set a maximum height for this element to prevent it from growing beyond that limit.",
                      scope: "#/properties/maxHeight",
                      rule: {
                        condition: {
                          schema: {
                            const: true,
                          },
                          scope: "#/properties/customWidget",
                        },
                        effect: "ENABLE",
                      },
                      label: "Max height*",
                      placeholder: "Insert value in pixels",
                      tooltipIcon: "info",
                      type: "Control",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
      {
        id: "ec71f530-5662-4a3a-9d2a-5aa9f60a2e55",
        title: "List of sweepstakes",
        version: 1,
        slug: "sweepstakes",
        inputSchema: {
          title: "List of Sweepstakes",
          type: "object",
          properties: {
            warningColor: {
              type: "string",
            },
            selectedCampaigns: {
              type: "array",
            },
            buttonColor: {
              type: "string",
            },
            format: {
              enum: ["GRID", "CAROUSEL"],
            },
            visibleNonMembers: {
              type: "boolean",
            },
            buttonTextHoverColor: {
              type: "string",
            },
            textColor: {
              type: "string",
            },
            buttonHoverColor: {
              type: "string",
            },
            itemsPerRow: {
              type: "number",
              enum: [3, 4],
            },
            titleColor: {
              type: "string",
            },
            unauthenticated: {
              type: "object",
              properties: {
                cta: {
                  type: "object",
                  properties: {
                    "es-mx": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL Spanish",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text Spanish",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                    "en-us": {
                      type: "object",
                      properties: {
                        url: {
                          title: "CTA URL English",
                          type: "string",
                          maxLength: 150,
                        },
                        text: {
                          title: "CTA Text English",
                          type: "string",
                          maxLength: 50,
                        },
                      },
                    },
                  },
                },
              },
            },
            maxHeight: {
              type: "number",
            },
            cardBgColor: {
              type: "string",
            },
            buttonTextColor: {
              type: "string",
            },
            customWidget: {
              type: "boolean",
            },
            comingSoonColor: {
              type: "string",
            },
          },
          dependencies: {
            customWidget: {
              oneOf: [
                {
                  properties: {
                    customWidget: {
                      const: true,
                    },
                  },
                  required: ["maxHeight"],
                },
                {
                  properties: {
                    customWidget: {
                      const: false,
                    },
                  },
                },
              ],
            },
          },
        },
        uiSchema: {
          type: "VerticalLayout",
          elements: [
            {
              tooltipDirection: "right",
              tooltipText:
                "Inform the button CTA and redirect link for non-authenticated experience",
              elements: [
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-1",
                  elements: [
                    {
                      component: "toggle",
                      label: "Widget is visible by non members",
                      type: "Control",
                      scope: "#/properties/visibleNonMembers",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text English*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL English*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  className: "horizontal-layout-2",
                  elements: [
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA Text Spanish*",
                      placeholder: "Type here",
                      type: "Control",
                      maxLength: 50,
                    },
                    {
                      component: "textInput",
                      scope:
                        "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                      rule: {
                        condition: {
                          schema: {
                            const: false,
                          },
                          scope: "#/properties/visibleNonMembers",
                        },
                        effect: "HIDE",
                      },
                      label: "CTA URL Spanish*",
                      placeholder: "www.URL.com",
                      type: "Control",
                      maxLength: 150,
                    },
                  ],
                },
              ],
              className: "setup-non-members-group",
              rules: [
                {
                  targets: [
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/en-us/properties/url",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/text",
                    "#/properties/unauthenticated/properties/cta/properties/es-mx/properties/url",
                  ],
                  condition: {
                    expectedValue: true,
                    scope: "#/properties/visibleNonMembers",
                  },
                  effect: "MAKE_REQUIRED",
                },
              ],
              label: "Button Setup for Non Members",
              tooltipIcon: "info",
              type: "SimpleGroup",
            },
            {
              className: "custom-preview-group",
              page: "Sweepstakes",
              type: "simpleGroupPreviewCards",
              elements: [
                {
                  className: "horizontal-layout-3",
                  label: "Format",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      options: {
                        labelMapping: {
                          CAROUSEL: "Show in horizontal carousel",
                          GRID: "Show in a grid pattern",
                        },
                      },
                      component: "radioInput",
                      type: "Control",
                      scope: "#/properties/format",
                    },
                  ],
                },
                {
                  className: "horizontal-layout-4",
                  label: "Widget Card Styling",
                  type: "HorizontalLayout",
                  elements: [
                    {
                      component: "toggle",
                      label: "Custom Widget",
                      type: "Control",
                      scope: "#/properties/customWidget",
                    },
                  ],
                },
                {
                  rule: {
                    condition: {
                      schema: {
                        const: false,
                      },
                      scope: "#/properties/customWidget",
                    },
                    effect: "HIDE",
                  },
                  className: "group-bg-gray",
                  label: "",
                  type: "SimpleGroup",
                  elements: [
                    {
                      type: "HorizontalLayout",
                      className: "horizontal-layout-5",
                      elements: [
                        {
                          options: {
                            labelMapping: {
                              3: "3 cards per row",
                              4: "4 cards per row",
                            },
                          },
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "radioInput",
                          type: "Control",
                          scope: "#/properties/itemsPerRow",
                        },
                        {
                          tooltipDirection: "right",
                          component: "numberInput",
                          tooltipText:
                            "Set a maximum height for this element to prevent it from growing beyond that limit.",
                          scope: "#/properties/maxHeight",
                          rule: {
                            condition: {
                              conditions: [
                                {
                                  schema: {
                                    const: true,
                                  },
                                  scope: "#/properties/customWidget",
                                },
                                {
                                  schema: {
                                    const: "GRID",
                                  },
                                  scope: "#/properties/format",
                                },
                              ],
                              type: "AND",
                            },
                            effect: "ENABLE",
                          },
                          label: "Max height",
                          placeholder: "Insert value in pixels",
                          tooltipIcon: "info",
                          type: "Control",
                          maxLength: 4,
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Card",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Card Background",
                          type: "Control",
                          scope: "#/properties/cardBgColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Sweepstakes Title",
                          type: "Control",
                          scope: "#/properties/titleColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Coming Soon",
                          type: "Control",
                          scope: "#/properties/comingSoonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Secondary Texts",
                          type: "Control",
                          scope: "#/properties/textColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Warning Message",
                          type: "Control",
                          scope: "#/properties/warningColor",
                        },
                      ],
                    },
                    {
                      className: "horizontal-layout-hexacolor",
                      label: "Button",
                      type: "HorizontalLayout",
                      elements: [
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Background",
                          type: "Control",
                          scope: "#/properties/buttonColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Text",
                          type: "Control",
                          scope: "#/properties/buttonTextColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Button Hover",
                          type: "Control",
                          scope: "#/properties/buttonHoverColor",
                        },
                        {
                          rule: {
                            condition: {
                              schema: {
                                const: true,
                              },
                              scope: "#/properties/customWidget",
                            },
                            effect: "ENABLE",
                          },
                          component: "textInputHexaColor",
                          label: "Text Hover",
                          type: "Control",
                          scope: "#/properties/buttonTextHoverColor",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    ],
  },
};
