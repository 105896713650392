import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Slider } from "@mui/material";
import { Icon } from "pepsico-ds";
import { useEffect, useState } from "react";

const CodeWarningLimitComponent = (props) => {
  const { handleChange, path, enabled, data } = props;
  const [defaultValue, setDefaultValue] = useState(0);

  useEffect(() => {
    if (!data) {
      handleChange(path, 0);
    }
    if (data) {
      setDefaultValue(data);
    }
  }, []);

  return (
    <div className="codeWarningLimit">
      <div className="title">
        <p className="label">
          Code Warning Limit % &nbsp;
          <Icon size="small" icon="info" />
        </p>
        <p className="selected-percentage">{data ?? 0}%</p>
      </div>
      <div className="slider-control">
        <p className="font-xs">0%</p>
        <Slider
          style={{ marginLeft: 8, marginRight: 8 }}
          aria-label="Always visible"
          onChange={(e) => handleChange(path, e.target.value)}
          value={data ?? 0}
          min={0}
          max={100}
          step={1}
          defaultValue={defaultValue}
          disabled={!enabled}
        />
        <p className="font-xs">100%</p>
      </div>
    </div>
  );
};

export const CodeWarningLimitControl = withJsonFormsMultiEnumProps(
  CodeWarningLimitComponent
);
