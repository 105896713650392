export const filterJsonSchema = {
  type: "object",
  properties: {
    active: {
      label: "Status",
      items: {
        type: "string",
        options: [
          { id: true, displayText: "Active" },
          { id: false, displayText: "Inactive" },
        ],
      },
    },
    type: {
      label: "Type",
      items: {
        type: "string",
        options: [
          { id: "INTEGRATED", displayText: "Integrated" },
          { id: "PARTNER_CODE_GROUP", displayText: "Partner Code Group" },
        ],
      },
    },
  },
};

export const filterUiSchema = {
  type: "HorizontalLayout",
  className: "partner-filters",
  elements: [
    {
      type: "Control",
      scope: "#/properties/active",
      component: "multiSelect",
      placeholder: "Status",
      selection: "single",
    },
    {
      type: "Control",
      scope: "#/properties/type",
      component: "multiSelect",
      placeholder: "Partners",
      selection: "multiple",
    },
  ],
};
