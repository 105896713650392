import { useQuery } from "@apollo/client";
import { Dropdown } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { RewardContext } from "../../../context/RewardContext";
import { listRewardTypes } from "../../../graphql/queries/listRewardTypes";

const RewardTypeSelect = ({ label, selected, required, readonly }) => {
  const {
    rewardState,
    addOptionLoading,
    removeOptionLoading,
    updateRewardState,
    setToast,
  } = useContext(RewardContext);
  const { refetch } = useQuery(listRewardTypes, {
    skip: true,
  });
  const [typesList, setTypesList] = useState([]);
  const [selectedType, setSelectedType] = useState([{ displayText: "" }]);

  useEffect(() => {
    if (rewardState?.partnerId) {
      addOptionLoading("types");
      refetch({ partnerId: rewardState.partnerId, size: 1000 })
        .then(({ data: rewardTypeData, loading }) => {
          !loading && removeOptionLoading("types");
          if (rewardTypeData?.listRewardType) {
            const typesListOptions = rewardTypeData.listRewardType.items?.map(
              (type) => ({
                id: type.id,
                displayText: type.name,
                schema: type.schemaData,
              })
            );
            setTypesList(typesListOptions);
          }
        })
        .catch(() => {
          removeOptionLoading("types");
          setTypesList([]);
          setToast({
            open: true,
            type: "error",
            title: "Something went wrong while loading types.",
            description: "Please try again later.",
          });
        });
      return;
    }
    setTypesList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardState?.partnerId, refetch]);

  useEffect(() => {
    if (typesList.length) {
      const selectedTypeOption = typesList.find((type) => type.id === selected);
      setSelectedType([selectedTypeOption ?? { displayText: "" }]);
    }
  }, [selected, typesList]);

  useEffect(() => {
    if (rewardState.rewardTypeId && !rewardState.type?.length && typesList.length) {
      const selectedTypeOption = typesList.find(
        (type) => type.id === rewardState.rewardTypeId
      );
      updateRewardState({
        ...rewardState,
        type: selectedTypeOption?.displayText || "",
        schema:
          typeof selectedTypeOption?.schema === "object"
            ? selectedTypeOption?.schema
            : "",
      });
    }
  }, [rewardState, typesList, updateRewardState]);

  const diabledControl = readonly ? "disabled-control" : "";

  return (
    <Dropdown
      isRequired={required}
      childList={typesList}
      style={{ minWidth: "0px" }}
      className={`zIndexToDropDown ${diabledControl}`}
      label={label}
      selection="single"
      selectedValue={selectedType}
      setSelectedValue={(val) =>
        updateRewardState({
          ...rewardState,
          rewardTypeId: val?.[0]?.id || "",
          type: val?.[0]?.displayText || "",
          schema: typeof val?.[0]?.schema === "object" ? val?.[0]?.schema : "",
        })
      }
      size="medium"
      placeholder="Select"
      disabled={readonly}
    />
  );
};

export default RewardTypeSelect;
