import { useQuery } from "@apollo/client";
import { composePaths, createAjv } from "@jsonforms/core";
import { useJsonForms, withJsonFormsArrayControlProps } from "@jsonforms/react";
import { Modal } from "pepsico-ds";
import { useCallback, useMemo, useState } from "react";
import { listSegments } from "../../../graphql/queries/listSegments";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import JsonFormsWrapper from "../jsonFormsWrapper/JsonFormsWrapper";
import SegmentSelect from "../segmentSelect/SegmentSelect";
import "./multistep-segment-select.scss";

const MultiStepSegmentSelectComponent = ({
  visible,
  label,
  renderers,
  schema,
  ...props
}) => {
  const {
    openModal,
    selectedSegments,
    savedSegments,
    currentSegmentIndex,
    page,
    segments,
    contentImage,
    handleToggleModal,
    handleSegmentSelection,
    handleRemoveSaved,
    handleStepForward,
    handleCloseModal,
    handleImageChange,
    currentPageSegmentName,
    childUiSchema,
    childPath,
    isLastPage,
    isDataValid,
    setSelectedSegments,
    setPage,
    setContentImage,
  } = useMultiStepSegmentSelect({ schema, ...props });

  return (
    visible && (
      <>
        <span className="multistep-segment-select-label">{label}</span>
        <div className="multistep-segment-select">
          {savedSegments.map((segment, index) =>
            renderSegmentItem(
              segment,
              index,
              handleRemoveSaved,
              setSelectedSegments,
              setPage,
              handleToggleModal,
              setContentImage
            )
          )}
          {renderAddSegmentButton(handleToggleModal)}
          <Modal
            className={
              page > 0
                ? "segment-select-steps-modal"
                : "campaign-segment-select-modal"
            }
            showModal={openModal}
            title={
              page === 0
                ? "Choose Segment"
                : `Upload Homepage Image for ${currentPageSegmentName}`
            }
            onCloseModal={handleCloseModal}
            showTertiaryButton={false}
            showLink={false}
            showSecondaryButton={
              isLastPage && currentSegmentIndex === savedSegments.length
            }
            secondaryButtonProps={{
              text: isDataValid ? "Back to Segment" : "Previous",
              variant: "secondary",
              iconLeading: "arrow_back",
              onClick: () => setPage(page - 1),
            }}
            primaryButtonProps={{
              text: isLastPage ? "Save" : "Next",
              ...(isLastPage ? {} : { iconTrailing: "arrow_forward" }),
              disabled: page === 0 && !selectedSegments.length,
              onClick: handleStepForward,
            }}
          >
            {page === 0 ? (
              <SegmentSelect
                segmentsData={segments}
                selected={selectedSegments}
                onChange={handleSegmentSelection}
                selection="single"
              />
            ) : (
              <div className="segment-steps-content-wrapper">
                <JsonFormsWrapper
                  path={childPath}
                  renderers={renderers}
                  schema={schema}
                  uischema={childUiSchema}
                  onChange={handleImageChange}
                  data={contentImage}
                />
              </div>
            )}
          </Modal>
        </div>
      </>
    )
  );
};

const SEGMENTS_PATH = "segments";
const useMultiStepSegmentSelect = ({
  data,
  removeItems,
  handleChange,
  path,
  schema,
  uischema,
  addItem,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [contentImage, setContentImage] = useState({});
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [page, setPage] = useState(0);
  const ctx = useJsonForms();
  const campaignSegments = useMemo(
    () => ctx?.core?.data?.segments || [],
    [ctx?.core?.data?.segments]
  );
  const contentSegments = useMemo(
    () => ctx?.core?.data?.segmentedContents || [],
    [ctx?.core?.data?.segmentedContents]
  );
  const { data: segmentsData } = useQuery(listSegments, {
    variables: { status: "ENABLED" },
    fetchPolicy: "cache-first",
  });

  const listSegmentsRes = segmentsData?.listSegments;
  const savedSegments = useMemo(() => {
    return campaignSegments.map((item) => ({
      name: listSegmentsRes?.find((segment) => segment.id === item)?.name || "",
      id: item,
      contentData: contentSegments?.find(
        (segment) => segment.id === item || segment?.segments[0] === item
      )?.contentData,
    }));
  }, [campaignSegments, listSegmentsRes, contentSegments]);
  const segments = getAvailableSegments(listSegmentsRes, savedSegments);

  const addItemCallback = useCallback(
    (path, value) => addItem(path, value),
    [addItem]
  );

  const handleImageChange = (data) => {
    if (data?.contentData?.campaign_image_url) {
      setContentImage(data);
    }
  };

  const removeItemsCallback = useCallback(
    (path, toDelete) => removeItems(path, toDelete),
    [removeItems]
  );

  const handleToggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleSegmentSelection = (selected) => {
    setSelectedSegments(selected);
  };

  const handleRemoveSaved = (id) => {
    const contentSegmentsWithoutRemoved = contentSegments.filter(
      (segment) => segment.id !== id
    );
    const campaignSegmentsWithoutRemoved = savedSegments.filter(
      (segment) => segment.id !== id
    );

    handleChange(path, generateSegmentsData(contentSegmentsWithoutRemoved));
    handleChange(SEGMENTS_PATH, campaignSegmentsWithoutRemoved);
  };

  const handleStepForward = () => {
    if (page === 0) {
      if (data?.length - savedSegments.length > 0) {
        removeItemsCallback(path, [data.length - 1])();
      }

      setPage(page + 1);
      setCurrentSegmentIndex(savedSegments.length);
      return;
    }

    const toAddSegments = selectedSegments.filter(
      (segment) => !campaignSegments?.some((item) => item.segments?.[0] === segment)
    );

    handleChange(SEGMENTS_PATH, [...campaignSegments, toAddSegments[0].id]);
    if (contentImage?.contentData?.campaign_image_url) {
      addItemCallback(path, { ...selectedSegments[0], ...contentImage })();
    }

    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setPage(0);
    setSelectedSegments([]);
    setContentImage({});
  };

  const isLastPage = page === 1;
  const currentPageSegmentName = listSegmentsRes?.find(
    (segment) => segment.id === selectedSegments[0]?.id
  )?.name;
  const childUiSchema = uischema.options?.detail;
  const childPath = composePaths(path, `${currentSegmentIndex}`);

  const ajv = createAjv();
  const isDataValid = ajv.validate(schema, data?.[currentSegmentIndex] || {});

  return {
    openModal,
    selectedSegments,
    savedSegments,
    currentSegmentIndex,
    page,
    segments,
    contentImage,
    handleToggleModal,
    handleSegmentSelection,
    handleRemoveSaved,
    handleStepForward,
    handleCloseModal,
    handleImageChange,
    currentPageSegmentName,
    childUiSchema,
    childPath,
    isLastPage,
    isDataValid,
    setSelectedSegments,
    setPage,
    setContentImage,
  };
};

// MARK :- Helper Functions

const getAvailableSegments = (listSegmentsRes, savedSegments) => {
  return (
    listSegmentsRes?.filter(
      (segment) => !savedSegments?.some((item) => item.id === segment.id)
    ) || []
  );
};

const generateSegmentsData = (savedSegments) => {
  return savedSegments.map((segment) => ({
    segments: segment.segments?.[0],
    contentData: segment.contentData,
  }));
};

const renderSegmentItem = (
  segment,
  index,
  handleRemoveSaved,
  setSelectedSegments,
  setPage,
  handleToggleModal,
  setContentImage
) => (
  <div
    className={`segment-item display-flex flex-direction-row font-xs font-bold segment-added ${segment.status === "DISABLED" ? "segment-disabled" : ""}`}
    key={segment.id}
  >
    <div className="added-segment display-flex flex-direction-row align-items-center justify-content-space-between">
      <div className="segment-content display-flex flex-direction-row align-items-center">
        {segment.contentData?.campaign_image_url && (
          <img
            className="segment-homepage-image"
            src={segment.contentData.campaign_image_url}
            alt={`Homepage for ${segment.name}`}
          />
        )}
        <span>{segment.name}</span>
      </div>
      <ButtonGroupDynamic
        size="small"
        items={[
          {
            icon: "edit",
            onClick: () => {
              if (segment.contentData?.campaign_image_url) {
                setContentImage({
                  contentData: segment.contentData,
                });
              }
              setSelectedSegments([segment]);
              setPage(1);
              handleToggleModal();
            },
          },
          {
            icon: "delete",
            onClick: () => handleRemoveSaved(segment.id),
          },
        ]}
      />
    </div>
  </div>
);

const renderAddSegmentButton = (onClick) => (
  <div className="segment-item display-flex flex-direction-row font-xs font-bold">
    <ButtonGroupDynamic
      className="add-new"
      size="small"
      items={[{ icon: "add", onClick }]}
    />
    Add New Segment
  </div>
);

export const MultiStepSegmentSelectControl = withJsonFormsArrayControlProps(
  MultiStepSegmentSelectComponent
);
