import { useCallback, useState } from "react";
import { useCreatePartnerCodeGroupMutation } from "../../Hooks/useCreatePartnerGroupMutation";
import PartnerCodeGroupFormModal from "../PartnerCodeGroupForm/PartnerCodeGroupForm";

function CopyPartnerCodeGroupModel({
  isOpen,
  closeCreatePartnerCodeGroupModal,
  setToast,
  partnerCodeGroup,
  refetch,
}) {
  const initialPartnerCodeGroupData = {
    ...partnerCodeGroup,
    name: `${partnerCodeGroup?.name} copy`,
  };

  const [partnerCodeGroupData, setPartnerCodeGroupData] = useState(
    initialPartnerCodeGroupData
  );
  const [copyPartnerCodeGroupMutation, { loading }] =
    useCreatePartnerCodeGroupMutation();

  const handleCreatePartnerCodeGroup = useCallback(() => {
    const onSuccess = () => {
      setToast({
        open: true,
        type: "success",
        title: `Code Group ${partnerCodeGroupData.name} Created!`,
        description: "You have just published a partner Group!",
      });
      resetForm();
      refetch();
    };

    const onFailure = (error) => {
      setToast({
        open: true,
        type: "error",
        title: `Error on creating Code Group: ${partnerCodeGroupData.name}`,
        description: error?.message || "",
      });
      resetForm();
    };
    /* eslint-disable no-unused-vars */
    const {
      programId,
      id,
      codeCount,
      exhaustedCode,
      importedDate,
      isImported,
      ...dataToSubmit
    } = partnerCodeGroupData;

    copyPartnerCodeGroupMutation(onSuccess, onFailure, dataToSubmit);
  }, [copyPartnerCodeGroupMutation, partnerCodeGroupData, refetch, setToast]);

  const resetForm = () => {
    setPartnerCodeGroupData(initialPartnerCodeGroupData);
    closeCreatePartnerCodeGroupModal();
  };

  return (
    <PartnerCodeGroupFormModal
      isOpen={isOpen}
      toggleCreatePartnerCodeModal={closeCreatePartnerCodeGroupModal}
      closeModal={closeCreatePartnerCodeGroupModal}
      partnerCodeGroupData={partnerCodeGroupData}
      setPartnerCodeGroupData={setPartnerCodeGroupData}
      handleMutation={handleCreatePartnerCodeGroup}
      loading={loading}
      title="Copy"
      isEditMode={false}
    />
  );
}

export default CopyPartnerCodeGroupModel;
