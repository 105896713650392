import { gql } from "@apollo/client";

export const listPartners = gql`
  query ListPartners($filter: PartnerFilter, $size: Int, $page: Int) {
    listPartners(filter: $filter, page: $page, size: $size) {
      query
      page
      size
      total
      items {
        id
        name
        active
        description
        image
        type
        programId
      }
    }
  }
`;
