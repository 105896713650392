import { Avatar, Card, Font, Icon } from "pepsico-ds";
import useModalState from "../../../hooks/useModalState";
import Modal from "./Modal";
import "./socialMediaSelector.scss";

export default function MediaCard({
  socialMedia,
  handleSocialMediaUpdate,
  id,
  readOnly,
}) {
  const [isOpen, onOpen, onClose] = useModalState();

  return (
    <section className="social-media-card">
      <Card
        border
        ellipsis
        icon="info"
        onEllipsisClick={function Qa() {}}
        onIconClick={function Qa() {}}
        title="Title"
      >
        <section className="info-container">
          {!readOnly ? (
            <Icon
              className="drag-icon"
              icon="drag_indicator"
              size="small"
              variant="filled"
            />
          ) : null}
          <Avatar name={socialMedia?.title} imageUrl={socialMedia?.image} />
          <section className="info-text">
            <Font style={{ color: "#4C5358", fontWeight: "bold" }}>
              {socialMedia?.title}
            </Font>
            <Font style={{ color: "#616161" }}>{socialMedia?.url}</Font>
          </section>
        </section>
        {!readOnly ? (
          <Icon
            icon="edit"
            label="open edit media modal"
            onClick={onOpen}
            size="medium"
            className="settings-button"
          />
        ) : null}
      </Card>
      {isOpen ? (
        <Modal
          isOpen={isOpen}
          closeModal={onClose}
          socialMedia={socialMedia}
          handleSocialMediaUpdate={handleSocialMediaUpdate}
          id={id}
        />
      ) : null}
    </section>
  );
}
