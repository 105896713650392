export const FeatureToggle = {
  newFileUploader: "REACT_APP_NEW_FILE_UPLOADER",
  imagePicker: "REACT_APP_IMAGE_PICKER",
};

export const isFeatureEnabled = (flagKey) => {
  const ftValue = window?.[flagKey];
  if (ftValue === undefined) {
    console.warn(`[FT] Feature "${flagKey}" is not defined!`);
  }
  return ftValue == "true";
};
