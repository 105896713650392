import { useCallback, useState } from "react";
import { useUpdatePartnerCodeGroupMutation } from "../../Hooks/useEditPartnerCodeGroupMutation";
import PartnerCodeGroupFormModal from "../PartnerCodeGroupForm/PartnerCodeGroupForm";

function EditPartnerCodeGroupModel({
  isOpen,
  closeCreatePartnerCodeGroupModal,
  setToast,
  partnerCodeGroup,
  refetch,
}) {
  const [partnerCodeGroupData, setPartnerCodeGroupData] = useState(partnerCodeGroup);
  const [updatePartnerCodeGroupMutation, { loading }] =
    useUpdatePartnerCodeGroupMutation();

  const handleUpdatePartnerCodeGroup = useCallback(() => {
    const onSuccess = () => {
      setToast({
        open: true,
        type: "success",
        title: `Code Group ${partnerCodeGroupData.name} Updated!`,
        description: "You have just updated a partner Group!",
      });
      resetForm();
      refetch();
    };

    const onFailure = (error) => {
      setToast({
        open: true,
        type: "error",
        title: `Error updating Code Group: ${partnerCodeGroupData.name}`,
        description: error?.message || "",
      });
      resetForm();
    };

    /* eslint-disable no-unused-vars */
    const {
      id,
      programId,
      codeCount,
      exhaustedCode,
      importedDate,
      isImported,
      ...dataToSubmit
    } = partnerCodeGroupData;

    updatePartnerCodeGroupMutation(onSuccess, onFailure, dataToSubmit, id);
  }, [partnerCodeGroupData, refetch, setToast, updatePartnerCodeGroupMutation]);

  const resetForm = () => {
    setPartnerCodeGroupData(partnerCodeGroup);
    closeCreatePartnerCodeGroupModal();
  };

  return (
    <PartnerCodeGroupFormModal
      isOpen={isOpen}
      toggleCreatePartnerCodeModal={closeCreatePartnerCodeGroupModal}
      closeModal={closeCreatePartnerCodeGroupModal}
      partnerCodeGroupData={partnerCodeGroupData}
      setPartnerCodeGroupData={setPartnerCodeGroupData}
      handleMutation={handleUpdatePartnerCodeGroup}
      loading={loading}
      title="Edit"
      isEditMode={true}
    />
  );
}

export default EditPartnerCodeGroupModel;
