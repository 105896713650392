import Check from "@mui/icons-material/Check";
import { Card } from "pepsico-ds";
import PropTypes from "prop-types";
import { getDateWithFormat } from "../../../utils/commons";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { partnersCodeGroupActionButtons } from "../partnersCodeGroupActionButton";
import "./partnerCodeGroupCard.scss";

const PartnerCodeGroupCard = ({
  partnerCodeGroupData,
  handleActionButtons,
  currentProgramTimezone,
}) => {
  /* We need this code for future change so commented */
  /* const getStatus = (status, startDate, endDate) => {
    if (
      new Date(moment.tz(startDate, currentProgramTimezone)) >
      new Date(moment.tz(currentProgramTimezone))
    ) {
      return "imported";
    }
    if (
      new Date(moment.tz(endDate, currentProgramTimezone)) <
      new Date(moment.tz(currentProgramTimezone))
    ) {
      return "expired";
    }
    return status ? "active" : "anactive";
  }; */

  return (
    <Card
      icon=""
      ellipsis=""
      style={{
        padding: "0px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      <div className="partner-code-group-card-container">
        <div className="partner-code-group-details">
          <div className="partner-code-group-status-type">
            <span
              className={`status ${partnerCodeGroupData?.isActive ? "active" : "inactive"}`}
            >
              {partnerCodeGroupData?.isActive ? "active" : "inactive"}
            </span>
            {partnerCodeGroupData?.isImported && (
              <span className={`code-upload-status`}>
                Code Imported
                <Check fontSize="small" />
              </span>
            )}
          </div>
          <h3 className="partner-code-group-name">{partnerCodeGroupData?.name}</h3>
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Total Codes</span>
              <span className="field-value">
                {partnerCodeGroupData?.codeCount !== undefined &&
                partnerCodeGroupData?.codeCount !== null &&
                partnerCodeGroupData?.codeCount !== ""
                  ? partnerCodeGroupData?.codeCount
                  : "-"}
              </span>
            </div>
            <div className="field-pair">
              <span className="field-label">Exhausted Codes</span>
              <span className="field-value">
                {partnerCodeGroupData?.exhaustedCode !== undefined &&
                partnerCodeGroupData?.exhaustedCode !== null &&
                partnerCodeGroupData?.exhaustedCode !== ""
                  ? partnerCodeGroupData?.exhaustedCode
                  : "-"}
              </span>
            </div>
          </div>

          <div className="partner-code-group-dates">
            <div className="date-item">
              <span className="date-label">Start Date</span>
              <span>
                {getDateWithFormat(
                  partnerCodeGroupData?.startDate,
                  currentProgramTimezone
                )}
              </span>
            </div>
            <div className="date-item">
              <span className="date-label">End Date</span>
              <span>
                {getDateWithFormat(
                  partnerCodeGroupData?.endDate,
                  currentProgramTimezone
                )}
              </span>
            </div>
          </div>
          <div className="button-group-container">
            <ButtonGroupDynamic
              items={partnersCodeGroupActionButtons(
                partnerCodeGroupData?.isActive ? "active" : "inactive",
                handleActionButtons,
                partnerCodeGroupData
              )}
              spacing="1px"
              size="medium"
              variant="control"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

PartnerCodeGroupCard.propTypes = {
  title: PropTypes.string.isRequired,
  codeCount: PropTypes.number,
  exhaustedCode: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  codeImportStatus: PropTypes.string,
  currentProgramTimezone: PropTypes.string,
};

export default PartnerCodeGroupCard;
