export const partnerCodeGroupFilterJsonSchema = {
  title: "Filter",
  type: "object",
  properties: {
    active: {
      label: "Status",
      items: {
        type: "string",
        options: [
          { id: true, displayText: "Active" },
          { id: false, displayText: "Inactive" },
        ],
      },
    },
    partnerIds: {
      label: "Partners",
      items: {
        type: "string",
        options: [{ id: "1234", displayText: "Test" }],
      },
    },
    startDate: {
      type: "string",
    },
    endDate: {
      type: "string",
    },
  },
};

export const partnerCodeGroupFilterUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/active",
      component: "multiSelect",
      placeholder: "Status",
      selection: "single",
    },
    {
      type: "Control",
      scope: "#/properties/partnerIds",
      component: "multiSelect",
      placeholder: "Partners",
      selection: "multiple",
    },
    {
      type: "Control",
      scope: "#/properties/startDate",
      component: "dateTimePicker",
      label: "Start date",
    },
    {
      type: "Control",
      scope: "#/properties/endDate",
      component: "dateTimePicker",
      label: "End Date",
      rule: {
        condition: {
          schema: {
            gt: "startDate",
          },
        },
        effect: "VALIDATE",
      },
    },
  ],
};
