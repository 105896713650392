import { File } from "pepsico-ds";
import PropTypes from "prop-types";
import { FeatureToggle, isFeatureEnabled } from "../../../utils/featureToggle";
import { ImagePicker } from "../../imagePicker/ImagePicker";

export default function FileUploadField({
  acceptedExtensions,
  className,
  disabled = false,
  label,
  maxSize,
  uiSchema,
  updateValue,
  value,
}) {
  let imageInfo = uiSchema.description
    ? uiSchema.description
    : "Drag and drop your file here";
  if (uiSchema.maxWidth && uiSchema.maxHeight)
    imageInfo += ` (${uiSchema.maxWidth}px x ${uiSchema.maxHeight}px)`;
  return (
    <>
      {isFeatureEnabled(FeatureToggle.newFileUploader) ? (
        <ImagePicker
          data-testid="image-uploader"
          accept={acceptedExtensions}
          description={imageInfo}
          files={value || []}
          onUpdate={updateValue}
          label={label}
          multiple
          classNameProp={className}
          maxSize={maxSize}
          disabled={disabled}
        />
      ) : (
        <File
          data-testid="file-uploader"
          className={className}
          style={{ width: "100%" }}
          accept={acceptedExtensions}
          description={imageInfo}
          label={label}
          variant="default"
          files={value || []}
          onUpdate={updateValue}
          multiple
          disabled={disabled}
        />
      )}
    </>
  );
}

FileUploadField.propTypes = {
  acceptedExtensions: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  maxSize: PropTypes.number,
  uiSchema: PropTypes.shape({ description: PropTypes.string }).isRequired,
  updateValue: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
};
