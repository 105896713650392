import { gql, useQuery } from "@apollo/client";
import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useEffect, useMemo, useState } from "react";
import "./multiple-id-selector.scss";

const MultiplePartnerSelectorComponent = (props) => {
  const { handleChange, data, label, path, required, visible, enabled } = props;

  const { data: listOptions, loading } = useQuery(gql`
    query ListPartners {
      listPartners(page: 0, size: 1000, filter: { types: ["INTEGRATED"] }) {
        items {
          id
          name
        }
      }
    }
  `);

  const formattedOptions = useMemo(() => {
    const items = listOptions?.listPartners?.items
    if (loading || !items) {
      return []
    }

    return items.map((child) => ({
      id: child.id,
      displayText: child.name || "",
    }))
  }, [loading, listOptions]);

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ formattedOptions:", formattedOptions);
    if (!data || !formattedOptions) {
      setSelectedValues([])
      return
    }

    const values = data
      .filter((selectedValueId) =>
        formattedOptions.find((option) => selectedValueId === option?.id)
      )
      .map((selectedValueId) =>
        formattedOptions.find((option) => selectedValueId === option?.id)
      );

    setSelectedValues(values);
  }, [data, formattedOptions]);

  const formatData = (listOptions) =>
    listOptions ? listOptions.map((item) => item.id) : [];

  const handleChangeValues = (newValues) => {
    handleChange(path, formatData(newValues));
  };

  if (!visible) return null;

  if (loading && formattedOptions.length === 0) return <p>Loading...</p>;

  return (
    <div className="multiple-select-wrap">
      <Dropdown
        style={{ zIndex: 1000 }}
        label={label}
        placeholder="Select Partner"
        childList={formattedOptions}
        selection="multiple"
        isRequired={required}
        disabled={!enabled}
        selectedValue={selectedValues}
        setSelectedValue={handleChangeValues}
      />
    </div>
  );
};

export const MultiplePartnerSelectorControl = withJsonFormsMultiEnumProps(
  MultiplePartnerSelectorComponent
);
