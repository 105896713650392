import { Grid } from "pepsico-ds";
import ViewComponentsRenderer from "../../../../components/campaign/viewComponents/ViewComponentsRenderer";
import SummaryCard from "./SummaryCard";

const Activity = ({ data, length }) => {
  return (
    <>
      {length > 1 && (
        <h3 className="campaign-definition-name">
          {data?.activityDefinition?.name}
        </h3>
      )}
      <Grid
        item
        xs={12}
        md={6}
        xl={4}
        mt={1}
        mb={1}
        className="display-flex gap-3 two-column"
      >
        <ViewComponentsRenderer
          customComponentName={data?.activityDefinition?.slug}
          data={data?.activityData}
          inputSchema={data?.activityDefinition?.inputSchema}
        />
      </Grid>
    </>
  );
};
const Rules = ({ data }) => (
  <>
    {/* <h3 className="campaign-name">Rules</h3> */}
    {data?.map((rule) => (
      <Grid
        key={rule?.ruleDefinition?.slug}
        item
        xs={12}
        md={6}
        xl={4}
        mt={1}
        mb={1}
        className="display-flex gap-3 two-column"
      >
        <ViewComponentsRenderer
          customComponentName={rule.ruleDefinition?.slug}
          data={rule.ruleData}
          inputSchema={rule.ruleDefinition?.inputSchema}
        />
      </Grid>
    ))}
  </>
);
const Outcomes = ({ data }) => {
  return (
    <>
      <h3 className="campaign-name">Outcomes</h3>
      {data
        .reduce(
          (acc, outcome) => [
            ...acc,
            ...outcome.outcomeRules,
            {
              ruleData: outcome.outcomeData || {},
              ruleDefinition: outcome.outcomeDefinition || {},
            },
          ],
          []
        )
        .filter((rule) => rule?.ruleData?.target_id !== null)
        .map((rule) => (
          <Grid
            key={rule?.ruleDefinition?.slug}
            item
            xs={12}
            md={6}
            xl={4}
            mt={1}
            mb={1}
            className="display-flex gap-3 two-column"
          >
            {!!Object.keys(rule.ruleData).length && (
              <ViewComponentsRenderer
                customComponentName={rule.ruleDefinition?.slug}
                data={rule.ruleData}
                inputSchema={rule.ruleDefinition?.inputSchema}
              />
            )}
          </Grid>
        ))}
    </>
  );
};
const SummaryActivitySetup = ({ data, isViewScreen, editSectionHandler }) => {
  return (
    <SummaryCard
      title="Activity Setup"
      isViewScreen={isViewScreen}
      handleEditSection={editSectionHandler}
    >
      {data?.name && <h3 className="campaign-name">{data?.name}</h3>}
      {data.activities?.map((activity) => (
        <div className="mb-20" key={activity?.activityDefinition?.name}>
          <Grid container>
            {activity?.activityData &&
              activity?.activityDefinition?.inputSchema &&
              activity?.activityDefinition?.uiSchema && (
                <Activity data={activity} length={data.activities?.length} />
              )}

            {/* Render activityRules */}
            {activity?.activityRules?.length > 0 && (
              <Rules data={activity.activityRules} />
            )}
          </Grid>
          {/* Render activities outcomes */}
          {activity?.outcomes?.length > 0 && <Outcomes data={activity.outcomes} />}
        </div>
      ))}
    </SummaryCard>
  );
};

export default SummaryActivitySetup;
