import { gql } from "@apollo/client";

const getListPartners = gql`
  query ListPartners($filter: PartnerFilter!) {
    listPartners(filter: $filter) {
      items {
        id
        name
      }
    }
  }
`;

export default getListPartners;
