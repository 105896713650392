import { useState } from "react";
import ToastNotification from "../../../common/toastNotification/ToastNotification";
import CopyPartnerModal from "../CopyPartner/CopyPartner";
import DeletePartnerModal from "../DeletePartner/DeletePartner";
import EditPartnerModal from "../EditPartner/EditPartner";
import ViewDetailsModal from "../ViewDetails/ViewDetails";

const PartnerCardModal = ({
  viewIsOpen,
  viewOnClose,
  copyIsOpen,
  copyOnClose,
  editIsOpen,
  editOnClose,
  deleteIsOpen,
  deleteOnClose,
  partner,
  refetch,
}) => {
  const { name, description, type, active, id } = partner;
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });

  return (
    <>
      {viewIsOpen ? (
        <ViewDetailsModal
          isOpen={viewIsOpen}
          closeModal={viewOnClose}
          name={name}
          description={description}
          type={type}
          id={id}
          setToast={setToast}
        />
      ) : null}
      {copyIsOpen ? (
        <CopyPartnerModal
          isOpen={copyIsOpen}
          closeModal={copyOnClose}
          partner={{
            name,
            description,
            type,
            active,
          }}
          refetch={refetch}
          id={id}
          setToast={setToast}
        />
      ) : null}
      {editIsOpen ? (
        <EditPartnerModal
          isOpen={editIsOpen}
          closeModal={editOnClose}
          partner={{
            name,
            description,
            type,
            active,
          }}
          refetch={refetch}
          id={id}
          setToast={setToast}
        />
      ) : null}
      {deleteIsOpen ? (
        <DeletePartnerModal
          isOpen={deleteIsOpen}
          closeModal={deleteOnClose}
          id={id}
          name={name}
          refetch={refetch}
          setToast={setToast}
        />
      ) : null}
      <ToastNotification
        {...toast}
        handleClose={() => setToast({ ...toast, open: false })}
      />
    </>
  );
};

export default PartnerCardModal;
