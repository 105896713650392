import { withJsonFormsControlProps } from "@jsonforms/react";
import { useContext } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import DateTimePickerWithTimeZone from "../../common/DateTime/DateTimePickerWithTimeZone";

const DateTimePickerComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    required,
    uischema,
    schema,
    enabled,
    otherData,
  } = props;
  const {
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);
  const currentProgramTimezone = programConfig?.localization?.programTimezone;
  return (
    <>
      {currentProgramTimezone ? (
        <DateTimePickerWithTimeZone
          dateTime={data}
          setDateTime={(newVal) => handleChange(path, newVal)}
          timezone={currentProgramTimezone}
          format={schema.date_format}
          label={`${label}${required ? "*" : ""}`}
          errors={errors}
          required={required}
          minimum={uischema?.minimum}
          disabled={!enabled || uischema?.disabled}
          uischema={uischema}
          otherData={otherData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export const DateTimePickerControl = withJsonFormsControlProps(
  DateTimePickerComponent
);
