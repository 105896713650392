import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import { Button } from "pepsico-ds";
import { useEffect, useState } from "react";

const CodeGeneratorComponent = (props) => {
  const { uischema } = props;
  const ctx = useJsonForms();
  const jsonformState = ctx.core.data;
  const [resetCode, setResetCode] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [codeConfigs, setCodeConfigs] = useState({
    prefix: jsonformState.prefixVal || "",
    suffix: jsonformState.suffixVal || "",
    excludedChars: jsonformState.excludeCharacterValues || "",
    characterSet: jsonformState?.characterSet || "",
    codeLength: jsonformState?.codeLength || 0,
  });
  useEffect(() => {
    if (codeConfigs.prefix !== jsonformState["prefixVal"]) {
      setCodeConfigs((prev) => ({
        ...prev,
        prefix: jsonformState["prefixVal"] || "",
      }));
    }
    if (codeConfigs.suffix !== jsonformState["suffixVal"]) {
      setCodeConfigs((prev) => ({
        ...prev,
        suffix: jsonformState["suffixVal"] || "",
      }));
    }
    if (codeConfigs.excludedChars !== jsonformState["excludeCharacterValues"]) {
      setCodeConfigs((prev) => ({
        ...prev,
        excludedChars: jsonformState["excludeCharacterValues"] || "",
      }));
    }
    if (codeConfigs.characterSet !== jsonformState["characterSet"]) {
      setCodeConfigs((prev) => ({
        ...prev,
        characterSet: jsonformState["characterSet"] || "",
      }));
    }
    if (codeConfigs.codeLength !== jsonformState["codeLength"]) {
      setCodeConfigs((prev) => ({
        ...prev,
        codeLength: jsonformState["codeLength"] || 0,
      }));
    }
  }, [jsonformState, uischema]);

  useEffect(() => {
    const prefix = codeConfigs.prefix || "";
    const suffix = codeConfigs.suffix || "";
    const codeLength = codeConfigs.codeLength || 0;
    const excludedChars = codeConfigs.excludedChars || "";
    const characterSet = codeConfigs.characterSet || "";
    let validCharacters = [...characterSet]
      .filter((char) => !excludedChars?.includes(char))
      .join("");
    let randomPartLength = codeLength - prefix?.length - suffix?.length;
    let randomPart = "";
    if (randomPartLength > 0 && validCharacters.length > 0) {
      for (let i = 0; i < randomPartLength; i++) {
        let randomIndex = Math.floor(Math.random() * validCharacters.length);
        randomPart += validCharacters[randomIndex];
      }
    }
    let codeParts = [prefix, randomPart, suffix];
    let code = codeParts.filter(Boolean).join("-");
    setGeneratedCode(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetCode]);
  return (
    <div>
      <div className="display-flex justify-content-space-between">
        <h6 style={{ fontSize: "14px", fontWeight: "400" }}>
          Sample Generated Code:
        </h6>
        <Button
          iconTrailing="refresh"
          size="small"
          variant="primaryInverse"
          onClick={() => setResetCode(!resetCode)}
        >
          Refresh
        </Button>
      </div>
      <h6 style={{ fontSize: "24px" }}>{generatedCode || "-"}</h6>
    </div>
  );
};

export const CodeGeneratorControl = withJsonFormsControlProps(
  CodeGeneratorComponent
);
