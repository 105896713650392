import { useMutation } from "@apollo/client";
import _ from "lodash";
import { Button, Modal } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import updateProgramConfiguration from "../../../graphql/mutations/updateProgramConfiguration";
import { paths } from "../../../routes/paths";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import "./app-configurations.scss";

export default function AppConfigurationsForm({ isEditPage }) {
  const jsonSchema = {
    title: "App Configurations",
    type: "object",
    properties: {
      labelHelper: {
        type: "string",
      },
      defaultCodeReaderiOS: {
        type: "string",
        enum: ["camera_scan", "manually_type_in"],
      },
      showCameraScanButtoniOS: {
        type: "string",
        enum: ["yes", "no"],
      },
      defaultCodeReaderAndroid: {
        type: "string",
        enum: ["camera_scan", "manually_type_in"],
      },
      showCameraScanButtonAndroid: {
        type: "string",
        enum: ["yes", "no"],
      },
      showChatbot: {
        type: "string",
        enum: ["yes", "no"],
      },
      chatbotTutorialExpiration: {
        type: "string",
      },
      showYearSummary: {
        type: "string",
        enum: ["yes", "no"],
      },
      contentTitleArea: {
        type: "string",
      },
      isOfferEnabled: {
        type: "string",
        enum: ["yes", "no"],
      },
      applicationIcon: {
        type: "string",
        enum: ["default", "snow"],
      },
      splashScreenAnimation: {
        type: "string",
        enum: ["default", "snow"],
      },
    },
  };

  const uiSchema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Label",
        text: "Code Scan Settings",
      },
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Label",
            text: "iOS Device",
            scope: "#/properties/labelHelper",
            component: "Label",
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/defaultCodeReaderiOS",
                label: "Select default code reader screen*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    camera_scan: "Camera Scan",
                    manually_type_in: "Manually type in",
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/showCameraScanButtoniOS",
                label:
                  "Show the 'Scan Code with Camera' button on Manual reading screen*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    yes: "Yes",
                    no: "No",
                  },
                },
              },
            ],
          },
          {
            type: "Label",
            text: "Android Device",
            scope: "#/properties/labelHelper",
            component: "Label",
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/defaultCodeReaderAndroid",
                label: "Select default code reader screen*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    camera_scan: "Camera Scan",
                    manually_type_in: "Manual reading screen",
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/showCameraScanButtonAndroid",
                label: "Show 'Camera Scan' button on Manual reading screen*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    yes: "Yes",
                    no: "No",
                  },
                },
              },
            ],
          },
        ],
      },
      {
        type: "Label",
        text: "Feature Management Settings",
      },
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/showChatbot",
                label: "Show chatbot on My Profile screen*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    yes: "Yes",
                    no: "No",
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/chatbotTutorialExpiration",
                label: "Chatbot tutorial expiration date*",
                component: "dateTimePicker",
                minimum: "currentDateTime",
                placeholder: "Select",
                rule: {
                  condition: {
                    schema: {
                      gt: "startDate",
                    },
                  },
                  effect: "VALIDATE",
                },
              },
              {
                type: "Control",
                scope: "#/properties/showYearSummary",
                label: "Year Summary*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    yes: "Yes",
                    no: "No",
                  },
                },
              },
            ],
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/contentTitleArea",
                label: "Content Page Title*",
                placeholder: "Type content title",
              },
              {
                type: "Control",
                scope: "#/properties/isOfferEnabled",
                label: "Enable offer*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    yes: "Yes",
                    no: "No",
                  },
                },
              },
            ],
          },
        ],
      },
      {
        type: "Label",
        text: "Splash Screen and App Icon Settings",
      },
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "Label",
            text: "This title will be shown in Content Hub page in the mobile app",
            scope: "#/properties/labelHelper",
            component: "Label",
          },
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/applicationIcon",
                label: "Application icon*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    default: "Default",
                    snow: "Snow",
                  },
                },
              },
              {
                type: "Control",
                scope: "#/properties/splashScreenAnimation",
                label: "Splash Screen Animation*",
                component: "stringSelect",
                placeholder: "Select",
                options: {
                  labelMapping: {
                    default: "Default",
                    snow: "Snow",
                  },
                },
              },
            ],
          },
        ],
      },
    ],
  };

  const [appConfigurations, setAppConfigurations] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [hasEmptyFields, setHasEmptyFields] = useState(true);
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });

  const [updateProgramConfigurationMutation, { loading }] = useMutation(
    updateProgramConfiguration
  );

  const {
    programConfigurations,
    updateProgramConfigurations,
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);

  useEffect(() => {
    const programConfigData = _.find(programConfigurations, {
      key: "app_configurations",
    });
    if (programConfigData !== null && programConfigData !== undefined) {
      setAppConfigurations(
        programConfigData.value?.data === null ? {} : programConfigData.value?.data
      );
    }
  }, [programConfigurations]);

  useEffect(() => {
    setHasEmptyFields(_.some(appConfigurations, (value) => _.isEmpty(value)));
    if (_.some(appConfigurations, (value) => _.isEmpty(value))) {
      openWarningToast();
    }
  }, [appConfigurations]);

  const handleSaveConfirmation = async () => {
    const params = {
      variables: {
        input: {
          slug: "app_configurations",
          title: "App Configurations",
          description: "Sample description",
          data: JSON.stringify(appConfigurations),
        },
        id: programConfig?.id,
      },
    };

    try {
      const res = await updateProgramConfigurationMutation(params);
      if (res?.data?.updateProgramConfiguration) {
        updateProgramConfigurations(
          res?.data?.updateProgramConfiguration?.configurations
        );
        openSuccessToast();
      }
    } catch {
      openErrorToast();
    }

    setConfirmationModalOpen(false);
  };

  const openSuccessToast = () => {
    setToast({
      open: true,
      type: "success",
      title: "Settings updated successfully!",
      description: "You have just saved the user limit settings",
      navigateToSettings: true,
    });
  };

  const openWarningToast = () => {
    setToast({
      open: true,
      type: "warning",
      title: "Fill all mandatory fields",
    });
  };

  const openErrorToast = () => {
    setToast({
      open: true,
      type: "error",
      title: "Something went wrong while saving the user limit settings.",
      description: "Try again later.",
    });
  };

  const navigate = useNavigate();

  const navigateToSystemSettings = () => {
    navigate(`/${paths.system_setting}`);
  };

  return (
    <div className="container appConfigurationsForm">
      <div className="wrapper">
        <JsonFormsWrapper
          data={appConfigurations}
          onChange={(data) => setAppConfigurations(data)}
          uischema={uiSchema}
          jsonschema={jsonSchema}
        />
        <div className="button-container">
          <Button
            size="medium"
            text="Discard"
            variant="secondary"
            className="mr-2"
            onClick={navigateToSystemSettings}
          />
          <Button
            size="medium"
            text="Save"
            variant="primary"
            disabled={!isEditPage || hasEmptyFields}
            onClick={() => setConfirmationModalOpen(true)}
          />
        </div>

        <Modal
          showModal={confirmationModalOpen}
          onCloseModal={() => setConfirmationModalOpen(false)}
          primaryButtonProps={{
            size: "medium",
            text: "Save",
            variant: "primary",
            disabled: loading,
            onClick: handleSaveConfirmation,
          }}
          secondaryButtonProps={{
            size: "medium",
            text: "Back to settings",
            variant: "secondary",
            onClick: () => setConfirmationModalOpen(false),
          }}
          showTertiaryButton={false}
          showLink={false}
        >
          <div className="wrap">
            <h2>Save this settings?</h2>
            <p>Are you sure you want to update the user limit settings?</p>
          </div>
        </Modal>
        <ToastNotification
          {...toast}
          handleClose={() => {
            setToast({ ...toast, open: false });
            if (toast.navigateToSettings) {
              navigateToSystemSettings();
            }
          }}
        />
      </div>
    </div>
  );
}
