import { withJsonFormsControlProps } from "@jsonforms/react";
import { getContentList } from "../../../graphql/queries/contentQueries";
import { ListingComponentProvider } from "../../common/listingComponents/ListingComponentContext";
import PromoProductSelector from "./PromoProductSelector";

const PromoProductSelectorComponent = ({
  data,
  path,
  handleChange,
  required,
  uischema,
}) => {
  const options = uischema.options || {};
  const defaultFilterSchemas = {
    debouncedFilterSchema: {
      uiSchema: {
        type: "VerticalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/title",
            component: "searchInput",
            placeholder: "Search product",
          },
        ],
      },
      jsonSchema: {
        type: "object",
        properties: {
          title: {
            type: "string",
            title: "Title",
          },
        },
      },
    },
    filterDataSchema: {
      uiSchema: {
        type: "VerticalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/contentData/properties/category",
            component: "listDropdownFilter",
            label: "Category",
          },
        ],
      },
      jsonSchema: {
        type: "object",
        properties: {
          contentData: {
            type: "object",
            properties: {
              category: {
                type: "string",
                title: "Category",
                options: ["SNACKS", "BEVERAGE"],
              },
            },
          },
        },
      },
    },
  };

  const initialFilterData = options.initialFilter || {
    contentDefinitionSlug: "promotional-product-content",
  };
  const filterSchemas = options.filterSchemas || defaultFilterSchemas;
  return (
    <ListingComponentProvider
      query={getContentList}
      initialFilterData={initialFilterData}
      fetchPolicy="cache-first"
      queryKey="listContent"
    >
      <PromoProductSelector
        selectedProductIds={data}
        setSelectedProductIds={(newValue) => handleChange(path, newValue)}
        required={required}
        filterSchemas={filterSchemas}
      />
    </ListingComponentProvider>
  );
};

export const PromoProductSelectorControl = withJsonFormsControlProps(
  PromoProductSelectorComponent
);
