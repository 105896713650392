import { InputItem, LoadingSpinner, Pagination } from "pepsico-ds";
import { useEffect, useState } from "react";
import EmptyImage from "../../assets/widget/create_new_widget_empty.svg";
import { useListPartnersQuery } from "../../hooks/useListPartnersQuery";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import PartnerCard from "./PartnerCard/PartnerCard";
import PartnerListTable from "./PartnerListViewTable/PartnerListTable";
import "./partners.scss";
import { filterJsonSchema, filterUiSchema } from "./schemas/partnerFilters";

const PartnersTab = ({ partnersSearch, setPartnersSearch }) => {
  const [viewType, setViewType] = useState("grid");
  const [mainContentY, setMainContentY] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState({});
  const { data, loading, refetch, page, totalPages } = useListPartnersQuery(
    {
      searchText: partnersSearch,
      active: filterData.active?.[0],
      types: filterData.type,
    },
    currentPage
  );

  const handleResize = () => {
    setMainContentY(
      Math.ceil(
        document.getElementsByClassName("main-content")[0].getBoundingClientRect()
          .top
      )
    );
  };

  const handleSearch = (e) => {
    setPartnersSearch(e?.target?.value);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="page-content">
      <div className="view-filter">
        <div className="search-filter-container">
          <InputItem
            placeholder="Type a search keyword..."
            trailingIcon="search"
            size="medium"
            className="input-search"
            onChange={handleSearch}
            data-testid="partner-search"
          />
          <JsonFormsWrapper
            jsonschema={filterJsonSchema}
            uischema={filterUiSchema}
            data={filterData}
            onChange={(data) => setFilterData(data)}
          />
        </div>
        <ButtonGroupDynamic
          items={[
            {
              icon: "view_module",
              onClick: () => {
                setViewType("grid");
              },
            },
            {
              icon: "view_list",
              onClick: () => {
                setViewType("list");
              },
            },
          ]}
          variant="toggle"
          selectedIndex={viewType === "grid" ? 0 : 1}
        />
      </div>
      <div
        className="main-content"
        style={{
          flex: 20,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${mainContentY}px)`,
        }}
      >
        {!loading ? (
          <>
            {data?.length > 0 ? (
              <>
                {viewType === "grid" ? (
                  <div
                    className="grid-container"
                    style={{ flex: 20, overflowY: "auto", padding: "1rem 1rem 0" }}
                    key="grid"
                  >
                    {data.map((partner) => (
                      <div
                        className="grid-item grid-item-3 grid-item-4"
                        key={partner?.id}
                      >
                        <PartnerCard {...partner} refetch={refetch} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <PartnerListTable partnerList={data} refetch={refetch} />
                )}
                {totalPages > 1 ? (
                  <section className="pagination">
                    <Pagination
                      currentPage={page + 1}
                      onUpdate={(newIndex) => {
                        setCurrentPage(newIndex - 1);
                      }}
                      pageCount={totalPages}
                      size="medium"
                      variant="number"
                    />
                  </section>
                ) : null}
              </>
            ) : (
              <section className="empty-state-container">
                <section className="empty-state">
                  <img src={EmptyImage} alt="No partners was found" />
                  <p>No partners was found</p>
                </section>
              </section>
            )}
          </>
        ) : (
          <LoadingSpinner
            size={{
              height: "45px",
              width: "45px",
            }}
            style={{ margin: "auto", marginTop: "15rem" }}
          />
        )}
      </div>
    </div>
  );
};

export default PartnersTab;
