import { Dialog } from "@mui/material";
import { Button, ToastContainer } from "pepsico-ds";
import { useState } from "react";
import { CODE_GROUP_LIFE_CYCLE_STATUS } from "../../utils/constants";
import "./createCodeGroupModal.scss";
import { useCodeGroupModal } from "./useCodeGroupModal";
/**
 * CodeGroupSetupCloseModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Flag indicating whether the modal should be shown.
 * @param {function} props.setShowModal - Function to set the showModal state.
 * @params {function} props.refetchListing - Function to refetch the listing.
 * @params {Object} props.codeGroupData - The code group data.
 * @params {function} props.setCodeGroupData - Function to set the code group data
 * @params {function} props.setParentToastData - Function to set the parent toast data.
 * @returns {JSX.Element} The CodeGroupSetupCloseModal component.
 */
export default function CodeGroupSetupCloseModal({
  showModal,
  setShowModal,
  refetchListing,
  codeGroupData,
  setParentToastData,
  setCodeGroupData,
}) {
  const { performDraftCodeGroup, validateCodeGroup } =
    useCodeGroupModal(codeGroupData);
  const [modalToast, setModalToast] = useState([]);

  const handleSaveAsDraft = async () => {
    const isValidCodeGroup = validateCodeGroup(CODE_GROUP_LIFE_CYCLE_STATUS.DRAFT);
    if (!isValidCodeGroup.isValid) {
      setModalToast([
        {
          text: isValidCodeGroup.message,
          type: "error",
          id: 1,
        },
      ]);
      return;
    }
    const response = await performDraftCodeGroup();
    if (response.isSuccess) {
      setShowModal((prev) => ({ ...prev, closeConfirmModal: false }));
      setCodeGroupData({});
      setParentToastData([
        {
          text: "Code group saved as draft successfully.",
          type: "success",
          id: 1,
        },
      ]);
      refetchListing();
      return;
    }
    setModalToast([
      {
        text: response.message,
        type: "error",
        id: 1,
      },
    ]);
  };

  return (
    <Dialog
      open={showModal.closeConfirmModal}
      sx={{
        "& .MuiPaper-root": {
          width: "80%",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          padding: "1rem",
          alignItems: "center",
          border: "10px solid var(--Colors-Blue-500, #d7e5f8)",
          background: "var(--layer-color-layer-01, #fbfcfe)",
        },
      }}
      scroll="paper"
    >
      <ToastContainer
        data={modalToast}
        removeToast={() => setModalToast([])}
        showActionIcon
      />
      <h4 style={{ fontSize: "18px", fontWeight: "700" }}>
        You haven’t completed the process. Would you save it as a draft?
      </h4>
      <p style={{ fontSize: "14px", fontWeight: "400" }}>
        The code group will appear as a ‘Draft’ status. You can revise and edit this
        later.
      </p>
      <div className="display-flex align-items-center mt-8 mb-8 gap-4">
        <Button
          variant="secondary"
          size="medium"
          className="justify-content-space-around"
          onClick={() =>
            setShowModal({ showModal, closeConfirmModal: false, setupModal: true })
          }
        >
          Back to editing
        </Button>
        <Button
          variant="primary"
          className="jufstify-content-space-around"
          onClick={handleSaveAsDraft}
        >
          Save as Draft
        </Button>
      </div>
    </Dialog>
  );
}
