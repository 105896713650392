import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { getContentList } from "../../../graphql/queries/contentQueries";

const ContentListViewComponent = ({
  data,
  contentDefinitionSlug,
  label,
  contentKey,
}) => {
  const { data: apiList, loading } = useQuery(getContentList, {
    variables: { filter: { contentDefinitionSlug: contentDefinitionSlug } },
    fetchPolicy: "cache-first",
  });

  const formattedContentList = useMemo(() => {
    const formattedSelectedContent = [];
    const selectedIds =
      data?.[contentKey] && Array.isArray(data?.[contentKey])
        ? data[contentKey]
        : [data[contentKey]].flat();
    if (selectedIds?.length && apiList?.listContent?.length) {
      selectedIds.forEach((id) => {
        const formattedContent = apiList?.listContent?.find(
          (content) => content?.id === id
        );
        if (formattedContent) {
          formattedSelectedContent.push(formattedContent);
        }
      });
      return formattedSelectedContent;
    }
  }, [data, apiList]);
  return (
    <div className="additional-fields-container">
      <div className="field-pair" style={{ flexBasis: "31% !important" }}>
        <span className="field-label">{label}: </span>
      </div>
      <div className="field-pair-two-column">
        {loading ? (
          <CircularProgress />
        ) : (
          <span className="field-value">
            {formattedContentList?.length
              ? formattedContentList
                  ?.map((product) => product?.title || "")
                  .join(", ")
              : "-"}
          </span>
        )}
      </div>
    </div>
  );
};

export default ContentListViewComponent;
