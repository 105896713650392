import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import moment from "moment-timezone";
import { Button } from "pepsico-ds";
import image from "../../assets/default_img.png";

moment.locale("en");

/**
 * ViewWinningRewardsScreenModal component.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.showModal - Flag indicating whether the modal should be shown.
 * @param {function} props.setShowModal - Function to set the showModal state.
 * @param {function} props.showViewModal - Function to set the showModal state.
 * @param {function} props.setSelectedContent - Function to set the showModal state.
 * @param {boolean} props.selectedContent - selectedContent object
 * @returns {JSX.Element} The ViewWinningRewardsScreenModal component.
 */
export default function ViewWinningRewardsScreenModal({
  setShowModal,
  showViewModal,
  setSelectedContent,
  setViewShowModal,
  selectedContent,
}) {
  return (
    <Dialog
      className="createProduct-modal"
      open={showViewModal}
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" direction="row">
          <Grid item xs={10}>
            <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
              {selectedContent?.title} Details
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon
              onClick={() => {
                setSelectedContent({});
                setViewShowModal(false);
              }}
              className="cursor-pointer"
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        {selectedContent?.contentDefinition?.slug !==
          "partner-reward-4-play-content" && (
          <div
            className="mt-3 mb-2 display-flex flex-direction-column"
            style={{
              gap: "16px",
              padding: "12px 48px 12px 16px",
              backgroundColor: "#EFF5FC",
              borderRadius: "5px",
            }}
          >
            <div>
              <span className="font-xs font-bold mr-3">
                Good Choice Success Screen Version
              </span>
              <span className="font-xs $color-text-secondary ml-3">Customized</span>
            </div>
            <div className="display-flex align-items-center" style={{ gap: "5%" }}>
              <div>
                <span className="font-xs font-bold mr-3">
                  Good Choice Screen Title
                </span>
                <span className="font-xs $color-text-secondary ml-3">
                  {selectedContent?.contentData?.screen_title || "-"}
                </span>
              </div>
              {selectedContent?.contentDefinition?.slug !==
                "partner-reward-sodexo-flexogift-tek-marka-content" && (
                <div>
                  <span className="font-xs font-bold mr-3">
                    Good Choice Screen Subtitle
                  </span>
                  <span className="font-xs $color-text-secondary ml-3">
                    {selectedContent?.contentData?.screen_subtitle || "-"}
                  </span>
                </div>
              )}
            </div>
            {selectedContent?.contentDefinition?.slug !==
              "partner-reward-sodexo-flexogift-tek-marka-content" && (
              <div className="display-flex">
                <span className="font-xs font-bold mr-3">Description</span>
                <span className="font-xs $color-text-secondary ml-3">
                  {selectedContent?.contentData?.screen_description || "-"}
                </span>
              </div>
            )}
            <div className="display-flex" style={{ gap: "5%" }}>
              {selectedContent?.contentDefinition?.slug !==
                "partner-reward-sodexo-flexogift-tek-marka-content" && (
                <div className="display-flex">
                  <span className="font-xs font-bold mr-3">Success Image</span>
                  <img
                    src={selectedContent?.contentData?.image_url || image}
                    width={338}
                    height={207}
                  />
                </div>
              )}
              <div className="display-flex">
                <span className="font-xs font-bold mr-3">Action Button Text</span>
                <span className="font-xs $color-text-secondary ml-3">
                  {selectedContent?.contentData?.action_button_text || "-"}
                </span>
              </div>
            </div>
          </div>
        )}
        {selectedContent?.contentDefinition?.slug !==
          "partner-reward-hopi-content" && (
          <div
            className="mt-3 mb-2 display-flex flex-direction-column"
            style={{
              gap: "16px",
              padding: "12px 48px 12px 16px",
              backgroundColor: "#EFF5FC",
              borderRadius: "5px",
            }}
          >
            <div>
              <span className="font-xs font-bold mr-3">Info Screen Version</span>
              <span className="font-xs $color-text-secondary ml-3">Customized</span>
            </div>
            <div>
              <span className="font-xs font-bold mr-3">Info Screen Title</span>
              <span className="font-xs $color-text-secondary ml-3">
                {selectedContent?.contentData?.info_screen_title || "-"}
              </span>
            </div>
            <div className="display-flex">
              <span className="font-xs font-bold mr-3">Description</span>
              <span className="font-xs $color-text-secondary ml-3">
                {selectedContent?.contentData?.info_screen_description || "-"}
              </span>
            </div>
            {selectedContent?.contentDefinition?.slug ===
              "partner-reward-4-play-content" && (
              <div className="display-flex">
                <span className="font-xs font-bold mr-3">Display Info Icon</span>
                <span className="font-xs $color-text-secondary ml-3">
                  {selectedContent?.contentData?.display_info_icon ? "Yes" : "No"}
                </span>
              </div>
            )}
          </div>
        )}
        {selectedContent?.contentDefinition?.slug !==
          "partner-reward-4-play-content" && (
          <div
            className="mt-3 mb-2 display-flex flex-direction-column"
            style={{
              gap: "16px",
              padding: "12px 48px 12px 16px",
              backgroundColor: "#EFF5FC",
              borderRadius: "5px",
            }}
          >
            <div>
              <span className="font-xs font-bold mr-3">Partner App Link</span>
              <span className="font-xs $color-text-secondary ml-3">Customized</span>
            </div>
            <div>
              <span className="font-xs font-bold mr-3">
                Prod App Android app ID{" "}
              </span>
              <span className="font-xs $color-text-secondary ml-3">
                {selectedContent?.contentData?.android_app_id || "-"}
              </span>
            </div>
            <div>
              <span className="font-xs font-bold mr-3">iOS App URL Schema </span>
              <span className="font-xs $color-text-secondary ml-3">
                {selectedContent?.contentData?.ios_app_url_Schema || "-"}
              </span>
            </div>
          </div>
        )}
        <div className="mt-5 display-flex justify-content-end align-items-center">
          <Button
            variant="primary"
            className="justify-content-space-around"
            onClick={() => {
              setViewShowModal(false);
              setShowModal(true);
            }}
          >
            Edit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
