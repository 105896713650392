import { gql } from "@apollo/client";

const getPartnerCodeGroups = gql`
  query ListPartnerCodeGroups(
    $filter: PartnerCodeGroupFilter
    $page: Int
    $size: Int
  ) {
    listPartnerCodeGroups(filter: $filter, page: $page, size: $size) {
      total
      self
      items {
        id
        name
        description
        partnerId
        programId
        codeWarningLimitPercentage
        isActive
        startDate
        endDate
        codeCount
        exhaustedCode
        isImported
        importedDate
        rewardItemFileData {
          id
          name
          size
          type
          url
          metadata {
            description
            tags
            uploadedBy
            uploadedAt
            containerName
          }
        }
      }
    }
  }
`;

export default getPartnerCodeGroups;
