import {
  CODE_GROUP_ADMINISTRATION_STATUS,
  CODE_GROUP_LIFE_CYCLE_STATUS,
  PRODUCER_COMPANIES,
} from "../../utils/constants";

export const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      className: "modal-container",
      elements: [
        {
          child_class_name: "basic-setup",
          type: "VerticalLayout",
          elements: [
            {
              type: "HorizontalLayout",
              label: "Basic Setup",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/codeGroupName",
                },
                {
                  type: "Control",
                  scope: "#/properties/codeActiveStartDate",
                  minimum: "currentDateTime",
                  component: "dateTimePicker",
                  label: "Code Active Dates",
                },
                {
                  type: "Control",
                  scope: "#/properties/codeActiveEndDate",
                  component: "dateTimePicker",
                  rule: {
                    condition: {
                      schema: {
                        gt: "codeActiveStartDate",
                      },
                    },
                    effect: "VALIDATE",
                  },
                },
              ],
            },
            {
              type: "VerticalLayout",
              label: "Producer Packaging",
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/brand",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/producerCompany",
                      component: "muiSelect",
                      label: "Producer Company",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/codesPrintedCount",
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/productVolume",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/packageType",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/productCapColor",
                    },
                  ],
                },
              ],
            },
            {
              type: "HorizontalLayout",
              label: "Dummy Codes",
              className: "dummy-codes-container",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/allowDummyCodes",
                      component: "toggle",
                      size: "small",
                      label: "Create Dummy Codes",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/dummyCodeMessage",
                      label: "Dummy Code",
                      maxLength: "10",
                      includedChars: {
                        key: "characterSet",
                      },
                      rule: {
                        effect: "ENABLE",
                        condition: {
                          schema: { const: true },
                          scope: "#/properties/allowDummyCodes",
                        },
                      },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/dummyCodePercent",
                      component: "muiSlider",
                      displayType: "percentage",
                      label: "Possibility of getting Dummy Codes",
                      rule: {
                        effect: "ENABLE",
                        condition: {
                          schema: { const: true },
                          scope: "#/properties/allowDummyCodes",
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          child_class_name: "code-specifications-wrapper",
          type: "SimpleGroup",
          label: "Code Specifications",
          elements: [
            {
              type: "Control",
              scope: "#/properties/characterSet",
              readOnly: true,
            },
            {
              type: "Control",
              component: "muiSlider",
              scope: "#/properties/codeLength",
              label: "Fixed Code Length",
              displayType: "characters",
              hideMarks: true,
              disabled: true,
            },
            {
              type: "HorizontalLayout",
              className: "align-items-center",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/usePrefix",
                  component: "checkbox",
                },
                {
                  type: "Control",
                  scope: "#/properties/prefixVal",
                  showLabel: false,
                  maxLength: 10,
                  placeholder: "E.g) U",
                  component: "prefixSuffixControl",
                  isPrefixVal: true,
                  rule: {
                    effect: "ENABLE",
                    condition: {
                      schema: { const: true },
                      scope: "#/properties/usePrefix",
                    },
                  },
                },
              ],
            },
            {
              type: "HorizontalLayout",
              className: "align-items-center",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/useSuffix",
                  component: "checkbox",
                },
                {
                  type: "Control",
                  scope: "#/properties/suffixVal",
                  maxLength: 10,
                  showLabel: false,
                  placeholder: "E.g) 485",
                  component: "prefixSuffixControl",
                  isPrefixVal: false,
                  rule: {
                    effect: "ENABLE",
                    condition: {
                      schema: { const: true },
                      scope: "#/properties/useSuffix",
                    },
                  },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/caseSensitive",
              component: "checkbox",
            },
            {
              type: "Control",
              component: "codeGenerator",
              scope: "#/properties/codeGenerator",
            },
            {
              type: "VerticalLayout",
              className: "mt-3",
              layoutStyles: { marginTop: "20px" },
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/excludeCharacters",
                  component: "toggle",
                  label: "Exclude characters from code",
                },
                {
                  type: "Control",
                  scope: "#/properties/excludeCharacterValues",
                  showLabel: false,
                  placeholder: "i.e.: XDRT2345",
                  includedChars: {
                    key: "characterSet",
                    isUnique: true,
                  },
                  rule: {
                    effect: "ENABLE",
                    condition: {
                      schema: { const: true },
                      scope: "#/properties/excludeCharacters",
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const restrictedUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      className: "modal-container",
      elements: [
        {
          child_class_name: "basic-setup",
          type: "VerticalLayout",
          elements: [
            {
              type: "HorizontalLayout",
              label: "Basic Setup",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/codeGroupName",
                },
                {
                  type: "Control",
                  scope: "#/properties/codeActiveStartDate",
                  minimum: "currentDateTime",
                  component: "dateTimePicker",
                  label: "Code Active Dates",
                },
                {
                  type: "Control",
                  scope: "#/properties/codeActiveEndDate",
                  component: "dateTimePicker",
                },
              ],
            },
            {
              type: "VerticalLayout",
              label: "Producer Packaging",
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/brand",
                      readOnly: true,
                    },
                    {
                      type: "Control",
                      scope: "#/properties/producerCompany",
                      component: "muiSelect",
                      label: "Producer Company",
                      readOnly: true,
                    },
                    {
                      type: "Control",
                      scope: "#/properties/codesPrintedCount",
                      readOnly: true,
                    },
                  ],
                },
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/productVolume",
                      readOnly: true,
                    },
                    {
                      type: "Control",
                      scope: "#/properties/packageType",
                      readOnly: true,
                    },
                    {
                      type: "Control",
                      scope: "#/properties/productCapColor",
                      readOnly: true,
                    },
                  ],
                },
              ],
            },
            {
              type: "HorizontalLayout",
              label: "Dummy Codes",
              className: "dummy-codes-container",
              elements: [
                {
                  type: "VerticalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/allowDummyCodes",
                      component: "toggle",
                      size: "small",
                      label: "Create Dummy Codes",
                      readOnly: true,
                    },
                    {
                      type: "Control",
                      scope: "#/properties/dummyCodeMessage",
                      maxLength: "10",
                      readOnly: true,
                      includedChars: {
                        key: "characterSet",
                      },
                      rule: {
                        effect: "ENABLE",
                        condition: {
                          schema: { const: true },
                          scope: "#/properties/allowDummyCodes",
                        },
                      },
                    },
                    {
                      type: "Control",
                      scope: "#/properties/dummyCodePercent",
                      component: "muiSlider",
                      displayType: "percentage",
                      disabled: true,
                      label: "Possibility of getting Dummy Codes",
                      rule: {
                        effect: "ENABLE",
                        condition: {
                          schema: { const: true },
                          scope: "#/properties/allowDummyCodes",
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          child_class_name: "code-specifications-wrapper",
          type: "SimpleGroup",
          label: "Code Specifications",
          elements: [
            {
              type: "Control",
              scope: "#/properties/characterSet",
              readOnly: true,
            },
            {
              type: "Control",
              component: "muiSlider",
              scope: "#/properties/codeLength",
              label: "Fixed Code Length",
              displayType: "characters",
              hideMarks: true,
              disabled: true,
            },
            {
              type: "HorizontalLayout",
              className: "align-items-center",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/usePrefix",
                  component: "checkbox",
                  readOnly: true,
                },
                {
                  type: "Control",
                  scope: "#/properties/prefixVal",
                  showLabel: false,
                  readOnly: true,
                  maxLength: 10,
                  placeholder: "E.g) U",
                  component: "prefixSuffixControl",
                  isPrefixVal: true,
                  rule: {
                    effect: "ENABLE",
                    condition: {
                      schema: { const: true },
                      scope: "#/properties/usePrefix",
                    },
                  },
                },
              ],
            },
            {
              type: "HorizontalLayout",
              className: "align-items-center",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/useSuffix",
                  component: "checkbox",
                  readOnly: true,
                },
                {
                  type: "Control",
                  scope: "#/properties/suffixVal",
                  maxLength: 10,
                  showLabel: false,
                  placeholder: "E.g) 485",
                  component: "prefixSuffixControl",
                  readOnly: true,
                  isPrefixVal: false,
                  rule: {
                    effect: "ENABLE",
                    condition: {
                      schema: { const: true },
                      scope: "#/properties/useSuffix",
                    },
                  },
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/caseSensitive",
              component: "checkbox",
              readOnly: true,
            },
            {
              type: "Control",
              component: "codeGenerator",
              scope: "#/properties/codeGenerator",
            },
            {
              type: "VerticalLayout",
              className: "mt-3",
              layoutStyles: { marginTop: "20px" },
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/excludeCharacters",
                  component: "toggle",
                  label: "Exclude characters from code",
                  readOnly: true,
                },
                {
                  type: "Control",
                  scope: "#/properties/excludeCharacterValues",
                  showLabel: false,
                  readOnly: true,
                  placeholder: "i.e.: XDRT2345",
                  includedChars: {
                    key: "characterSet",
                    isUnique: true,
                  },
                  rule: {
                    effect: "ENABLE",
                    condition: {
                      schema: { const: true },
                      scope: "#/properties/excludeCharacters",
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const jsonSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    lifecycleStatus: {
      type: "string",
      enum: Object.values(CODE_GROUP_LIFE_CYCLE_STATUS),
    },
    administrationStatus: {
      type: "string",
      enum: Object.values(CODE_GROUP_ADMINISTRATION_STATUS),
      const: CODE_GROUP_ADMINISTRATION_STATUS.ENABLED,
    },
    characterSet: { type: "string", const: "13456789ACDEFHKLMNPQRTVWXYZ" },
    codeGroupGenerator: { type: "string" },
    codeGroupName: { type: "string" },
    codeLength: { type: "integer", const: 10 },
    codeActiveStartDate: { type: "string" },
    codeActiveEndDate: { type: "string" },
    brand: { type: "string" },
    producerCompany: {
      type: "string",
      enum: Object.values(PRODUCER_COMPANIES),
    },
    codesPrintedCount: { type: "integer" },
    productVolume: { type: "number" },
    packageType: { type: "string" },
    productCapColor: { type: "string" },
    allowDummyCodes: { type: "boolean", const: false },
    dummyCodeMessage: { type: "string" },
    dummyCodePercent: { type: "number" },
    usePrefix: { type: "boolean", const: false },
    prefixVal: { type: "string", maxLength: 10 },
    useSuffix: { type: "boolean", const: false },
    suffixVal: { type: "string", maxLength: 10 },
    caseSensitive: { type: "boolean", const: false },
    excludeCharacters: { type: "boolean", const: false },
    excludeCharacterValues: { type: "string" },
  },
  required: [
    "codeGroupName",
    "codeActiveStartDate",
    "codeActiveEndDate",
    "producerCompany",
    "codesPrintedCount",
    "productVolume",
    "packageType",
    "productCapColor",
    "allowDummyCodes",
    "usePrefix",
    "useSuffix",
    "caseSensitive",
    "excludeCharacters",
  ],
};

export const getInitialCodeGroupData = (jsonSchema) => {
  // get it based on jsonSchema. map all values which has const value
  const codeGroupData = {
    allowDummyCodes: jsonSchema.properties.allowDummyCodes.const,
    characterSet: jsonSchema.properties.characterSet.const,
    usePrefix: jsonSchema.properties.usePrefix.const,
    useSuffix: jsonSchema.properties.useSuffix.const,
    codeLength: jsonSchema.properties.codeLength.const,
    caseSensitive: jsonSchema.properties.caseSensitive.const,
    excludeCharacters: jsonSchema.properties.excludeCharacters.const,
    administrationStatus: CODE_GROUP_ADMINISTRATION_STATUS.ENABLED,
    lifecycleStatus: null,
  };
  return codeGroupData;
};

export const generateCodeGroupData = (codeGroup) => {
  // flatten the object
  const { basicSetup, producerPackaging, dummyCodes, codeSpecifications, ...rest } =
    codeGroup;

  const updatedCodeGroup = {
    ...rest,
    ...basicSetup,
    ...producerPackaging,
    ...dummyCodes,
    ...codeSpecifications,
    characterSet: jsonSchema.properties.characterSet.const,
  };
  return updatedCodeGroup;
};
