/* eslint-disable no-unused-vars */
import { Icon, TextInput, Tooltip } from "pepsico-ds";
import { useState } from "react";
import "./NumberInputField.scss";

export default function NumberInputField(props) {
  const { value, updateValue, label, errors, schema, uiSchema, required, disabled } =
    props;

  const [forceRerender, setForceRerender] = useState(true);

  const handleUpdateValue = (...args) => {
    updateValue(...args);
    setForceRerender(false);
  };

  return (
    <>
      <div className="textinput-wrapper">
        {uiSchema.tooltipText && (
          <div className="tooltip-wrapper">
            <span>{label}</span>
            <Tooltip
              direction={uiSchema.tooltipDirection ?? "right"}
              text={uiSchema.tooltipText}
            >
              <Icon
                alt=""
                className="$color-icon-default"
                icon={uiSchema.tooltipIcon ?? "info"}
                size="medium"
                variant="outlined"
              />
            </Tooltip>
          </div>
        )}

        <TextInput
          /* error={!!errors} */
          key={forceRerender === true ? value : undefined}
          required={required}
          disabled={disabled}
          type="number"
          maxLength={uiSchema.maxLength ?? ""}
          label={!uiSchema.tooltipText ? label : ""}
          onUpdate={handleUpdateValue}
          value={parseInt(value)}
          placeholderText={uiSchema.placeholder ?? ""}
        />
      </div>
    </>
  );
}
