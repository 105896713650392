import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react";
import axios from "axios";
import { File } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";

const PartnerFileUploadComponent = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    uischema,
    required,
    visible,
    enabled = true,
  } = props;

  const accept = uischema?.accept?.toString() || ".txt, .csv";
  const description = uischema?.description || "Select a file";
  const [variant, setVariant] = useState("edit");
  const [errorMessage, setErrorMessage] = useState("");
  const [rewardItemFileData, setRewardItemFileData] = useState(data || []);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const { globalState } = useContext(GlobalConfigContext);

  const ctx = useJsonForms();
  const jsonformState = ctx.core.data;
  const [disabled, setDisabled] = useState(false);

  const programId =
    globalState?.programConfig?.id || "018a2bd5-fdc2-77ce-9367-7d700186d5ae";
  const accessToken = JSON.parse(localStorage.getItem("okta-token-storage"))
    ?.accessToken?.accessToken;

  const handleFileChange = (file) => {
    if (["text/plain", "text/csv"].includes(file.type)) {
      setErrorMessage("");
      uploadFile(file);
    } else {
      setErrorMessage("Invalid file type");
    }
  };

  const uploadFile = async (file) => {
    setVariant("uploading");
    setIsUploading(true);

    const formData = new FormData();
    formData.append("file", file);

    try {
      const uploadUrl = window.REWARD_PARTNER_GROUP_FILE_UPLOAD_BASE_URI;
      const partnerId = localStorage.getItem("partnerId"); // used localStorage since <File/> Component reset the state
      const endpoint = `${uploadUrl}/api/v1/rewardsfilemanager/files/upload?program_id=${programId}&partner_id=${partnerId}`;

      const response = await axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      });

      const uploadedFileData = response.data.map((currentFile) => ({
        id: currentFile.id,
        name: currentFile.name,
        size: currentFile.size,
        type: currentFile.type,
        url: null,
        metadata: {
          description: currentFile.metadata.description,
          tags: currentFile.metadata.tags,
          uploadedBy: currentFile.metadata.uploaded_by,
          uploadedAt: currentFile.metadata.uploaded_at,
          containerName: currentFile.metadata.container_name,
        },
      }));

      setRewardItemFileData((prev) => [...prev, ...uploadedFileData]);
      setVariant("edit");
    } catch (error) {
      const errMsg =
        error?.response?.data?.extension?.issues[0]?.detail ||
        error?.response?.data?.extension?.issues?.detail ||
        "Something went wrong";
      setErrorMessage("Upload error: " + errMsg);
      setVariant("edit");
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteFile = (fileIndex) => {
    setRewardItemFileData((prev) => prev.filter((_, index) => index !== fileIndex));
  };

  useEffect(() => {
    handleChange(path, rewardItemFileData);
  }, [rewardItemFileData]);

  useEffect(() => {
    if (jsonformState?.partnerId) {
      localStorage.setItem("partnerId", jsonformState?.partnerId);
      setDisabled(false);
      setErrorMessage("");
    } else {
      setDisabled(true);
      setRewardItemFileData([]);
      setErrorMessage("Please select a partner to upload files");
    }
  }, [jsonformState?.partnerId]);

  // key is used to reset the component when the error message changes, since the component is not re-rendered when the error message changes
  return visible ? (
    <>
      <File
        key={errorMessage}
        data-testid="image-uploader-file"
        accept={accept}
        description={description}
        multiple
        buttonLabel="Upload files"
        onUpdate={handleFileChange}
        variant={variant}
        onDelete={handleDeleteFile}
        files={rewardItemFileData.map((file) => file.name)}
        disabled={isUploading || !enabled || disabled}
        progressPerc={uploadProgress}
        progressLabel="Uploading"
        label={label}
        required={required}
      />
      {errorMessage && <p style={{ color: "red", fontSize: 12 }}>{errorMessage}</p>}
    </>
  ) : null;
};

export const PartnerFileUploadControl = withJsonFormsControlProps(
  PartnerFileUploadComponent
);
