import { useQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { Button, InputItem, Pagination } from "pepsico-ds";
import { useEffect, useState } from "react";
import { matchKeywordGroupsList } from "../../../../graphql/queries/listKeywordGroups";
import ButtonGroupDynamic from "../../../common/ButtonGroupDynamic";
import KeywordsGridView from "./KeywordsGridView";
import KeywordsTable from "./KeywordsTable";

const MatchKeywordsComponent = ({
  selectedIds,
  setSelectedIds,
  filterBy,
  required,
}) => {
  const [filter, setFilter] = useState({ searchTerm: "" });
  const [viewType, setViewType] = useState("grid");
  const [selectedKeywordGroupList, setSelectedKeywordGroupList] = useState([]);
  const [keywordGroupsList, setKeywordGroupsList] = useState([]);
  const { data, loading } = useQuery(matchKeywordGroupsList, {
    variables: { type: filterBy },
    fetchPolicy: "cache-first",
  });
  const [pagination, setPagination] = useState({
    recordsPerPage: 10,
    currentPage: 1,
  });

  const mapRuleDataToState = () => {
    const formattedSelectedGroups = [];
    if (selectedIds?.length && data?.listKeywordGroups?.length) {
      const formatListKeywordGroupdsData = {};
      data?.listKeywordGroups?.forEach((keywordGroup) => {
        formatListKeywordGroupdsData[keywordGroup.id] = keywordGroup;
      });
      selectedIds.forEach((id) => {
        const formattedGroup = formatListKeywordGroupdsData[id];
        if (formattedGroup) {
          formattedSelectedGroups.push(formattedGroup);
        }
      });
      setSelectedKeywordGroupList(formattedSelectedGroups);
    }
  };

  const mapStateToRuleData = () => {
    let updatedIdList = [];
    if (selectedKeywordGroupList.length) {
      updatedIdList = selectedKeywordGroupList.map(
        (keywordGroup) => keywordGroup.id
      );
    }
    setSelectedIds(updatedIdList);
  };

  useEffect(() => {
    mapRuleDataToState();
    if (data?.listKeywordGroups.length) {
      setKeywordGroupsList(data?.listKeywordGroups);
    }
  }, [data]);

  useEffect(() => {
    mapStateToRuleData();
  }, [selectedKeywordGroupList]);

  const onFilterChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
    const allKeywordsGroupsList = JSON.parse(
      JSON.stringify(data?.listKeywordGroups)
    );
    if (value === "") {
      setKeywordGroupsList(allKeywordsGroupsList);
    } else {
      setKeywordGroupsList(
        allKeywordsGroupsList.filter((keywordGroup) =>
          keywordGroup.name.toLowerCase().includes(value.toLowerCase())
        )
      );
    }
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
  };

  const onRemoveKeywordGroup = (selectedBrand) => {
    const newData = selectedKeywordGroupList.filter(
      (item) => item.id !== selectedBrand.id
    );
    setSelectedKeywordGroupList(newData);
  };

  const onSelectKeywordGroup = (keywordGroup) => {
    let selectedRows = selectedKeywordGroupList
      ? JSON.parse(JSON.stringify(selectedKeywordGroupList))
      : [];
    const index = selectedRows?.findIndex((item) => item.id === keywordGroup.id);
    if (index > -1) {
      selectedRows = selectedRows?.filter((item) => item.id !== keywordGroup.id);
    } else {
      selectedRows = [...selectedRows, keywordGroup];
    }
    setSelectedKeywordGroupList(selectedRows);
  };

  const getPaginatedKeywordsList = () => {
    const start = (pagination.currentPage - 1) * pagination.recordsPerPage;
    const end = start + pagination.recordsPerPage;
    return keywordGroupsList.slice(start, end);
  };

  return (
    <div>
      <div className="display-flex mb-8" style={{ width: "75%" }}>
        <h5
          style={{
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "23.8px",
            textAlign: "left",
          }}
        >
          {filterBy === "BRAND"
            ? "BRANDS"
            : filterBy === "PRODUCT"
              ? "SKU/PRODUCTS"
              : "RETAILERS"}{" "}
        </h5>
        {required && (
          <span className="required-text $font-xxsmall-bold">*Required</span>
        )}
      </div>
      <div className="display-flex">
        <div style={{ width: "75%" }}>
          <div
            style={{
              padding: "0px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "16px",
              marginBottom: "0.85rem",
              flex: 0.2,
            }}
          >
            <InputItem
              placeholder={
                filterBy === "BRAND"
                  ? "Search by Brand Name"
                  : filterBy === "PRODUCT"
                    ? "Search by SKU/Product Name"
                    : "Search by Retailer Name"
              }
              trailingIcon="search"
              size="medium"
              value={filter.searchTerm}
              style={{ width: "50%" }}
              className="input-search"
              onChange={(e) => onFilterChange("searchTerm", e.target.value)}
            />
            <ButtonGroupDynamic
              items={[
                {
                  icon: "view_module",
                  onClick: () => {
                    setViewType("grid");
                  },
                },
                {
                  icon: "view_list",
                  onClick: () => {
                    setViewType("list");
                  },
                },
              ]}
              size="medium"
              spacing="2px"
              variant="toggle"
              selectedIndex={viewType === "grid" ? 0 : 1}
            />
          </div>
          {!loading ? (
            <div
              className="grid-container"
              style={{ flex: 20, overflowY: "auto", justifyContent: "center" }}
            >
              {viewType === "grid" ? (
                <KeywordsGridView
                  filterBy={filterBy}
                  keywordGroupsListData={getPaginatedKeywordsList()}
                  selectedKeywordGroupList={selectedKeywordGroupList}
                  loading={loading}
                  onSelectKeywordGroup={(newKeywordGroup) =>
                    onSelectKeywordGroup(newKeywordGroup)
                  }
                />
              ) : (
                <KeywordsTable
                  filterBy={filterBy}
                  keywordGroupsListData={getPaginatedKeywordsList()}
                  selectedKeywordGroupList={selectedKeywordGroupList}
                  loading={loading}
                  onSelectKeywordGroup={(newKeywordGroup) =>
                    onSelectKeywordGroup(newKeywordGroup)
                  }
                />
              )}
              <Pagination
                currentPage={pagination.currentPage}
                onUpdate={(page) => {
                  setPagination((prev) => ({ ...prev, currentPage: page }));
                }}
                pageCount={Math.ceil(
                  keywordGroupsList.length / pagination.recordsPerPage
                )}
                size="medium"
                variant="number"
              />
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div
          style={{
            width: "25%",
            padding: "0.5rem",
            backgroundColor: "#F3F7FD",
            marginLeft: "1%",
          }}
        >
          <div className="display-flex justify-content-space-between align-items-center">
            <span style={{ fontWeight: "700" }}>
              {selectedKeywordGroupList?.length || 0} selected
            </span>
            <Button
              variant="primaryInverse"
              onClick={() => setSelectedKeywordGroupList([])}
              style={{ padding: "0px" }}
            >
              Remove all
            </Button>
          </div>
          <div className="display-flex gap-2 mt-16" style={{ flexWrap: "wrap" }}>
            {selectedKeywordGroupList?.map((item, index) => (
              <Chip
                key={index}
                id={`brand-${item?.name}`}
                className="font-xs"
                label={item.name}
                deleteIcon={
                  <CloseIcon
                    sx={{
                      color: "#3A3A3A !important",
                    }}
                  />
                }
                sx={{
                  borderRadius: "8px",
                  backgroundColor: "#B3DACB",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#B3DACB",
                  },
                }}
                onDelete={() => onRemoveKeywordGroup(item)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchKeywordsComponent;
