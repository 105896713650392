import { useMutation, useQuery } from "@apollo/client";
import { Chip } from "@mui/material";
import { Breadcrumb, Button, LoadingSpinner } from "pepsico-ds";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextAreaField from "../../components/jsonForms/textarea/TextAreaField";
import CreatePermissionsModal from "../../components/management/TermAndCondition/CreatePermissionsModal";
import { paths } from "../../routes/paths";
import saveBanner from "../banners/graphQl/mutations/saveBanner";
import loadContent from "../banners/graphQl/queries/loadContent";
import "./../../styles/common.scss";
import "./ManagementPage.scss";

export default function TermAndConditionEditPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dataReceived = location.state;
  const [saveBannerMutation] = useMutation(saveBanner);
  const [load, setLoad] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedPermission, setSelectedPermission] = useState(null);

  const [state, setState] = useState({
    content: {
      id: dataReceived?.id,
      title: dataReceived?.title,
      description: dataReceived?.description,
      activeStartDate: dataReceived?.activeStartDate,
      segments: "",
      tags: [],
      category: "",
      paused: false,
      contentDefinitionSlug: "terms-and-conditions-content",
      contentData: dataReceived?.contentData,
    },
  });

  const initialFilter = {
    id: dataReceived?.id,
  };
  const { data, loading } = useQuery(loadContent, {
    variables: initialFilter,
  });

  const [formDataPermission, setFormDataPermission] = useState({
    id: "",
    name: "",
    text: "",
  });

  useEffect(() => {
    if (!dataReceived) {
      navigate(`/${paths.system_setting}/${paths.term_and_condition}`);
    }
    if (data && !loading) {
      setState((prevState) => ({
        ...prevState,
        content: {
          ...prevState.content,
          contentData: {
            ...prevState.content.contentData,
            text: data?.loadContent?.contentData?.text,
          },
        },
      }));
    }
  }, [data, loading, dataReceived]);

  const handleEditPermission = (permission) => {
    setFormDataPermission(permission);
    setSelectedPermission(permission);
    setShowCreateModal(true);
  };

  const handleDeletePermission = (permissionId) => {
    // Implement delete functionality
    setState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        contentData: {
          ...prevState.content.contentData,
          permissions: prevState.content.contentData.permissions.filter(
            (permission) => permission.id !== permissionId
          ),
        },
      },
    }));
  };

  const onChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        contentData: {
          ...prevState.content.contentData,
          text: value,
        },
      },
    }));
  };

  const onChangeTitle = (value) => {
    setState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        contentData: {
          ...prevState.content.contentData,
          title: value,
        },
      },
    }));
  };

  const onCLickUpdate = async () => {
    try {
      const params = {
        variables: state,
      };
      setLoad(true);
      const save = await saveBannerMutation(params);
      if (save?.data) {
        setLoad(false);
        navigate(`/${paths.system_setting}/${paths.term_and_condition}`);
      }
    } catch (err) {
      alert(err);
    }
  };

  const handleAddPermission = () => {
    setSelectedPermission(null);

    setShowCreateModal(false);
    // Implement add functionality
    // setState((prevState) => ({
    //   ...prevState,
    //   content: {
    //     ...prevState.content,
    //     contentData: {
    //       ...prevState.content.contentData,
    //       permissions: [
    //         ...(prevState.content.contentData.permissions || []),
    //         formDataPermission,
    //       ],
    //     },
    //   },
    // }));

    setState((prevState) => {
      const permissions = prevState.content.contentData.permissions || [];
      const permissionIndex = permissions.findIndex(
        (permission) => permission.id === selectedPermission?.id
      );

      let updatedPermissions;
      if (permissionIndex !== -1) {
        // Update existing permission
        updatedPermissions = permissions.map((permission, index) =>
          index === permissionIndex ? formDataPermission : permission
        );
      } else {
        // Add new permission
        updatedPermissions = [...permissions, formDataPermission];
      }

      return {
        ...prevState,
        content: {
          ...prevState.content,
          contentData: {
            ...prevState.content.contentData,
            permissions: updatedPermissions,
          },
        },
      };
    });
    setFormDataPermission({ id: "", name: "", text: "" });
  };

  const onCLickClose = () => {
    navigate(`/${paths.system_setting}/${paths.term_and_condition}`);
  };
  return (
    <div className="container">
      <div className="wrap">
        <Breadcrumb
          breadcrumbs={[
            {
              label: "System Setting",
              href: `../../${paths.system_setting}`,
            },
            {
              label: "Terms and Conditions",
              href: `../../${paths.system_setting}/${paths.term_and_condition}`,
            },
            { label: `Edit ${dataReceived?.title}` },
          ]}
          activeIndex={2}
        />
        {dataReceived?.contentData?.is_required && (
          <Chip className="required-chip" size="small" label="Required to accept" />
        )}
        <h2>{dataReceived?.title}</h2>
        <p>
          {state?.content?.contentData?.text.length === 0 && (
            <span style={{ color: "red" }}>Please enter Terms and Conditions</span>
          )}
          <TextAreaField
            value={state?.content?.contentData?.title ?? ""}
            updateValue={(newVal) => onChangeTitle(newVal)}
            placeholder="Type here"
            minRows={2}
            label="Title"
            required={true}
          />

          <TextAreaField
            value={state?.content?.contentData?.text ?? ""}
            updateValue={(newVal) => onChange(newVal)}
            placeholder="Type here"
            minRows={6}
            label="Text"
            required={true}
          />
        </p>
        {/* <hr className="custom-hr" /> */}

        {state?.content?.contentData?.permissions &&
          state?.content?.contentData?.permissions.length > 0 && (
            <div style={{ maxHeight: "350px", overflow: "scroll" }}>
              <div className="permission-actions">
                <h4>Communication Preferences </h4>
              </div>
              <div className="permissions-container">
                {state?.content?.contentData?.permissions?.map((permission) => (
                  <div key={permission.id} className="permission-item">
                    <strong style={{ lineHeight: "10px" }}>{permission?.id}</strong>
                    <p>
                      <span className="title">Title : </span>
                      <span className="content">{permission?.name}</span>
                    </p>
                    <p>
                      <span className="title">Description :</span>
                      <span className="content"> {permission?.text}</span>
                    </p>

                    <div className="permission-actions">
                      <Button
                        onClick={() => handleEditPermission(permission)}
                        size="small"
                      >
                        Edit
                      </Button>
                      {!permission?.is_required && (
                        <Button
                          onClick={() => handleDeletePermission(permission?.id)}
                          size="small"
                        >
                          Delete
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        <CreatePermissionsModal
          setShowModal={setShowCreateModal}
          showModal={showCreateModal}
          selectedPermission={selectedPermission}
          handleAddPermission={handleAddPermission}
          setFormDataPermission={setFormDataPermission}
          formDataPermission={formDataPermission}
        />
        {load && (
          <LoadingSpinner
            size={{
              height: "30px",
              width: "30px",
            }}
            style={{ margin: "auto" }}
          />
        )}
      </div>

      <div className="wrap">
        <div className="button-container">
          <Button
            size="medium"
            text="Discard"
            variant="secondary"
            className="mr-2"
            onClick={onCLickClose}
          />
          <Button
            size="medium"
            text="Update"
            variant="primary"
            disabled={!state?.content?.contentData?.text.length}
            onClick={onCLickUpdate}
          />
        </div>
      </div>
    </div>
  );
}
