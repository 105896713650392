import { gql } from "@apollo/client";

const DELETE_CAMPAIGN = gql`
  mutation deleteCampaign($id: ID!) {
    deleteCampaign(id: $id)
  }
`;
const DELETE_CODE_GROUP = gql`
  mutation deleteCodeGroup($id: String!) {
    deleteCodeGroup(id: $id)
  }
`;
export const DELETE_CONTENT = gql`
  mutation deleteContent($id: ID!) {
    deleteContent(id: $id)
  }
`;

const DELETE_PARTNER = gql`
  mutation deletePartner($id: ID!) {
    deletePartner(id: $id)
  }
`;
const DELETE_PARTNER_CODE_GROUP = gql`
  mutation deleteRewardPartnerCodeGroup($id: ID!) {
    deleteRewardPartnerCodeGroup(id: $id)
  }
`;
const DELETE_WIDGET = gql`
  mutation deleteWidget($id: ID!) {
    deleteWidget(id: $id)
  }
`;

export {
  DELETE_CAMPAIGN,
  DELETE_CODE_GROUP,
  DELETE_PARTNER,
  DELETE_PARTNER_CODE_GROUP,
  DELETE_WIDGET,
};
