export const segmentationFilterJsonSchema = {
  title: "Segmentation Filter",
  type: "object",
  properties: {
    status: {
      type: "string",
      label: "Status",
      items: {
        type: "string",
        options: [
          { id: "ENABLED", displayText: "Active" },
          { id: "DISABLED", displayText: "Inactive" },
        ],
      },
      // enum: ["ENABLED", "DISABLED"],
    },
    population: {
      type: "object",
      properties: {
        from: { type: "number" },
        to: { type: "number" },
      },
    },
  },
};

export const segmentationFilterUISchema = {
  type: "HorizontalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/status",
      component: "muiSelect",
      selection: "single",
      placeholder: "Status",
    },
    {
      type: "Control",
      scope: "#/properties/population",
      label: "Population",
      component: "rangeSlider",
      options: {
        buttonLabel: "Population",
        sliderLabel: "Select population range",
      },
    },
  ],
};
