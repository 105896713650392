import { createAjv } from "@jsonforms/core";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { Button, Toggle } from "pepsico-ds";
import { useEffect, useMemo } from "react";
import JsonFormsWrapper from "../../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

import {
  getPartnerCodeGroupUISchema,
  partnerCodeGroupJsonSchema,
} from "../../schemas/partnerCodeGroupSchema";

import "./createPartnerCodeModal.scss";

function PartnerCodeGroupFormModal({
  isOpen,
  partnerCodeGroupData,
  setPartnerCodeGroupData,
  handleMutation,
  loading,
  title,
  toggleCreatePartnerCodeModal,
  isEditMode = false,
}) {
  const ajvInstance = useMemo(() => createAjv(), []);
  const uiSchema = getPartnerCodeGroupUISchema(isEditMode);
  const jsonSchema = partnerCodeGroupJsonSchema;

  let isValidForm = ajvInstance.validate(jsonSchema, partnerCodeGroupData);

  const handleIsActive = (isActive) => {
    setPartnerCodeGroupData((prev) => ({ ...prev, isActive }));
  };

  useEffect(() => {
    isValidForm = ajvInstance.validate(jsonSchema, partnerCodeGroupData);
  }, [partnerCodeGroupData, ajvInstance]);

  return (
    <Dialog
      className="partner-code-group-modal"
      open={isOpen}
      onClose={toggleCreatePartnerCodeModal}
      scroll="paper"
      fullWidth
      maxWidth="large"
    >
      <DialogTitle>
        <Grid container justifyContent="space-between">
          <Grid item xs={10}>
            <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
              {title} Partner Code Group
            </h2>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="flex-end">
            <CloseIcon onClick={toggleCreatePartnerCodeModal} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ overflowX: "hidden" }}>
        <div className={`code-group-mod ${isEditMode ? "edit-form" : ""}`}>
          <JsonFormsWrapper
            jsonschema={jsonSchema}
            uischema={uiSchema}
            data={partnerCodeGroupData}
            onChange={setPartnerCodeGroupData}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Toggle
          label="Active"
          onUpdate={handleIsActive}
          size="medium"
          checked={partnerCodeGroupData?.isActive}
        />
        <Button onClick={handleMutation} disabled={!isValidForm || loading}>
          {title === "Edit" ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PartnerCodeGroupFormModal;
