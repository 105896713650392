import { Breadcrumb, LoadingSpinner } from "pepsico-ds";
// ../components/common/CodeGroupGridCar
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TermAndConditionTable from "../../components/management/SystemSettingTable/TermAndConditionTable";
import { paths } from "../../routes/paths";
import getBannerList from "../banners/graphQl/queries/bannerList";
import "./../../styles/common.scss";
import "./ManagementPage.scss";

const INITIAL_FILTER = {
  filter: {
    contentDefinitionSlug: "terms-and-conditions-content",
  },
};

export default function TermAndConditionPage() {
  const navigate = useNavigate();
  const [termAndConditionData, setTermAndConditionData] = useState([]);

  const { refetch } = useQuery(getBannerList, {
    variables: INITIAL_FILTER,
    onCompleted: (data) => {
      setTermAndConditionData(data?.listContent);
    },
  });

  useEffect(() => {
    refetch();
  }, []);

  const onClickEdit = (item) => {
    navigate(
      `/${paths.system_setting}/${paths.term_and_condition_edit}/${item.id || paths.term_and_condition}`,
      {
        state: {
          contentData: item?.contentData,
          text: item?.contentData?.text,
          id: item.id,
          activeStartDate: item.activeStartDate,
          title: item.title,
          description: item.description,
        },
      }
    );
  };
  const onClickView = (item) => {
    navigate(
      `/${paths.system_setting}/${paths.term_and_condition_view}/${item.id || paths.term_and_condition}`,
      { state: item }
    );
  };
  return (
    <div className="container">
      <div className="wrap">
        <Breadcrumb
          breadcrumbs={[
            {
              label: "System Setting",
              href: `../../${paths.system_setting}`,
            },
            { label: `Terms and Conditions` },
          ]}
          activeIndex={1}
        />
        <div className="heading-wrap">
          <h2>View and Edit Terms and Conditions</h2>
        </div>
        <div className="card-list">
          {termAndConditionData.length ? (
            <TermAndConditionTable
              termAndConditionData={termAndConditionData}
              onClickEdit={onClickEdit}
              onClickView={onClickView}
              chip={true}
            />
          ) : (
            <LoadingSpinner
              size={{
                height: "30px",
                width: "30px",
              }}
              style={{ margin: "auto" }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
