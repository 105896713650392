import { useQuery } from "@apollo/client";
import { Breadcrumb, Button, Card, Tab, TabsNavigation } from "pepsico-ds";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { listSegments } from "../../../../graphql/queries/listSegments";
import { toCamelCase } from "../../../../utils/functions";
import filterCampaignsBySegment from "../queries/filterCampaignsBySegment";
import filterContentBySegment from "../queries/filterContentBySegment";
import "./segmentSummaryViewPage.scss";

const CONTENT_TYPES = {
  BANNER: "banner-content",
  SURVEY_QUIZ: "survey-quiz-content",
  CONTENT: "page-content",
};

const COLUMNS = {
  CAMPAIGN: [
    { header: "Campaign Name", columnKey: "title", width: "50%" },
    { header: "Campaign Type", columnKey: "type", width: "50%" },
  ],
  BANNER: [
    { header: "Banner Name", columnKey: "title", width: "50%" },
    { header: "Banner Type", columnKey: "contentType", width: "50%" },
  ],
  SURVEY: [
    { header: "Survey/Quiz Name", columnKey: "title", width: "50%" },
    { header: "Survey/Quiz Type", columnKey: "contentType", width: "50%" },
  ],
  CONTENT: [
    { header: "Content Name", columnKey: "title", width: "50%" },
    { header: "Content Type", columnKey: "contentType", width: "50%" },
  ],
};

export default function SegmentSummaryViewPage() {
  const { segmentId } = useParams();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabData, setTabData] = useState({
    campaignData: [],
    bannerData: [],
    surveyData: [],
    contentData: [],
  });

  const { data: segmentsData } = useQuery(listSegments);
  const [selectedSegments, setSelectedSegments] = useState({});

  const { refetch } = useQuery(filterContentBySegment, {
    variables: {
      filter: {
        contentDefinitionSlug: CONTENT_TYPES.BANNER,
        segments: [segmentId],
      },
    },
    skip: true, // Skip initial query, we'll use refetch
  });

  const { data: campaignData } = useQuery(filterCampaignsBySegment, {
    variables: { filter: { segmentations: [segmentId], hasSegments: true } },
  });

  useEffect(() => {
    if (segmentsData) {
      const initialSegments = segmentsData.listSegments.find(
        (segment) => segment.id === segmentId
      );
      setSelectedSegments(initialSegments);
    }
  }, [segmentsData, segmentId]);

  useEffect(() => {
    setTabData((prev) => ({
      ...prev,
      campaignData: campaignData?.listCampaigns || [],
    }));
  }, [campaignData]);

  const CustomTable = useCallback(
    ({ rows, columns }) => (
      <table className="segment-summery-table">
        <thead>
          <tr>
            {columns.map(({ columnKey, header, width }) => (
              <th key={columnKey} style={{ width }}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          ) : (
            rows.map((row) => (
              <tr key={row.id}>
                {columns.map(
                  ({ columnKey }) => (
                    console.log("HDUHEUDHE", row[columnKey]),
                    (<td key={columnKey}>{toCamelCase(row[columnKey])}</td>)
                  )
                )}
              </tr>
            ))
          )}
        </tbody>
      </table>
    ),
    []
  );

  const buildTabContent = useMemo(() => {
    const tabContents = [
      { data: tabData.campaignData, columns: COLUMNS.CAMPAIGN },
      { data: tabData.bannerData, columns: COLUMNS.BANNER },
      { data: tabData.surveyData, columns: COLUMNS.SURVEY },
      { data: tabData.contentData, columns: COLUMNS.CONTENT },
    ];

    return function WrappedCustomTable(tabIndex) {
      return (
        <div>
          <CustomTable
            key={tabIndex}
            rows={tabContents[tabIndex].data}
            columns={tabContents[tabIndex].columns}
          />
        </div>
      );
    };
  }, [tabData]);

  useEffect(() => {
    const contentTypes = [
      null,
      CONTENT_TYPES.BANNER,
      CONTENT_TYPES.SURVEY_QUIZ,
      CONTENT_TYPES.CONTENT,
    ];
    const dataKeys = ["campaignData", "bannerData", "surveyData", "contentData"];
    const contentTypeKeys = [null, "banner_type", "type", "content_type"];

    if (selectedTab > 0) {
      refetch({
        filter: {
          contentDefinitionSlug: contentTypes[selectedTab],
          segments: [segmentId],
        },
      }).then(({ data }) => {
        const listContent = data?.listContent || [];
        const content = listContent.map((item) => ({
          ...item,
          contentType: item?.contentData?.[contentTypeKeys[selectedTab]] || "",
        }));

        setTabData((prev) => ({
          ...prev,
          [dataKeys[selectedTab]]: content,
        }));
      });
    }
  }, [selectedTab, refetch, segmentId]);

  return (
    <div style={{ padding: "20px" }}>
      <Card>
        <div>
          <Breadcrumb
            activeIndex={1}
            breadcrumbs={[
              {
                label: "Segments",
                href: "/segmentation/segments-list",
              },
              {
                label: selectedSegments?.name || "Segment Summary",
                href: `/segmentation/segment-summary/${segmentId}`,
              },
            ]}
          />
        </div>
        <div className="segment-summary-view-page">
          <div className="segement-detail-card">
            <h3>Basic Setup</h3>
            <div style={{ display: "flex", gap: "20px", marginBottom: "20px" }}>
              <div>
                <p>
                  <strong>Name:</strong> {selectedSegments?.name}
                </p>
              </div>
              <div>
                <p>
                  <strong>Created By: </strong> {selectedSegments?.type}
                </p>
              </div>
            </div>
            <div>
              <p>
                <strong>Description:</strong> {selectedSegments?.rules}
              </p>
            </div>
          </div>
          <div className="segement-detail-card">
            <h3>Segment is added to:</h3>
            <div>
              <TabsNavigation
                className="tabs-wrap"
                style={{ borderBottom: "2px solid #D7E5F8" }}
                onUpdate={setSelectedTab}
                selectedIndex={0}
              >
                <Tab label="Campaigns" />
                <Tab label="Banners" />
                <Tab label="Surveys/Quizzes" />
                <Tab label="Contents" />
              </TabsNavigation>
              <div
                style={{
                  padding: "20px 0px ",
                  maxHeight: 200,
                  overflowX: "scroll",
                }}
              >
                {buildTabContent(selectedTab)}
              </div>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="secondary"
              size="medium"
              onClick={() => navigate("/segmentation/segments-list/list")}
            >
              Back to Segment
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
