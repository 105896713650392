import { Tooltip } from "pepsico-ds"; // Import Tooltip if needed
import { useNavigate } from "react-router-dom";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { segmentActionButtons } from "../segmentActionButtons";
import "./segmentListTable.scss";

const SegmentListTable = ({ segmentData }) => {
  const navigate = useNavigate();

  return (
    <div className="segment-list-container">
      <table className="segment-list-table">
        <thead>
          <tr>
            <th className="column-status">STATUS</th>
            <th className="column-created-by">CREATED BY</th>
            <th className="column-name">TITLE</th>
            <th className="column-population">POPULATION</th>
            <th className="column-description">DESCRIPTION</th>
            <th className="column-actions">ACTIONS</th>
          </tr>
        </thead>
        <tbody>
          {segmentData.map((segment) => (
            <tr key={segment.id}>
              <td className="segment-status-type">
                <span
                  className={`status ${
                    segment.status === "ENABLED" ? "active" : "inactive"
                  }`}
                >
                  {segment.status === "ENABLED" ? "Active" : "Inactive"}
                </span>
              </td>
              <td>
                <span className="type-chip">{segment.type || "Unknown"}</span>
              </td>
              <td className="segment-name">
                {segment.name.length > 60 ? (
                  <Tooltip direction="right" text={segment.name}>
                    {segment.name.substring(0, 60)}...
                  </Tooltip>
                ) : (
                  segment.name
                )}
              </td>
              <td>
                {segment.population ? segment.population.toLocaleString() : "N/A"}
              </td>
              <td>{segment.rules}</td>
              <td>
                <div className="button-group-wrapper">
                  <ButtonGroupDynamic
                    items={segmentActionButtons(
                      segment.status,
                      navigate,
                      segment.id
                    )}
                    spacing="0px"
                    size="medium"
                    variant="control"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SegmentListTable;
