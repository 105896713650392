import { useMutation } from "@apollo/client";
import { debounce } from "@mui/material";
import {
  Button,
  InputItem,
  Modal,
  Notification,
  Pagination,
  ToastContainer,
} from "pepsico-ds";
import { useCallback, useContext, useEffect, useState } from "react";
import ButtonGroupDynamic from "../../components/common/ButtonGroupDynamic";
import JsonFormsWrapper from "../../components/jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import CreateSurveyModal from "../../components/survey/CreateSurveyModal";
import SurveyCard from "../../components/survey/SurveyCard";
import SurveyListView from "../../components/survey/SurveyListView";
import SurveyActionModal from "../../components/survey/SurveySummaryModal";
import { getSurveyStatusActionButtons } from "../../components/survey/actionButtons";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import LoaderContext from "../../context/LoaderContext";
import { SurveyContext } from "../../context/SurveyContext";
import { deleteContent } from "../../graphql/mutations/contentMutations";
import { useSurveyHandlerHook } from "../../hooks/useSurveyHandlerHook";
import "./survey-page.scss";

const ROWS_PER_PAGE = 20;

const SurveyListPage = () => {
  const [viewType, setViewType] = useState("grid");
  const [createSurveyModalOpen, setCreateSurveyModalOpen] = useState(false);
  const [surveyData, setSurveyData] = useState(null);
  const [actionStatus, setActionStatus] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const { refetchSurveyList, handleFilterVariables } = useSurveyHandlerHook();
  const { setIsLoading } = useContext(LoaderContext);
  const [openSurveySummaryModal, setOpenSurveySummaryModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(0);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [toastData, setToastData] = useState([]);

  const {
    initialState,
    setSurveyState,
    surveyList,
    surveyFilterJsonSchema,
    surveyFilterUiSchema,
    load,
    successTitle,
    setSuccessTitle,
  } = useContext(SurveyContext);
  const {
    globalState: { programConfig },
  } = useContext(GlobalConfigContext);
  const currentProgramTimezone = programConfig?.localization?.programTimezone || "";

  const [deleteSurvey] = useMutation(deleteContent, {
    onCompleted: () => {
      setToastData([
        {
          id: Date.now(),
          text: "Survey deleted successfully",
          type: "success",
        },
      ]);
      refetchSurveyList();
    },
    onError: (error) => {
      setToastData([
        {
          id: Date.now(),
          text: "Error deleting survey: " + error.message,
          type: "error",
        },
      ]);
    },
  });

  const [filterVar, setFilterVar] = useState({});

  const cleanFilterObject = (filterObj) => {
    return Object.fromEntries(
      Object.entries(filterObj).filter(
        ([value]) => value !== null && value !== undefined && value !== ""
      )
    );
  };

  const toggleSummaryModal = () => {
    setOpenSurveySummaryModal(!openSurveySummaryModal);
  };

  const closeNotification = () => {
    setSuccessTitle({});
  };

  const toggleCreateSurveyModal = (event) => {
    setCreateSurveyModalOpen(!createSurveyModalOpen);
    setActionStatus(null);
    if (event === "cancel") {
      setSurveyState(initialState);
    }
  };

  const handleFilterData = (data) => {
    const updatedFilter = { ...filterVar, ...data };
    const cleanedFilter = cleanFilterObject(updatedFilter);
    setFilterVar(cleanedFilter);
    handleFilterVariables(cleanedFilter);
    setCurrentPage(1); // Reset to page 1 when filters are applied
  };

  const areFiltersApplied = () => {
    return (
      Object.keys(filterVar).length > 0 ||
      (searchFilter && searchFilter.trim() !== "")
    );
  };
  const handleClearFilters = () => {
    setFilterVar({});
    setSearchFilter("");
    handleFilterVariables({});
    setCurrentPage(1); // Reset to page 1 when filters are cleared
  };

  const handlActionButtons = (stat, survey) => {
    if (stat) {
      if (stat === "view") {
        toggleSummaryModal();
        setActionStatus("view");
      }
      if (stat === "edit") {
        const tempSurvey = { ...survey };
        delete tempSurvey.__typename;
        delete tempSurvey.contentDefinition;
        setSurveyState((prev) => ({ ...prev, ...tempSurvey }));
        toggleCreateSurveyModal();
        setActionStatus("edit");
      }
    }
    setSurveyData(survey);
  };

  const handleConfirmDelete = async () => {
    if (!surveyToDelete) return;
    try {
      setIsLoading(true);
      await deleteSurvey({ variables: { id: surveyToDelete.id } });
    } finally {
      setIsLoading(false);
      handleCloseConfirmationModal();
    }
  };

  const handleOpenConfirmationModal = (survey) => {
    setSurveyToDelete(survey);
    setConfirmationModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalOpen(false);
    setSurveyToDelete(null);
  };

  const callAfterChange = useCallback(
    debounce((searchFilterValue, filterVar) => {
      const temp = { ...filterVar };
      if (searchFilterValue && searchFilterValue.length > 0) {
        temp.title = searchFilterValue;
      } else {
        delete temp.title;
      }
      const cleanedFilter = cleanFilterObject(temp);
      setFilterVar(cleanedFilter);
      handleFilterVariables(cleanedFilter);
      setCurrentPage(1); // Reset to page 1 when search input changes
    }, 1500),
    [filterVar]
  );

  useEffect(() => {
    refetchSurveyList();
  }, []);

  useEffect(() => {
    setIsLoading(load);
  }, [load]);

  useEffect(() => {
    callAfterChange(searchFilter, filterVar);
  }, [searchFilter]);

  useEffect(() => {
    if (successTitle && Object.keys(successTitle).length > 0) {
      setTimeout(() => {
        setSuccessTitle({});
      }, 5000);
    }
  }, [successTitle]);

  const currentData = surveyList.slice(
    (currentPage - 1) * ROWS_PER_PAGE,
    Math.min(currentPage * ROWS_PER_PAGE, surveyList.length)
  );

  useEffect(() => {
    setNumPages(Math.ceil(surveyList.length / ROWS_PER_PAGE));
  }, [surveyList.length]);

  const handlePageChange = (newPageIndex) => {
    if (newPageIndex > 0 && newPageIndex <= numPages) {
      setCurrentPage(newPageIndex);
    }
  };

  return (
    <div className="px-3 pt-3">
      <div className="container-card">
        {successTitle && Object.keys(successTitle).length > 0 && (
          <div className="notification-wrapper">
            <Notification
              primaryActionLabel="Close"
              primaryActionOnClick={closeNotification}
              text={`You have just ${successTitle["id"] ? "updated" : "published"} a survey!`}
              title={`"${successTitle["title"]}" ${successTitle["id"] ? "Updated!" : "Created!"}`}
              type="success"
            />
          </div>
        )}
        <div className="page-header">
          <h6 className="font-bold font-lg">Survey/Quiz</h6>
          <Button
            iconTrailing="add_circle"
            size="medium"
            text="Create New Survey/Quiz"
            onClick={toggleCreateSurveyModal}
          />
        </div>
        <div className="page-content">
          <div className="view-filter">
            <div className="survey-search-filter-container">
              <div className="filter-container-search">
                <InputItem
                  placeholder="Search by title"
                  trailingIcon="search"
                  size="medium"
                  style={{ width: "90%" }}
                  className="input-search"
                  value={searchFilter}
                  onChange={(e) => setSearchFilter(e.target.value)}
                />
              </div>
              <div className="filter-container-popover">
                <JsonFormsWrapper
                  jsonschema={surveyFilterJsonSchema}
                  uischema={surveyFilterUiSchema}
                  data={filterVar}
                  onChange={(data) => handleFilterData(data)}
                />
              </div>
              {areFiltersApplied() && (
                <Button
                  style={{
                    color: "#005CBC",
                    marginLeft: "8px",
                    marginTop: "8px",
                  }}
                  variant="primaryInverse"
                  size="small"
                  onClick={handleClearFilters}
                >
                  Clear all
                </Button>
              )}
            </div>
            <ButtonGroupDynamic
              items={[
                {
                  icon: "view_module",
                  onClick: () => {
                    setViewType("grid");
                  },
                },
                {
                  icon: "view_list",
                  onClick: () => {
                    setViewType("list");
                  },
                },
              ]}
              variant="toggle"
              selectedIndex={viewType === "grid" ? 0 : 1}
            />
          </div>
          <div className="main-content">
            {viewType === "grid" ? (
              <div
                className="grid-container"
                style={{
                  height:
                    currentData && currentData.length === 0 ? "unset" : "680px",
                  overflowY: "auto",
                }}
              >
                {currentData.map((survey, index) => (
                  <div className="grid-item grid-item-3 grid-item-4" key={index}>
                    <SurveyCard
                      currentProgramTimezone={currentProgramTimezone}
                      key={index}
                      surveyData={survey}
                      actionButtons={getSurveyStatusActionButtons(
                        survey,
                        handlActionButtons,
                        handleOpenConfirmationModal
                      )}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <SurveyListView
                currentProgramTimezone={currentProgramTimezone}
                surveyList={currentData}
                handlActionButtons={handlActionButtons}
                handleOpenConfirmationModal={handleOpenConfirmationModal}
              />
            )}
            {currentData && currentData.length === 0 && (
              <p className="no-records">No records found.</p>
            )}
          </div>
          <div
            style={{
              padding: "16px 0px 16px 0px",
              display: "flex",
              justifyContent: "center",
              overflow: "auto",
            }}
          >
            <Pagination
              currentPage={currentPage}
              onUpdate={handlePageChange}
              pageCount={numPages}
              size="medium"
              variant="number"
            />
          </div>
        </div>
        {createSurveyModalOpen && (
          <CreateSurveyModal
            createSurveyModalOpen={createSurveyModalOpen}
            toggleCreateSurveyModal={toggleCreateSurveyModal}
            actionStatus={actionStatus}
          />
        )}
      </div>
      {openSurveySummaryModal && (
        <SurveyActionModal
          currentProgramTimezone={currentProgramTimezone}
          surveySummaryOpen={openSurveySummaryModal}
          toggleSummaryModal={toggleSummaryModal}
          handleActionButtons={handlActionButtons}
          surveyData={surveyData}
        />
      )}
      {toastData.length > 0 && (
        <ToastContainer
          data={toastData}
          removeToast={() => setToastData([])}
          showActionIcon
        />
      )}
      {confirmationModalOpen && (
        <Modal
          className="confirmation-modal"
          showModal={confirmationModalOpen}
          onCloseModal={handleCloseConfirmationModal}
          primaryButtonProps={{
            size: "medium",
            text: "Delete",
            variant: "primary",
            onClick: handleConfirmDelete,
          }}
          secondaryButtonProps={{
            size: "medium",
            text: "Cancel",
            variant: "secondary",
            onClick: handleCloseConfirmationModal,
          }}
          showTertiaryButton={false}
          showLink={false}
        >
          <p>
            Are you sure you want to delete this survey? This action cannot be
            undone.
          </p>
        </Modal>
      )}
    </div>
  );
};

export default SurveyListPage;
