import { useMutation } from "@apollo/client";
import _ from "lodash";
import { LoadingSpinner, Modal, Toggle } from "pepsico-ds";
import { useContext } from "react";
import EmptyImage from "../../../assets/widget/create_new_widget_empty.svg";
import { RewardContext } from "../../../context/RewardContext";
import { createReward } from "../../../graphql/mutations/createReward";
import { updateReward } from "../../../graphql/mutations/updateReward";
import Button from "../../common/button/Button";
import { ListingComponentContext } from "../../common/listingComponents/ListingComponentContext";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import PartnerSelect from "../PartnerSelect/PartnerSelect";
import RewardCategorySelect from "../RewardCategorySelect/RewardCategorySelect";
import RewardTypeSelect from "../RewardTypeSelect/RewardTypeSelect";
import "./rewards-modal.scss";
import { rewardBasicSetupJsonSchema, rewardBasicSetupUiSchema } from "./schemas";

const CreateRewardsModal = ({
  createRewardModalOpen,
  toggleModalNewReward,
  showToast,
  editConfig,
}) => {
  const {
    rewardState,
    updateRewardState,
    isLoadingOptions,
    loadingSpecifications,
    errors,
    toast,
    setToast,
    uiSchema,
  } = useContext(RewardContext);
  const { refetch } = useContext(ListingComponentContext);
  const [createRewardMutation, { loading: loadingCreate }] =
    useMutation(createReward);
  const [updateRewardMutation, { loading: loadingUpdate }] =
    useMutation(updateReward);

  const isSetupCompleted = !errors.length;

  const updateSpecifications = (data) =>
    updateRewardState({ ...rewardState, rewardTypeData: data });

  const additionalProps = [
    "id",
    "partnerType",
    "partnerName",
    "schema",
    "programId",
    "rewardTypeName",
    "rewardCategories",
  ];
  const updateAdditionalProps = ["type"];

  const handleUpdateReward = async () => {
    updateRewardMutation({
      variables: {
        id: rewardState.id,
        input: _.omit(rewardState, [...additionalProps, ...updateAdditionalProps]),
      },
    })
      .then(() => {
        refetch();
        showToast(
          "success",
          `'${rewardState.name}' Updated!`,
          "You have just updated a reward!"
        );
        toggleModalNewReward();
      })
      .catch(() => {
        setToast({
          open: true,
          type: "error",
          title: "Something went wrong while updating the reward.",
          description: "Please try again later.",
        });
      });
  };

  const handleCreateReward = async () => {
    createRewardMutation({
      variables: {
        input: _.omit(rewardState, additionalProps),
      },
    })
      .then(() => {
        refetch();
        showToast(
          "success",
          `'${rewardState.name}' Created!`,
          "You have just published a reward!"
        );
        toggleModalNewReward();
      })
      .catch(() => {
        setToast({
          open: true,
          type: "error",
          title: "Something went wrong while creating the reward.",
          description: "Please try again later.",
        });
      });
  };

  const setReadonly = (uischema) => {
    if (uischema.type === "Control") {
      const newSchema = {
        ...uischema,
        readonly: true,
      };
      if (newSchema.options && newSchema.options.detail) {
        newSchema.options = {
          ...newSchema.options,
          detail: setReadonly(newSchema.options.detail),
        };
      }
      return newSchema;
    } else if (uischema.elements && Array.isArray(uischema.elements)) {
      return {
        ...uischema,
        elements: uischema.elements.map(setReadonly),
      };
    }
    return uischema;
  };

  const isEdittingActive = editConfig?.isActive === true;

  return (
    <Modal
      className="reward-modal"
      showModal={createRewardModalOpen}
      onCloseModal={toggleModalNewReward}
      title={editConfig?.edit ? "Edit Reward" : "Create Reward"}
      primaryButtonProps={{}}
      showSecondaryButton={false}
      showTertiaryButton={false}
      showLink={false}
    >
      <h4 className="required-fields-label text-right">*Required fields</h4>
      {isLoadingOptions && (
        <div className="loading-options">
          <LoadingSpinner
            size={{
              height: "1rem",
              width: "1rem",
            }}
          />
          <span>Loading Options...</span>
        </div>
      )}
      <div className="reward">
        <div className="reward-wrap">
          <div className="reward-type-setup">
            <span className="reward-type-setup-label font-bold">Reward Type</span>
            <div className="reward-type-setup-fields">
              <div className="reward-type-setup-field">
                <PartnerSelect
                  label={"Select Partner*"}
                  selected={rewardState.partnerId}
                  required={true}
                  readonly={isEdittingActive}
                />
              </div>
              <div className="reward-type-setup-field">
                <RewardTypeSelect
                  label={"Reward Type*"}
                  selected={rewardState.rewardTypeId}
                  required={true}
                  readonly={isEdittingActive}
                />
              </div>
              <div className="reward-type-setup-field">
                <RewardCategorySelect
                  label={"Reward Category*"}
                  selected={rewardState.rewardCategoryIds}
                  required={true}
                  readonly={isEdittingActive}
                />
              </div>
            </div>
          </div>
          {rewardState?.partnerId?.length ? (
            <div className="reward-basic-setup">
              <JsonFormsWrapper
                jsonschema={rewardBasicSetupJsonSchema}
                uischema={
                  isEdittingActive
                    ? setReadonly(rewardBasicSetupUiSchema)
                    : rewardBasicSetupUiSchema
                }
                data={rewardState}
                onChange={updateRewardState}
              />
              {loadingSpecifications ? (
                <div className="specifications-loading">
                  <LoadingSpinner
                    size={{
                      height: "2rem",
                      width: "2rem",
                    }}
                  />
                </div>
              ) : (
                rewardState.schema &&
                uiSchema && (
                  <JsonFormsWrapper
                    jsonschema={rewardState.schema}
                    uischema={isEdittingActive ? setReadonly(uiSchema) : uiSchema}
                    data={rewardState.rewardTypeData || {}}
                    onChange={updateSpecifications}
                  />
                )
              )}
            </div>
          ) : (
            <div className="no-reward-type">
              <img src={EmptyImage} alt="No Completed Sweepstakes" />
              <h5>Select your type of reward to start</h5>
            </div>
          )}
        </div>
      </div>
      <div className="action-buttons">
        <Toggle
          label="Active"
          onUpdate={(checked) =>
            updateRewardState({ ...rewardState, isActive: checked })
          }
          size="medium"
          checked={rewardState.isActive}
          disabled={!isSetupCompleted}
        />
        <Button
          isLoading={loadingCreate || loadingUpdate}
          onClick={editConfig?.edit ? handleUpdateReward : handleCreateReward}
          disabled={!isSetupCompleted}
        >
          {editConfig?.edit ? "Update" : "Create"}
        </Button>
      </div>
      <ToastNotification
        {...toast}
        handleClose={() => setToast({ ...toast, open: false })}
      />
    </Modal>
  );
};

export default CreateRewardsModal;
